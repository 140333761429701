import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-badgeconfig-edit',
  templateUrl: './badgeconfig-edit.component.html',
  styleUrls: ['./badgeconfig-edit.component.css']
})
export class BadgeconfigEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
