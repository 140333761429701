<div class="row mt-50">
    <div class="col-md-4">
    </div>
    <div class="col-md-4">
        <div class="panel">
            <div class="panel-heading">
                <div class="panel-title text-center mt-20">
                    <h5>Xoomla Login</h5>
                </div>
            </div>
            <div class="panel-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div *ngIf="isError" class="text-center">
                        <div class="alert alert-danger">Email and/or password is incorrect.</div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="email" formControlName="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required" class="text text-danger">Email is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" formControlName="password" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required" class="text text-danger">Password is required</div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>