import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Setting } from 'src/app/models/setting';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.css']
})
export class SettingDialogComponent implements OnInit {

  settingsForm: FormGroup;
  isSaving = false;
  submitted = false;
  isError = false;
  loading = false;
  setting: Setting = new Setting();

  constructor(private _fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
   private dialogRef:MatDialogRef<SettingDialogComponent>) {
    this.setting = data.editSetting;
  
  }

  ngOnInit(): void {
    this.createSettingsFormInit();
    this.setSettingData()
  }
  createSettingsFormInit() {
    this.settingsForm = this._fb.group({
      'key': ['', Validators.required],
      'value': ['', Validators.required],
    });
  }
  get f() { return this.settingsForm.controls; }

  setSettingData(){
    this.settingsForm.patchValue({
      'key': this.setting.key,
      'value': this.setting.value
    });
  }

  onSubmit(){
    let data: any = {
      key: this.f.key.value,
      value: this.f.value.value,
    }
    this.dialogRef.close({
      data:data
    });
    
  }

}
