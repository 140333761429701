import { Component, OnInit } from '@angular/core';
import { PusherService } from '../pusher.service';
import { ApiDataServices } from '../_services/apiservices/api.service';
import { apiUrls } from '../api-urls';

interface Message {
  name: string;
  message: string;
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})


export class MessagesComponent implements OnInit {

  constructor(private pusherService: PusherService, private api: ApiDataServices,) {
  }
  messages: any[] = [];

  ngOnInit() {
    this.pusherService.messagesChannel.bind('sent-message', (data) => {
      this.messages.push(data);
      // console.log(data);
    });

    //fetch messages
    this.api.postApiData(apiUrls.fetchMessage('0a1ee83d-5dd3-49d4-b40e-d43daf1b0a77')).pipe()
    .subscribe(
        (data: any) => {
          var messages = data.data;
          // console.log(messages);
          for (let i = 0; i < messages.length; i++) {
            const element = messages[i];
            this.messages.push(element);
          }
          // this.messages.push();
        },
        error => {
        });
  }

  sendMessage(user: string, text: string) {
    const message: Message = {
      "name": "dravid",
      "message": "hello"
    }
    var messagevalue = (document.getElementById('message_val') as HTMLButtonElement).value;
    if (!messagevalue) {
      alert('Cant send empty message');
    }
    let json = {
      idApplier : "0a1ee83d-5dd3-49d4-b40e-d43daf1b0a77",
      message : messagevalue
    };
      // this.pusherService.messagesChannel.trigger('sent-message', message);
      this.api.postApiData(apiUrls.sendMessage, json).pipe()
      .subscribe(
          (data: any) => {
            (document.getElementById('message_val') as HTMLButtonElement).value = '';
          },
          error => {
            (document.getElementById('message_val') as HTMLButtonElement).value = '';
          });

    // this.messages.push(message);
  }

}
