import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { apiUrls } from 'src/app/api-urls';

@Component({
  selector: 'app-badgeconfig-add',
  templateUrl: './badgeconfig-add.component.html',
  styleUrls: ['./badgeconfig-add.component.css'], template: '<div>'
    + '<input type="file" (change)="upload($event)">'
    + '</div>',

})
export class BadgeconfigAddComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private _fb: FormBuilder,
    private router: Router,
    private _api: ApiDataServices,
    private _ac: ActivatedRoute
  ) { }


  @Input() actionType: string = 'Add';
  @Input() title: string = 'Create New BadgeConfig';
  badgeForm: FormGroup;
  submitted = false;
  loading = false;
  errorMessage = "";
  isSaving = false;
  idBadgeConfig: any = '';
  badgeData: any = '';
  badgeList: any[];
  image: string = '';

  ngOnInit(): void {
    this.createBadgeFormInit();
    this.getbadgeList();
    this._ac.params.subscribe(
      params => {
        this.idBadgeConfig = params.id;
        if (this.idBadgeConfig) {
          // console.log(this.idBadgeConfig);
          this.getBadge();
        }
      }
    )
  }

  //Initializing Badgeform for validation
  createBadgeFormInit() {
    this.badgeForm = this._fb.group({
      'title': ['', [Validators.required]],
      'slug': ['', [Validators.required]],
      'type': ['', [Validators.required]],
      'description': ['', [Validators.required]],
      'parents': [0],
      'price': ['', [Validators.required]],
      'image': ['', this.actionType == 'Add' ? [Validators.required] : []],
      'visibility': ['', [Validators.required]]
    });

  }

  get f() { return this.badgeForm.controls; }

  // Calling Submit function
  onSubmit() {
    // console.log(this.badgeForm.value);
    this.submitted = true;

    // Checking form validaton
    if (this.badgeForm.valid) {
      this.isSaving = true;

      // Creating BadgeConfig
      if (this.actionType == 'Add') {

        let data: any = {
          title: this.f.title.value,
          slug: this.f.slug.value,
          type: this.f.type.value,
          description: this.f.description.value,
          price: this.f.price.value,
          image: this.f.image.value,
          visibility: this.f.visibility.value
        }

        if (this.f.parents.value) {
          data.parents = (this.f.parents.value).join();
        }

        this._api.postApiData(apiUrls.badgeCreate, data).subscribe(
          (data: any) => {
            // console.log(data);
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('BadgeConfig  created successfully');
            this.router.navigate(['badge-config/list']);

            }, (error: any) => {
              this.errorMessage = error && error.message ? error.message : 'Sorry, Something went wrong';
              this.isSaving = false;
              this.submitted = false;
            }

        );
      }

      // Editing BadgeConfig details
      if (this.actionType == 'Edit') {
        let data: any = {
          title: this.f.title.value,
          slug: this.f.slug.value,
          type: this.f.type.value,
          description: this.f.description.value,
          price: this.f.price.value,
          visibility: this.f.visibility.value
        }
        Object.keys(this.f.parents.value).length ? (data.parents = (this.f.parents.value).join()) : '';
        this.f.image.value ? (data.image = this.f.image.value) : '';
        // console.log(data)

        this._api.putApiData(apiUrls.badgeUpdate(this.idBadgeConfig), data).subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('BadgeConfig  updated successfully');
            this.router.navigate(['badge-config/list']);

            },
            (error: any) => {
              this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
              this.isSaving = false;
              this.submitted = false;

          });

      }

    } else {
      // console.log('error')
      return;
    }
  }

  // Getting BadgeConfig data using (idBadgeConfig)
  getBadge() {
    this.loading = true;
    this._api.postApiData(apiUrls.badgeGet(this.idBadgeConfig)).subscribe(
      (data: any) => {
        // console.log(data);
        this.badgeData = data.data;
        if (this.badgeData) {
          this.loading = false;
          this.setbadgeData(this.badgeData);
        }
      }
    )
  }

  // Setting BadgeConfig data in edit form
  setbadgeData(data) {
    this.badgeForm.patchValue({
      'title': data.title,
      'slug': data.slug,
      'type': data.type,
      'description': data.description,
      'price': data.price,
      'parents': data.parents,
      'visibility': data.visibility
    });
    this.image = data.imageLink;
  }

  //Gettting BadgeConfig list
  getbadgeList() {
    this.loading = true;
    this._api.postApiData(apiUrls.badgeList).subscribe(
      (data: any) => {
        this.loading = false;
        this.badgeList = data.data;
      },
      error => {
        this.loading = false;
      }
    )
  }

  // Cancel BadgeConfig
  cancelbadgeConfigcreate() {
    this.router.navigate(['badge-config/list']);
  }

  // Function for uploading image
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    // console.log(file);

    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.image = reader.result;
    // console.log(this.image);

    this.badgeForm.patchValue({ image: this.image })
    // console.log(this.image)
  }

  cancel() {
    this.image = "null"
  }
}

