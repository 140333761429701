<div class="container-fluid">
  <div class="row page-title-div">
    <div class="col-md-6">
      <h2 class="title">User ACL</h2>
    </div>
    <!-- /.col-md-6 -->
  </div>
  <!-- /.row -->
  <div class="row breadcrumb-div">
    <div class="col-md-6">
      <ul class="breadcrumb">
        <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
        <li><a [routerLink]="'/user-acl/list'">ACL List</a></li>
        <li class="active">{{actionType}}</li>
      </ul>
    </div>
    <!-- /.col-md-6 -->
  </div>
  <!-- /.row -->
</div>
<!-- /.container-fluid -->
<section class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="panel">
          <div class="panel-heading">
            <div class="panel-title">
              <h5>{{title}}</h5>
            </div>
          </div>
          <div class="panel-body">
            <div *ngIf="loading" class="text-center">
              <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
            </div>
            <form *ngIf="!loading"[formGroup]="userAclForm" (ngSubmit)="onSubmit()">
              <div *ngIf="loading" class="text-center">
                <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
              </div>
             
              <div class="form-group" [ngClass]="{ 'has-error': submitted && f.namespace.errors }">
                <label for="namespace" class="control-label">Namespace</label>
                <div class="">
                  <input type="text" formControlName="namespace" class="form-control" placeholder="Namespace"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.namespace.errors }" />
                  <span *ngIf="submitted && f.namespace.errors" class="help-block">This field is required.</span>
                </div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': submitted && f.route.errors }">
                <label for="route" class="control-label">Route</label>
                <div class="">
                  <input type="text" formControlName="route" class="form-control" placeholder="Route"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.route.errors }" />
                  <span *ngIf="submitted && f.route.errors" class="help-block">This field is required.</span>
                </div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': submitted && f.path.errors }">
                <label for="path" class="control-label">Path</label>
                <div class="">
                  <input type="text" formControlName="path" class="form-control" placeholder="Path" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.path.errors }" />
                  <span *ngIf="submitted && f.path.errors" class="help-block">This field is required.</span>
                </div>
              </div>
              <button type="submit" class="btn btn-primary">{{actionType == 'Add'? (isSaving ? 'Creating...' : 'Create') : (isSaving ? 'Updating...' : 'Update') }}
                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
              </button>
            </form>
          </div>
        </div>
      </div>
      <!-- /.col-md-8 col-md-offset-2 -->
    </div>
  </div>
</section>