<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Update Profile</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
				<li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li class="active">Update-Profile</li>
			</ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Profile Details</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center"><h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4></div>
                        <form *ngIf="!loading" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                            <div class="row mb-20">
                            <div class="col-sm-2 text-center">
                                <label for="email" class="control-label ml-30">Email : </label>
                            </div>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" value="{{email}}" readonly/>
                            </div>
                            <div class="col-sm-4">

                            </div>
                            </div>
                            <div class="row mb-20">
                                <div class="col-sm-2 text-right">
                                    <label for="userName" class="control-label">Username : </label>
                                </div>
                                <div class="col-sm-6">
                                    <input formControlname="userName" type="text" class="form-control" value="{{userName}}" (focusout)="change($event,'username')" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"/>
                                    <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                                        <div *ngIf="f.userName.errors.required" class="text text-danger">Username is required</div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
        
                                </div>
                            </div>
                            <div class="row mb-20">
                                <div class="col-sm-2 text-right">
                                    <label for="password" class="control-label">Password : </label>
                                </div>
                                <div class="col-sm-6">
                                    <input formControlname="password" type="text" class="form-control" value="{{password}}" (focusout)="change($event,'password')" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required" class="text text-danger">Password is required</div>
                                    </div>
                                    <div *ngIf = "submitted && f.password.errors" class = "invalid-feedback">
                                        <div *ngIf="f.password.errors.minlength" class="text text-danger">Min 5 characters required.</div>
                                      </div>  
                                </div>
                                <div class="col-sm-4">
        
                                </div>
                            </div>
                            <br><br>
                            <div class="col-sm-6">

                            </div>
                            
                            <div class="text-right">
                                <div class="text-right">
                                    <a [routerLink]="'/dashboard'" class="btn btn-default mr-20">Cancel</a>
                                    <button type="submit" class="btn btn-primary mr-20">{{isSaving ? 'Saving...' : 'Save' }} 
                                        <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>