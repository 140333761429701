import { Injectable } from '@angular/core';


@Injectable()
export class UtilityService {

    constructor() {
    }

    filterArrayObj(arr, prop, val) {
        let filteredVal = arr.filter(item => {
            return item[prop] != val;
        });
        return filteredVal;
    }


    changeArrayObjProp(arr, match, value, prop, val) {
        arr.forEach(function (item, index, array) {
            if (item[match] == value) item[prop] = val;
        });
        return arr;
    }

    getIndexByValFromObj(arr, match, value) {
        let idx = 0;

        arr.forEach(function (item, index, array) {
            if (item[match] === value) {
                idx = index;
            }
        });

        return idx;
    }

    getIndexByValFromArray(arr, match) {
        let idx = 0;

        arr.forEach(function (item, index) {
            if (item === match) {
                idx = index;
            }
        });

        return idx;
    }
}
