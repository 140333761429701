import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserType } from 'src/app/models/user-type';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { ToastrService } from 'ngx-toastr';
import { paginationConfig } from 'src/app/constants';
import { ApiurlsService } from 'src/app/_services/api-urls/apiUrls.service';
import { AuthenticationService } from 'src/app/authentication.service';
@Component({
  selector: 'app-user-type-list',
  templateUrl: './user-type-list.component.html',
  styleUrls: ['./user-type-list.component.css']
})
export class UserTypeListComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private urls: ApiurlsService,
    private dialog: MatDialog,
    private api: ApiDataServices,
    private toastr: ToastrService
  ) { auth.checkAuth(); }
  @ViewChild('deleteModalButton') deleteModalButton: ElementRef;
  @ViewChild('cancelModalButton') cancelModalButton: ElementRef;
  userTypes: UserType[];
  loading = true;
  isDeleting = false;
  isError = false;
  errorMessage = "Sorry, Something went wrong";
  selectedUserType: UserType = new UserType();
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize = 20;

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;

  ngOnInit(): void {
    this.userTypes = [];
    this.getUserTypes();
  }

  pageChange(page) {
    this.getUserTypes(page);
  }

  getUserTypes(page = 1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this.api.postApiData(this.urls.userTypeList, data).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.userTypes = data.data;
          if (data.meta.pageLength && data.meta.page) {
            this.collectionsize = data.meta.total;
            this.maxPages = data.meta.pages;
            this.endCount = (this.page == this.maxPages) ?
              (this.collectionsize < this.pageLength) ? this.collectionsize :
                ((this.collectionsize % this.pageLength == 0)) ?
                  this.collectionsize :
                  ((this.page == this.maxPages)) ? this.collectionsize :
                    this.endCount + (this.collectionsize % this.pageLength) :
              (this.page * this.pageLength);
          } else {
            this.tableloading = true;
          }
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.tableloading = false;
        });
  }

  showDeleteModal(userType) {
    // this.isDeleting = false;
    // let inputElement: HTMLElement = this.deleteModalButton.nativeElement as HTMLElement;
    // inputElement.click();
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure, you want to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedUserType = userType;
          this.delete();
        }
      }
    );
  }
  delete() {
    if (this.selectedUserType) {
      this.isDeleting = true;
      this.api.putApiData(this.urls.userTypeDelete(this.selectedUserType.idUserType)).pipe()
        .subscribe(
          (data: any) => {
            this.isDeleting = false;
            // let inputElement: HTMLElement = this.cancelModalButton.nativeElement as HTMLElement;
            // inputElement.click();
            this.toastr.success('UserType deleted successfully', 'Deleted');
            let index = this.userTypes.indexOf(this.selectedUserType);
            if (index >= -1) {
              this.userTypes.splice(index, 1);
            }
            this.selectedUserType = new UserType();
          },
          error => {
            this.loading = false;
            this.isError = true;
          });
    } else {
      alert('User not found');
    }
  }

}
