import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common';
import { apiUrls } from '../../api-urls';
import { ApiDataServices } from '../../_services/apiservices/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EditAdsService } from '../../core/services/editad/edit-ads.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-ad',
  templateUrl: './edit-ad.component.html',
  styleUrls: ['./edit-ad.component.css']
})
export class EditAdComponent implements OnInit {


  imageData: any;
  isLoading: boolean = false;
  dataForm: any = [];
  imgDataArr: any = [];
  imgDataObj: any = {};
  imgData: any;
  selectedTitle: string;
  form: FormGroup;
  adTitle: string;
  adDetails: boolean = true;
  idAdCategory: string;
  dateRangeParam: any;
  dateParam: any;
  priceParam: any;
  tagParam: any;
  categoryCode: any;
  editAdParams: any;
  isSaving: boolean;
  idAdvertisement: any;
  videoInputName: any;

  parentCategory: any;
  childCategory: any;
  grandChildCategory: any;
  hint: any;
  hintData: any;


  pipe = new DatePipe('en-US');
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '500px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter your ad description here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Heading',
        class: 'titleText',
        tag: 'h1',
      }, {
        name: 'Sub HEading',
        class: 'subtitleText',
        tag: 'h2',
      }
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage',
      'insertVideo']]
  };

  constructor(
    private api: ApiDataServices,
    private route: ActivatedRoute,
    private router: Router,
    private editadService: EditAdsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.adDetails = true;
    this.isLoading = true;
    this.route.paramMap.subscribe(params => {
      var ad_id = params.get('id');
      this.idAdvertisement = ad_id;
      this.getEditAdParams(this.idAdvertisement);
    });
  }

  getEditAdParams(idAd) {
    this.api.postApiData(apiUrls.advertisementGet(idAd)).pipe()
      .subscribe(
        (data: any) => {
          if (data) {
            localStorage.setItem('showphone', data.data?.showPhone == 1 ? '1' : '0');
            this.editAdParams = data.data;
            this.categoryCode = data.data?.categoryTree[0].categorycode;
            let dt: any = data.data;
            let additionalData = {};
            let imageObj;
            let outerObj;
            this.changeadTitle(dt.adType);
            additionalData['adDesc'] = dt?.adDesc;
            additionalData['adTitle'] = dt?.adTitle;
            additionalData['adType'] = dt?.adType;
            additionalData['advertiserType'] = dt?.advertiserType;
            additionalData['additionalParams'] = dt?.additionalParams;
            // additionalData['additionalParams'].forEach((xyz) => {

            // })
            this.setBreadCrum(this.editAdParams?.categoryTree);
            // this.checkDateanddateRange(dt?.additionalParams);
            // this.checkPriceBlock(dt?.additionalParams);
            this.idAdCategory = dt?.idAdCategory;
            dt?.additionalParams.filter((data) => {
              if (data.block == "Media") {
                outerObj = data.params;
              }
            });
            if (outerObj) {
              outerObj.filter((data) => {
                if (data.paramType == 'image') {
                  imageObj = data.values
                } else if (data.paramType == 'video') {
                  this.videoInputName = data.inputName
                }
              })
            }
            if (imageObj) {
              imageObj.forEach(element => {
                let tempObj = {};
                tempObj["idAdGallery"] = element.idAdGallery;
                tempObj["image"] = element.value;
                this.imgDataObj[element.name] = tempObj;

              });
              this.imgDataArr.push(this.imgDataObj);
            }
            this.buildForm(dt?.additionalParams)
            // this.dataForm = additionalData;

            this.form = this.editadService.toFormGroup(additionalData);
            this.isLoading = false;

          }

        }
      );
  }

  setBreadCrum(categoryTree) {
    this.parentCategory = categoryTree[0];
    this.childCategory = this.parentCategory?.child ? this.parentCategory?.child[0] : null;
    this.grandChildCategory = this.childCategory?.child ? this.childCategory.child[0] : null;
  }
  changeadTitle(val) {
    this.selectedTitle = val;
  }
  // checkDateanddateRange(data) {
  //   this.dateParam = [];
  //   this.dateRangeParam = [];
  //   data.forEach(blockElement => {
  //     if (blockElement['block'] == "Details") {
  //       blockElement.params.forEach(element => {
  //         if (element.paramType == 'date') {
  //           this.dateParam.push(element.inputName)
  //         } else if (element.paramType == 'daterange') {
  //           this.dateRangeParam.push(element.inputName)
  //         }
  //       });
  //     }
  //   });
  // }
  // checkPriceBlock(data) {
  //   data.forEach(blockElement => {
  //     blockElement['block'] == "Price" ? this.priceParam = true : null;
  //   });
  // }
  getResponse(data) {
    this.imgDataObj[data].image = '';
  }
  imageOrder: any = [];
  getImageOrder(data) {
    let i = 1
    data.forEach(element => {
      this.imageOrder[element.name] = i++;
    });
  }
  isvalidVideoLink: boolean = true;
  validateVideoLink() {
    if (this.videoInputName) {
      if (this.form.getRawValue()[this.videoInputName]) {
        (this.form.getRawValue()[this.videoInputName].includes('youtube.com') || this.form.getRawValue()[this.videoInputName].includes('vimeo.com')) ?
          this.isvalidVideoLink = true : this.isvalidVideoLink = false
      }
    }
  }

  buildForm(data) {
    // var postAdForm = [];
    this.dateParam = [];
    this.dateRangeParam = [];
    this.tagParam = [];
    data.forEach(blockElement => {
      if (blockElement['block'] == "Details") {
        blockElement.params.forEach(element => {
          if (element.paramType == 'date') {
            this.dateParam.push(element.inputName)
          } else if (element.paramType == 'daterange') {
            this.dateRangeParam.push(element.inputName)
          } else if (element.paramType == 'tag') {
            this.tagParam.push(element.inputName)
          }
        });
      } else if (blockElement['block'] == "Price") {
        this.categoryCode == 9967471 ? blockElement['block'] = 'Salary' : null;
        this.priceParam = true
      } else if (blockElement['block'] == "postHint") {
        blockElement.params.forEach(element => {
          this.hint = element.text;
          this.hintData = element.title;
        })
      } else if (blockElement['block'] == "Location") {
        this.categoryCode == 9967471 ? blockElement['block'] = 'Job Location' : null;
        blockElement['params'].forEach((data) => {
          data['inputName'] == 'city' ? blockElement['params'].splice(blockElement['params'].indexOf(data), 1) : null;
        })
      }
      blockElement['block'] == "postHint" ? null : this.dataForm.push(blockElement);
    });
  }
  onSubmit() {
    let userInfo = {};
    let additionalData = {};
    userInfo['idAdCategory'] = this.idAdCategory;
    this.isSaving = true;
    let postForm = {};
    this.validateVideoLink();

    if (this.form.valid && this.isvalidVideoLink) {
      if (this.form.getRawValue()) {
        let min = this.form.getRawValue()['minsalary'];
        let max = this.form.getRawValue()['maxsalary'];
        if (min === 0 || max === 0 || min || max) {
          if (min === 0 || min) {
            if (max) {
              if (min <= max) {
                postForm['salary'] = min + ',' + max;
              } else {
                // 'Maximum value should be greater than or equal to minimum value';
                return
              }
            } else if (max === 0) {
              // 'Maximum value should be greater than zero';
              return
            } else {
              // 'Maximum value is required';
              return
            }
          } else {
            // 'Minimum value is required'
            return
          }
        }
        this.dateRangeParam.forEach(element => {
          if (this.form.getRawValue()[element]) {
            let from = this.pipe.transform(this.form.getRawValue()[element][0], 'MM/dd/yyyy');
            let to = this.pipe.transform(this.form.getRawValue()[element][1], 'MM/dd/yyyy');
            postForm[element] = from + ',' + to;
          }
        });
        this.dateParam.forEach(element => {
          if (this.form.getRawValue()[element]) {
            postForm[element] = this.pipe.transform(this.form.getRawValue()[element], 'MM/dd/yyyy');
          }
        });
        if (this.form.getRawValue()['tags']) {
          let tagArr = [];
          this.form.getRawValue()['tags'].forEach(keyword => {
            keyword ? keyword?.value ? tagArr.push(keyword.value) : tagArr.push(keyword) : null;
          })
          postForm['tags'] = tagArr.toString();

        }
        this.categoryCode == 9967471 ? postForm['offeredBy'] = this.form.getRawValue().advertiserType : null;
        this.priceParam ? (this.form.getRawValue().price ? null : postForm['price'] = 0.0) : null;

        let data: any = {
          ...userInfo, ...additionalData, ...this.form.getRawValue(), ...postForm
        }
        for (let key in this.imgDataObj) {
          if (data[key]) {
            this.imgDataObj[key].image = data[key];
          }

          data[key] = this.imgDataObj[key];
        }
        //only for Job category type is job and offering for others
        data['adType'] = this.categoryCode == 9967471 ? 'Job' : 'Offering';
        data['showPhone'] = localStorage.getItem('showphone') ? localStorage.getItem('showphone') : 0;
        data['city'] = this.form.getRawValue()['address'];
        this.api.putApiData(apiUrls.advertisementUpdate(this.idAdvertisement), data).pipe()
          .subscribe(
            (data: any) => {
              this.adDetails = false;
              this.isSaving = false;
              this.toastr.success('Advertisement updated successfully', 'Done');
              this.router.navigate(['/advertisements/list']);
              localStorage.removeItem('showphone')
            },
            (error: any) => {
              let message = error.error?.errors ? error.error.errors.price ? error.error.errors.price[0] : error.error.errors.message ? error.error.errors.message : '' : '';
              message = message ? message : "Something Went Wrong";
              this.toastr.error(message, 'Error');
              // console.log(error);

              this.isSaving = false;
            });
      }
    } else {
      this.adDetails = true;
      this.form.markAllAsTouched();
    }
  }

}
