import { Component, OnInit } from '@angular/core'; import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Advertisement } from 'src/app/models/advertisement';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrls } from 'src/app/api-urls';
import { ToastrService } from 'ngx-toastr';
import { AdminUser } from 'src/app/models/admin-user';

@Component({
  selector: 'app-link-ad',
  templateUrl: './link-ad.component.html',
  styleUrls: ['./link-ad.component.css']
})
export class LinkAdComponent implements OnInit {

  constructor(private api: ApiDataServices,
    private route: Router,
    private actRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  advertisementForm: FormGroup;
  isLinking = false;
  linkingBadgeId: any;
  errorMessage = "";
  loading = true;
  badges: any[];
  idAdvertisement = null;
  categoryParams: any = [];
  categoryParamOptions = [];
  selectedParamOptions: any[] = [];
  advertisement = new Advertisement();
  adminUser = new AdminUser();
  ngOnInit(): void {
    this.getBadges();
    this.actRoute.paramMap.subscribe(params => {
      this.idAdvertisement = params.get('id');
    });
  }

  getBadges() {
    this.api.postApiData(apiUrls.badgeList).pipe()
      .subscribe(
        (data: any) => {
          this.badges = data.data;
          this.updateBadgeList();
        },
        (error: any) => {
          this.loading = false;
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
        });
  }
  promotedBadges: any
  updateBadgeList() {
    let i = 0;
    this.promotedBadges = JSON.parse(localStorage.getItem('promotedBadge'));
    if (this.badges.length > 0) {
      this.badges.filter(data => {
        this.badges[i]['isPromoted'] = false;
        if (this.promotedBadges.length > 0) {
          this.promotedBadges.filter(idBadge => {
            if (idBadge == data.idBadgeConfig) {
              this.badges[i]['isPromoted'] = true;
            }
          })
        }
        i++;
      })
    }
    this.loading = false;
  }
  hit(badgeid) {
    this.linkingBadgeId = badgeid;
    this.isLinking = true;
    let data = {
      "idAdvertisement": this.idAdvertisement,
      "idBadge": badgeid
    }
    this.api.putApiData(apiUrls.advertisementBadgeLink, data).pipe()
      .subscribe(
        (data: any) => {
          this.isLinking = false;
          this.toastr.success(data.data.message, 'Done');
          this.route.navigate(['/advertisements/list']);
        },
        (error: any) => {
          this.errorMessage = 'Sorry, something went wrong.';
          this.isLinking = false;
        });
  }
}
