import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { xoomlaConfig } from '../config/xoomla.config';

@Injectable()
export class ApiurlsService {
    constructor(public config: xoomlaConfig) { }


    /* get Session key */
    getSessionKeyUrl: string = `${environment.apiUrl}/get/sessionkey`;

    
    /* Usertype */
    userTypeList: string = `${environment.apiUrl}/usertype/list`;
    userTypeCreate: string = `${environment.apiUrl}/usertype/create`;
    userTypeGet(userId: string) {return `${environment.apiUrl}/usertype/${userId}` }
    userTypeUpdate(userId: string) {return `${environment.apiUrl}/usertype/update/${userId}` }
    userTypeDelete(userId: string) {return `${environment.apiUrl}/usertype/delete/${userId}` }



}