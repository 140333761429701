import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from '../utilities/utilities.services';
import { UserAcl } from '../models/user-Acl';
import { ApiDataServices } from '../_services/apiservices/api.service';
import { ConfirmdialogComponent } from '../common/dialog/confirmdialog/confirmdialog.component';
import { ToastrService } from 'ngx-toastr';
import { paginationConfig } from 'src/app/constants';
import { apiUrls } from '../api-urls';
import { AuthenticationService } from '../authentication.service';



@Component({
  selector: 'app-user-acl',
  templateUrl: './user-acl.component.html',
  styleUrls: ['./user-acl.component.css'],
})
export class UserACLComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private toastr: ToastrService,
    public api: ApiDataServices,
    private dialog: MatDialog,
    private _utils: UtilityService) { auth.checkAuth(); }
  @ViewChild('deleteModalButton') deleteModalButton: ElementRef;
  @ViewChild('cancelModalButton') cancelModalButton: ElementRef;
  // userACL: UserAcl[];
  userAclList: any = [];
  loading = true;
  isDeleting = false;
  errorMessage = "Sorry, Something went wrong";
  selectedUserAcl: UserAcl = new UserAcl();
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize = 20;

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;

  ngOnInit(): void {
    this.getUserACLList();
  }

  // pageChanged(event) {
  //   this.paginationConfig.currentPage = event;
  //   this.getUserACLList(event);
  // }

  pageChange(page) {
    this.getUserACLList(page);
  }

  //Getting Useracl list
  getUserACLList(page = 1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this.api.postApiData(apiUrls.userAclList, data).subscribe(
      (data: any) => {
        this.loading = false;
        this.userAclList = data.data;
        if (data.meta.pageLength && data.meta.page) {
          this.collectionsize = data.meta.total;
          this.maxPages = data.meta.pages;
          this.endCount = (this.page == this.maxPages) ?
            (this.collectionsize < this.pageLength) ? this.collectionsize :
              ((this.collectionsize % this.pageLength == 0)) ?
                this.collectionsize :
                ((this.page == this.maxPages)) ? this.collectionsize :
                  this.endCount + (this.collectionsize % this.pageLength) :
            (this.page * this.pageLength);
        } else {
          this.tableloading = true;
        }
      },
      error => {
        this.loading = false;
        this.tableloading = false;
      }
    );
  }

  //Deleting UserAcl using (idUserAcl)
  confirmDelete(res) {
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) this.deleteuseracl(res);
      }
    );
  }
  deleteuseracl(res) {
    this.api.putApiData(apiUrls.userAclDelete(res.idUserAcl)).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.toastr.success('UserACL deleted successfully', "Deleted");
          this.deleteuseraclFromList(res);
        },
        error => {

        },
        () => { }
      );
  }
  deleteuseraclFromList(res) {
    this.userAclList = this._utils.filterArrayObj(this.userAclList, 'idUserAcl', res.idUserAcl);
  }

  checkActive(active, type) {

    if (active) {
      return type === 'bool' ? true : 'Active';
    } else {
      return type === 'bool' ? false : 'Inactive';
    }
  }

  //Function for toggle button
  toggleActive(useracl, e) {
    let status = e.checked ? 'true' : 'false';
    let active = e.checked ? '0' : '1';
    const activeDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to ' + status + ' the ' + useracl.namespace + '?'
      }
    });

    activeDialog.afterClosed().subscribe(
      data => {
        (data) ? 'this.toggleActiveUser(useracl.id, e)' : this.userAclList = this._utils.changeArrayObjProp(this.userAclList, 'id', useracl.id, 'false', !e.checked);
      }
    );
  }

  toggleActiveuseracl(id, e) {
    let data = {
      id: id,
      active: e.checked ? 1 : 0
    };
    this.userAclList = this._utils.changeArrayObjProp(this.userAclList, 'id', id, 'false', e.checked);
    this.api.putApiData('this._urls.userActiveUrl', data).subscribe(
      (data: any) => {
        let id = data.data.id;
        this.userAclList = this._utils.changeArrayObjProp(this.userAclList, 'id', id, 'false', e.checked);
      },
      error => {

      },
      () => {

      });
  }

}
