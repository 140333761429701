import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
declare const Pusher: any;
@Injectable({
  providedIn: 'root'
})
export class PusherService {
  messagesChannel: any;
  pusher: any;
  constructor() {
    this.pusher = new Pusher(environment.pusher.key,{cluster : "ap2"});
    this.messagesChannel = this.pusher.subscribe('adChat.19db87d2-8147-4ca0-b55a-38c063752dc8');
    // console.log(this.messagesChannel);
  }
}
