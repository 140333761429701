import { ChangeDetectorRef, Component, OnInit, Input, Output, Renderer2, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { PostAdsService } from 'src/app/core/services/postads/post-ads.service';
import { HttpClient } from '@angular/common/http';
import { apiUrls } from 'src/app/api-urls';
import { fromEvent, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { mergeAll } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { AdParamsDetails } from '../../models/postads/ad-params.model';
import {
  CdkDrag,
  CdkDragStart,
  CdkDropList,
  CdkDropListGroup,
  CdkDragMove,
  CdkDragEnter,
  moveItemInArray
} from "@angular/cdk/drag-drop";
import { ViewportRuler } from "@angular/cdk/overlay";

@Component({
  selector: 'app-ad-builder',
  templateUrl: './ad-builder.component.html',
  styleUrls: ['./ad-builder.component.css']
})
export class AdBuilderComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  @Input() formData: AdParamsDetails;
  @Input() form: FormGroup;
  @Input() ind: any;
  @Input() croute: string;
  @Output() response: EventEmitter<any> = new EventEmitter;
  @Output() imageOrder: EventEmitter<any> = new EventEmitter();
  locationacd;
  @ViewChild('locationacd') set content(content: ElementRef) {
    this.locationacd = content;
    if (this.locationacd) {
      this.autocomplete = new google.maps.places.Autocomplete(this.locationacd.nativeElement);
      this.autocomplete.setFields(
        ['address_components', 'geometry', 'icon', 'name']);

      this.autocomplete.addListener('place_changed', () => {

        let place = this.autocomplete.getPlace();
        if (place) {
          place.geometry ? (this.selectedCity = this.locationacd.nativeElement.value,
            this.form.patchValue({
              ['city']: this.locationacd.nativeElement.value
            })) : null;
          if (!place.geometry) {
            if (this.selectedCity != this.locationacd.nativeElement.value) {
              this.form.patchValue({
                ['city']: ''
              })
            }
            return;
          }
        }

      });
    }
  }

  addressacd;
  @ViewChild('addressacd') set address(address: ElementRef) {
    this.addressacd = address;
    if (this.addressacd) {
      this.addressautocomplete = new google.maps.places.Autocomplete(this.addressacd.nativeElement);
      this.addressautocomplete.setFields(
        ['address_components', 'geometry', 'icon', 'name']);

      this.addressautocomplete.addListener('place_changed', () => {

        let place = this.addressautocomplete.getPlace();
        this.form.patchValue({
          ['address']: this.addressacd.nativeElement.value
        })
      });
    }
  }


  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  imageSrc: string;
  chkArr: any = [];
  tagval: any = [];
  currentRoute: boolean;
  autocomplete;
  addressautocomplete;
  isUplink: any = [];
  selectedVal: any = [];
  selectedCity: any;
  selectedAddress: any;
  isShowPhone: any = 1;
  isValidSize = true;
  imageId: any = [];
  private searchUrl = apiUrls.tagSuggestions;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private postAdService: PostAdsService,
    private http: HttpClient,
    private viewportRuler: ViewportRuler) {
    this.target = null;
    this.source = null;
  }

  ngOnInit(): void {
    this.selectedCity = this.form['value']['city'];
    this.selectedAddress = this.form['value']['address'];
    let radio = this.formData;
    if (this.formData['block'] == "Details") {
      this.formData.params.forEach(element => {
        if (element.paramType == 'radio') {
          this.changeRadio(element.inputName, element.inputValue);
        }
      });
    } else if (this.formData['block'] == "Media") {
      this.formData.params.forEach(element => {
        if (element.paramType == 'image') {
          let i = element.values.length - 1;
          element.values.forEach(val => {
            if (val['order']) {
              this.imageId[(val['order'] - 1)] = val;
            } else if (val['order'] == 0) {
              this.imageId[i] = val;
              i = i - 1;
            } else {
              this.imageId.push(val);
            }
            this.isUplink.push(false)
          })
        }
      });

    }
    this.isShowPhone = localStorage.getItem('showphone') ? localStorage.getItem('showphone') : 1;
    this.isShowPhone = this.isShowPhone == 0 ? 0 : 1;
  }

  ngAfterViewInit() {
    if (this.formData['block'] == 'Media') {
      let phElement = this.placeholder.element.nativeElement;
      phElement.style.display = "none";
      phElement.parentElement.removeChild(phElement);
    }
  }

  onFileChange(event, id, setId, i, isEdit = false) {
    this.isValidSize = true;
    let p = i;
    let imageOption = event.target.files;
    if (event.target.files && event.target.files.length) {
      for (let data of imageOption) {
        if (i < this.imageId.length) {
          id = this.imageId[i]['name']
          // file size should be within 4MB
          if (data.size > 4000000) {
            if (p == i) {
              isEdit ? this.editClose(id, i) : this.close(id, i)
              this.isValidSize = false;
            }
          } else {
            if (p == i) {
              this.uploadImage(id, i, data)
            } else {
              this.checkEmptyImage(data, isEdit)
            }
          }
        } else {
          this.checkEmptyImage(data, isEdit)
        }
        i = i + 1;
      }
    }
  }

  checkEmptyImage(data, isEdit) {
    for (let j = 0; j < this.imageId.length; j++) {
      if ((!isEdit && !this.isUplink[j]) || (isEdit && (this.isUplink[j] == 2 || this.imageId[j]['value'] == ''))) {
        let id = this.imageId[j]['name']
        // file size should be within 4MB
        if (data.size > 4000000) {
          // this.isValidSize = false;
        } else {
          this.imageId[j]['value'] = 'true'
          this.uploadImage(id, j, data)
          j = 100;
        }
      }
    }

  }

  uploadImage(id, i, image) {
    const reader = new FileReader();
    let element = document.getElementById('lbl' + id);
    this.isUplink[i] = 1;
    reader.readAsDataURL(image);
    reader.onload = () => {
      this.imageSrc = 'url(' + reader.result as string + ')';
      this.renderer.setStyle(element, 'background-image', this.imageSrc);
      try {
        this.form.patchValue({
          [id]: reader.result as string
        })
      } catch (err) {

      }

      this.cdr.markForCheck();
    };

  }
  close(id, i) {
    let element = document.getElementById('lbl' + id);
    this.renderer.setStyle(element, 'background-image', '');
    this.isUplink[i] = false;
    this.form.patchValue({
      [id]: ''
    })
  }
  editClose(id, i) {
    let element = document.getElementById('lbl' + id);
    this.renderer.setStyle(element, 'background-image', '');
    this.isUplink[i] = 2;
    this.form.patchValue({
      [id]: ''
    })
    this.response.emit(id);
  }
  changeRadio(key, val) {
    this.selectedVal[key] = val;
    this.form.patchValue({
      [key]: val
    })
  }
  isvalidVideoLink: boolean;
  focusOutVideo(e) {
    let videoLink = e.target.value;
    (videoLink.includes('youtube.com') || videoLink.includes('vimeo.com')) ? this.isvalidVideoLink = false : this.isvalidVideoLink = true;
  }

  focusOutFunction() {
    // console.log(this.locationacd.nativeElement.value)
    setTimeout(() => {
      if (this.selectedCity) {
        // console.log(this.selectedCity)
        if (this.selectedCity != this.locationacd.nativeElement.value) {
          this.form.patchValue({
            ['city']: ''
          })
        }
      } else {
        // console.log(this.selectedCity)
        this.form.patchValue({
          ['city']: ''
        })
      }
    }, 1000);
  }
  addressLocation() {
    // console.log(this.addressacd.nativeElement.value)
    setTimeout(() => {
      if (this.selectedAddress) {
        // console.log(this.selectedAddress)
        if (this.selectedAddress != this.addressacd.nativeElement.value) {
          this.form.patchValue({
            ['address']: ''
          })
        }
      } else {
        // console.log(this.selectedAddress)
        this.form.patchValue({
          ['address']: ''
        })
      }
    }, 1000);
  }
  item: any = [];
  public requestAutocompleteItems = (text: string): Observable<any> => {
    const url = this.searchUrl + `?searchKeyword=${text}`;
    return this.http
      .get(url)
      .map((data: any) => data.data?.result ? data.data?.result.map(item => item.suggestion) : null);
  }

  isChecked() {
    this.isShowPhone = this.isShowPhone == 1 || this.isShowPhone == true ? 0 : 1;
    // console.log(this.isShowPhone);
    localStorage.setItem('showphone', this.isShowPhone);
  }
  maxsalaryError: any;
  minsalaryError: any;
  focusOutSalary() {
    let min = this.form.getRawValue()['minsalary'];
    let max = this.form.getRawValue()['maxsalary'];
    if (min === 0 || max === 0 || min || max) {
      if (min === 0 || min) {
        this.minsalaryError = '';
        if (max) {
          if (min <= max) {
            this.maxsalaryError = '';
          } else {
            this.maxsalaryError = 'Maximum value should be greater than or equal to minimum value';
          }
        } else if (max === 0) {
          this.maxsalaryError = 'Maximum value should be greater than zero';
        } else {
          this.maxsalaryError = 'Maximum value is required';
        }
      } else {
        this.maxsalaryError = '';
        this.minsalaryError = 'Minimum value is required';
      }
    } else {
      this.minsalaryError = '';
      this.maxsalaryError = '';
    }
  }
  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped() {
    if (!this.target) return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = "none";

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex)
      moveItemInArray(this.imageId, this.sourceIndex, this.targetIndex);
    moveItemInArray(this.isUplink, this.sourceIndex, this.targetIndex);
    this.imageOrder.emit(this.imageId);
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder) return true;

    if (drop != this.activeContainer) return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(
      dropElement.parentElement.children,
      this.source ? phElement : sourceElement
    );
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + "px";
      phElement.style.height = sourceElement.clientHeight + "px";

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = "";
    dropElement.parentElement.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder.enter(
      drag,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    );
    return false;
  };

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event)
      ? event.touches[0] || event.changedTouches[0]
      : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith("touch");
}

function __isInsideDropListClientRect(
  dropList: CdkDropList,
  x: number,
  y: number
) {
  const {
    top,
    bottom,
    left,
    right
  } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}

