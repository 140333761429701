import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { paginationConfig } from '../constants';
import { Translation } from 'src/app/models/translation';
import { AuthenticationService } from '../authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiDataServices } from '../_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmdialogComponent } from '../common/dialog/confirmdialog/confirmdialog.component';
import { apiUrls } from '../api-urls';
import { Language } from '../models/language';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-translation-list',
  templateUrl: './translation-list.component.html',
  styleUrls: ['./translation-list.component.css']
})
export class TranslationListComponent implements OnInit {

  @ViewChild('deleteModalButton') deleteModalButton: ElementRef;
  @ViewChild('cancelModalButton') cancelModalButton: ElementRef;
  translations: Translation[];
  loading = true;
  isDeleting = false;
  isError = false;
  errorMessage = "Sorry, Something went wrong";
  selectedTranslation: Translation = new Translation();
  tableloading = true;
  paginationConfig: any = paginationConfig;
  languages: Language[];
  selectedIdLanguage: string = '';
  searchedValue: string = '';
  formGroup: FormGroup;
  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  constructor(private auth: AuthenticationService,
    private dialog: MatDialog,
    private api: ApiDataServices,
    private toastr: ToastrService, private fb: FormBuilder) {
    auth.checkAuth();
  }

  ngOnInit(): void {
    this.translations = [];
    this.getLanguages();
    this.getTranslations();
    // this.formgroupInit();
  }

  // formgroupInit() {
  //   this.formGroup = this.fb.group({
  //     'selectValue': [''],
  //     'searchValue': ['']
  //   })
  // }

  // pageChanged(event) {
  //   this.paginationConfig.currentPage = event;
  //   this.getTranslations(event);
  // }
  pageChange(page) {
    this.getTranslations(page);
  }


  getTranslations(page = 1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    if (this.selectedIdLanguage) data['idLanguage'] = this.selectedIdLanguage;
    if (this.searchedValue) data['searchKeyword'] = this.searchedValue;
    this.api.postApiData(apiUrls.translationList, data).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.translations = data.data;
          if (data.meta.pageLength && data.meta.page) {
            this.collectionsize = data.meta.total;
            this.maxPages = data.meta.pages;
            this.endCount = (this.page == this.maxPages) ?
              (this.collectionsize < this.pageLength) ? this.collectionsize :
                ((this.collectionsize % this.pageLength == 0)) ?
                  this.collectionsize :
                  ((this.page == this.maxPages)) ? this.collectionsize :
                    this.endCount + (this.collectionsize % this.pageLength) :
              (this.page * this.pageLength);
            this.tableloading = false;
          } else {
            this.tableloading = true;
          }
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.tableloading = false;
        });
  }

  getLanguages() {
    this.api.postApiData(apiUrls.languageList, {}).pipe()
      .subscribe(
        (data: any) => {
          this.languages = data.data;
        },
        error => {
          // console.log(error);

        });
  }

  selectedLanguage(res) {
    this.selectedIdLanguage = res.idLanguage;
    this.getTranslations()

  }



  showDeleteModal(translation) {
    this.isDeleting = false;
    // let inputElement: HTMLElement = this.deleteModalButton.nativeElement as HTMLElement;
    // inputElement.click();

    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure, you want to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedTranslation = translation;
          this.delete();
        }
      }
    );
  }

  public delete() {
    if (this.selectedTranslation) {
      this.isDeleting = true;
      this.api.putApiData(apiUrls.translationDelete(this.selectedTranslation.idTranslation)).pipe()
        .subscribe(
          (data: any) => {
            this.isDeleting = false;
            // let inputElement: HTMLElement = this.cancelModalButton.nativeElement as HTMLElement;
            // inputElement.click();
            this.toastr.success('Translation deleted successfully', "Deleted");
            let index = this.translations.indexOf(this.selectedTranslation);
            if (index >= -1) {
              this.translations.splice(index, 1);
            }
            this.selectedTranslation = new Translation();
          },
          error => {
            this.loading = false;
            this.isError = true;
          });
    } else {
      alert('Language not found');
    }
  }

  searchedSlug(ev) {
    this.searchedValue = ev.target.value;
    this.getTranslations();
  }
}
