import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiurlsService } from 'src/app/_services/api-urls/apiUrls.service';

@Component({
  selector: 'app-add-user-type',
  templateUrl: './add-user-type.component.html',
  styleUrls: ['./add-user-type.component.css']
})
export class AddUserTypeComponent implements OnInit {

  constructor(
    private api: ApiDataServices, 
    private url: ApiurlsService, 
    private formBuilder: FormBuilder,
    private route: Router) { }

  userTypeForm: FormGroup;
  submitted = false;
  isError = false;
  errorMessage = "";
  isSaving = false;

  ngOnInit(): void {
    this.userTypeForm = this.formBuilder.group({
      userTypeName: ['', Validators.required],
      userTypeNum: ['', Validators.required],
      userTypeSlug: ['', Validators.required]
    });
  }

  
  // convenience getter for easy access to form fields
  get f() { return this.userTypeForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.userTypeForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    this.api.postApiData(this.url.userTypeCreate, this.userTypeForm.getRawValue()).pipe()
    .subscribe(
        (data: any) => {
          this.isSaving = false;
          this.submitted = false;
          this.route.navigate(['/user-types/list']);
        },
        (error: any) => {
          debugger
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
          this.isSaving = false;
          this.submitted = false;
          this.isError = true;
        });
  }

}
