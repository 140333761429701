import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from 'src/app/models/location';
import { apiUrls } from 'src/app/api-urls';

@Component({
  selector: 'app-locations-add',
  templateUrl: './locations-add.component.html',
  styleUrls: ['./locations-add.component.css']
})
export class LocationsAddComponent implements OnInit {

  constructor(private api: ApiDataServices,  
    private formBuilder: FormBuilder,
    private route: Router) { }

    locationForm: FormGroup;
    submitted = false;
    isError = false;
    errorMessage = "";
    isSaving = false;
    loadingLocations = false;
    locations: Location[];
    ngOnInit(): void {
      this.getLocations();
      this.locationForm = this.formBuilder.group({
        locationName: ['', Validators.required],
        parent: ['', ]
      });
    }
  
    
    // convenience getter for easy access to form fields
    get f() { return this.locationForm.controls; }
    
    getLocations(){
      this.loadingLocations = true;
      this.api.postApiData(apiUrls.locationList).pipe()
      .subscribe(
          (data: any) => {
            this.loadingLocations = false;
            this.locations = data.data;
          },
          error => {
            this.loadingLocations = false;
          });
    }

    onSubmit(){
      this.submitted = true;
      if (this.locationForm.invalid) {
        return;
      }
      this.isError = false;
      this.isSaving = true;
      this.api.postApiData(apiUrls.locationCreate, this.locationForm.getRawValue()).pipe()
      .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.route.navigate(['/locations/list']);
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });
    }
  

}
