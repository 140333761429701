import { RouterModule } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
// Components
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
// Import Plugins
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CustomFormsModule } from 'ng2-validation';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Error404Component } from './error-404/error-404.component';
import { Error500Component } from './error-500/error-500.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LaddaModule } from 'angular2-ladda';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// Import Services
import { SettingsService } from './_services/settings.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
// Import routes
import { routes } from './app.routes';
import { LoginComponent } from './accounts/login/login.component';
import { UserACLComponent } from './user-acl/user-acl.component';
import { xoomlaConfig } from './_services/config/xoomla.config';
import { ApiDataServices } from './_services/apiservices/api.service';
import { StorageService } from './_services/Localstorage/storage.service';
import { ApirequestService } from './_services/apirequest/apirequest.service';
import { AddUserAclComponent } from './user-acl/add-user-acl/add-user-acl.component';
import { UserTypeListComponent } from './user-type/user-type-list/user-type-list.component';
import { AddUserTypeComponent } from './user-type/add-user-type/add-user-type.component';
import { EditUserTypeComponent } from './user-type/edit-user-type/edit-user-type.component';
import { LocationsListComponent } from './locations/locations-list/locations-list.component';
import { LocationsAddComponent } from './locations/locations-add/locations-add.component';
import { LocationsEditComponent } from './locations/locations-edit/locations-edit.component';
import { UtilityService } from './utilities/utilities.services';
import { MaterialModule } from './modules/material/material.module';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { ConfirmdialogComponent } from './common/dialog/confirmdialog/confirmdialog.component';
import { UpdateUserAclComponent } from './user-acl/update-user-acl/update-user-acl.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { AddCategoryComponent } from './category/add-category/add-category.component';
import { EditCategoryComponent } from './category/edit-category/edit-category.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { UserAddComponent } from './user/user-add/user-add.component';
import { DragNDropComponent } from './components/drag-n-drop/drag-n-drop.component';
import { BadgeconfigListComponent } from './badge-config/badgeconfig-list/badgeconfig-list.component';
import { BadgeconfigAddComponent } from './badge-config/badgeconfig-add/badgeconfig-add.component';
import { BadgeconfigEditComponent } from './badge-config/badgeconfig-edit/badgeconfig-edit.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ApiurlsService } from './_services/api-urls/apiUrls.service';
import { BannerAddComponent } from './banner/banner-add/banner-add.component';
import { BannerEditComponent } from './banner/banner-edit/banner-edit.component';
import { BannerListComponent } from './banner/banner-list/banner-list.component';
import { ProfileComponent } from './accounts/profile/profile.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { MessagesComponent } from './messages/messages.component';
import { PusherService } from './pusher.service';
import { SettingsComponent } from './settings/settings/settings.component';
import { LanguageListComponent } from './language/language-list.component';
import { LanguageUpsertComponent } from './language/upsert/language-upsert.component';
import { TranslationListComponent } from './translation/translation-list.component';
import { TranslationUpsertComponent } from './translation/upsert/translation-upsert.component';
import { SettingDialogComponent } from './common/dialog/setting-dialog/setting-dialog.component';
import { FieldsComponent } from './category/create-fields/fields.component';
import { ConfigBlockComponent } from './category/config-block/config-block.component';
import { CreateDtimageComponent } from './category/create-dtimage/create-dtimage.component';
import { CreateOptionsComponent } from './category/create-options/create-options.component';
import { DataTransferService } from "./_services/data-transfer/data-transfer.service";
import { BlockFormService } from "./_services/block-form.service";
import { PostAdComponent } from './post-ad/post-ad/post-ad.component';
import { EditAdComponent } from './post-ad/edit-ad/edit-ad.component';
import { ListAdComponent } from './post-ad/list-ad/list-ad.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdBuilderComponent } from './shared/components/ad-builder/ad-builder.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TagInputModule } from 'ngx-chips';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
  };
import {TagListComponent} from './tag/tag-list.component';
import {TagUpsertComponent} from './tag/upsert/tag-upsert.component';
import { LinkAdComponent } from './post-ad/link-ad/link-ad.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		TopNavbarComponent,
		Error404Component,
		Error500Component,
		LoginComponent,
		UserACLComponent,
		AddUserAclComponent,
		UserTypeListComponent,
		AddUserTypeComponent,
		EditUserTypeComponent,
		LocationsListComponent,
		LocationsAddComponent,
		LocationsEditComponent,
		ConfirmdialogComponent,
		UpdateUserAclComponent,
		CategoryListComponent,
		AddCategoryComponent,
		EditCategoryComponent,
		UserListComponent,
		UserEditComponent,
		UserAddComponent,
		DragNDropComponent,
		BadgeconfigListComponent,
		BadgeconfigAddComponent,
		BadgeconfigEditComponent,
		PaginationComponent,
		BannerAddComponent,
		BannerEditComponent,
		BannerListComponent,
		ProfileComponent,
		MessagesComponent,
		SettingsComponent,
		LanguageListComponent,
		LanguageUpsertComponent,
		TranslationListComponent,
		TranslationUpsertComponent,
		SettingDialogComponent,
		FieldsComponent,
		ConfigBlockComponent,
		CreateDtimageComponent,
		CreateOptionsComponent,
		PostAdComponent,
		EditAdComponent,
		ListAdComponent,
		AdBuilderComponent,
		TagListComponent,
		TagUpsertComponent,
		LinkAdComponent
	],
	imports: [
		BrowserModule,
		NgxPaginationModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(), // ToastrModule added
		FormsModule,
		HttpClientModule,
		RouterModule.forRoot(routes),
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		AccordionModule.forRoot(),
		ButtonsModule.forRoot(),
		ModalModule.forRoot(),
		CarouselModule.forRoot(),
		PopoverModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		LaddaModule,
		CustomFormsModule,
		MaterialModule,
		ColorPickerModule,
		CKEditorModule,
		MatExpansionModule,
		NgbModule,
		PerfectScrollbarModule,
		AngularEditorModule,
		TagInputModule,
		DragDropModule
	],
	providers: [
		{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },

		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		  },
		SettingsService,
		xoomlaConfig,
		ApiDataServices,
		StorageService,
		ApiurlsService,
		ApirequestService,
		UtilityService,
		DataTransferService,
		BlockFormService,
		PusherService
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
