<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">BadgeConfig</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/badge-config/list'">BadgeConfig</a></li>
                <li class="active">{{actionType}}</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<!-- /.container-fluid -->
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>{{title}}</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <form *ngIf="!loading" [formGroup]="badgeForm" (ngSubmit)="onSubmit()">
                           

                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.title.errors }">
                                <label for="title" class="control-label">Title</label>
                                <div class="">
                                    <input type="text" formControlName="title" class="form-control" placeholder="Title"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                                    <span *ngIf="submitted && f.title.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>

                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.slug.errors }">
                                <label for="slug" class="control-label">Slug</label>
                                <div class="">
                                    <input type="text" formControlName="slug" class="form-control" placeholder="Slug"
                                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.slug.errors }" />
                                    <span *ngIf="submitted && f.slug.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.type.errors }">
                                <label for="type" class="control-label">Type</label>
                                <div class="">
                                    <select formControlName="type" class="form-control">
                                        <option>highlight</option>
                                        <option>feature</option>
                                        <option>badging</option>
                                        <option>promote</option>
                                    </select>

                                    <span *ngIf="submitted && f.type.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>



                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.description.errors }">
                                <label for="description" class="control-label">Description</label>
                                <div class="">
                                    <input type="description" formControlName="description" class="form-control"
                                        placeholder="Description" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }" />
                                    <span *ngIf="submitted && f.description.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.price.errors }">
                                <label for="price" class="control-label">Price</label>
                                <div class="">
                                    <input type="price" formControlName="price" class="form-control" placeholder="Price"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.price.errors }" />
                                    <span *ngIf="submitted && f.price.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>

                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.parents.errors }">
                                <label for="parents" class="control-label">Parents</label>
                                <select formControlName="parents" class="form-control" multiple>
                                    <option *ngFor="let badge of badgeList" value="{{badge.idBadgeConfig}}">
                                        {{badge.title}}</option>
                                </select>
                                <span id="helpBlock" class="help-block">Use ctrl / command key on keyboard to select multiple options.</span>
                                <span *ngIf="submitted && f.parents.errors" class="help-block">This field is
                                    required.</span>
                            </div>


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.image.errors }">
                                <label for="image" class="control-label">image</label>
                                <div class="image-upload">
                                    <img [src]="image" style="max-width:300px;max-height:300px"/>
                                    <input type="file"
                                        (change)="handleInputChange($event)"/>
                                        <input formControlName="image"  type="hidden"/>
                                </div>
                            </div>


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.type.errors }">
                                <label for="visibility" class="control-label">Visibility</label>
                                <div class="">
                                    <select formControlName="visibility" class="form-control">
                                        <option>home</option>
                                        <option>category</option>
                                        <option>list</option>
                                    </select>

                                    <span *ngIf="submitted && f.type.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>


                            <button type="submit"
                                class="btn btn-primary">{{actionType == 'Add'? (isSaving ? 'Creating...' : 'Create') : (isSaving ? 'Updating...' : 'Update') }}
                                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>