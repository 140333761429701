<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Category</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/category/list'">Category</a></li>
                <li class="active">{{actionType}}</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <form [formGroup]="categoryForm" (ngSubmit)="hit()">
            <div class="row">
                <div class="col-md-6">
                    <div class="panel">
                        <div class="panel-heading">
                            <div class="panel-title">
                                <h5>{{title}}</h5>
                            </div>
                        </div>
                        <div class="panel-body">
                            <div *ngIf="loading" class="loader-lay">
                                <div class="text-center">
                                    <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                                </div>
                            </div>

                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.categoryTitle.errors }">
                                <label for="categoryTitle" class="control-label">Category Title</label>
                                <div class="">
                                    <input type="text" formControlName="categoryTitle" required class="form-control"
                                           placeholder="categoryTitle" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.categoryTitle.errors }"/>
                                    <span *ngIf="submitted && f.categoryTitle.errors" class="help-block">This field is
                                            required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.parent.errors }">
                                <label for="parent" class="control-label">Parent</label>
                                <select formControlName="parent" class="form-control">
                                    <option *ngFor="let parent of parentList" value="{{parent.idAdCategory}}">
                                        {{parent.categoryTitle}}</option>
                                </select>
                                <span *ngIf="submitted && f.parent.errors" class="help-block">This field is
                                        required.</span>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.categoryIcon.errors }">
                                <label for="categoryIcon" class="control-label">Category Icon</label>
                                <div class="">
                                    <input type="text" formControlName="categoryIcon" class="form-control"
                                           placeholder="categoryIcon" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.categoryIcon.errors }"/>
                                    <span *ngIf="submitted && f.categoryIcon.errors" class="help-block">This field is
                                            required.</span>
                                </div>
                            </div>

                            <div class="form-group"
                                 [ngClass]="{ 'has-error': submitted && f.categoryIconMobile.errors }">
                                <label for="categoryIconMobile" class="control-label">Mobile Category Icon</label>
                                <div class="">
                                    <input type="text" formControlName="categoryIconMobile" class="form-control"
                                           placeholder="categoryIconMobile"
                                           [ngClass]="{ 'is-invalid': submitted && f.categoryIconMobile.errors }"/>
                                    <span *ngIf="submitted && f.categoryIconMobile.errors" class="help-block">This field
                                            is
                                            required.</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="categoryImage" required class="control-label">Category Image</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <label class="fileContainer">
                                            Upload <i class="fa fa-upload" aria-hidden="true"></i>
                                            <input type="file" (change)="onSelectFile($event)" id="cat_image" class="mb-20">
                                        </label>
                                    </div>
                                    <div class="col-md-4">
                                        <img *ngIf="uploadedimage !== ''" src="{{ uploadedimage }}" class="c-image" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.categoryColor.errors }">
                                <label for="categoryColor" class="control-label">Category Color</label>
                                <div class="color">
                                    <input type="color" value="#ff0000" formControlName="categoryColor"
                                           class="form-control" placeholder="categoryColor"
                                           [ngClass]="{ 'is-invalid': submitted && f.categoryColor.errors }"/>
                                    <span *ngIf="submitted && f.categoryColor.errors" class="help-block">This field is
                                            required.</span>
                                </div>
                            </div>


                            <div class="form-group"
                                 [ngClass]="{ 'has-error': submitted && f.categoryHoverColor.errors }">
                                <label for="categoryHoverColor" class="control-label">Category Hover Color</label>
                                <div class="color">
                                    <input type="color" value="#ff0000" formControlName="categoryHoverColor"
                                           class="form-control" placeholder="categoryHoverColor"
                                           [ngClass]="{ 'is-invalid': submitted && f.categoryHoverColor.errors }"/>
                                    <span *ngIf="submitted && f.categoryHoverColor.errors" class="help-block">This field
                                            is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <!-- start params -->
                    <div class="panel bg-light text-dark">
                        <div class="panel-heading bg-primary text-white">
                            <div class="panel-title">Add Params</div>
                        </div>
                        <div class="panel-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <select formControlName="blockName" class="form-control mb-20">
                                            <option *ngFor="let page of pageblocks" value="{{page}}">
                                                {{page}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <button
                                            *ngIf="hasAvailableBlock"
                                            (click)="addNewBlock()"
                                            class="btn btn-primary type-button"
                                            type="button">
                                            <i aria-hidden="true" class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <ng-container #configBlock></ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="panel bg-light text-dark">
                        <div class="panel-body">
                            <div class="text-right">
                                <button type="submit"
                                        [disabled]="isSaving"
                                        class="btn btn-primary text-right">{{actionType == 'Add' ? (isSaving ? 'Creating...' : 'Create') : (isSaving ? 'Updating...' : 'Update') }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
        </form>
    </div>
</section>
