export class CategoryList {
    idAdCategory: string;
    categoryCode: string;
    categoryTitle: string;
    parent: string;
    parentName: string;
    categoryIcon: string;
    categoryIconMobile: string;
    categoryColor: string;
    categoryHoverColor: string;
    params: any[];
}