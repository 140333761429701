import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrls } from 'src/app/api-urls';
import { ToastrService } from 'ngx-toastr';
import {Tag} from '../../models/tag';

@Component({
  selector: 'app-tag-upsert',
  templateUrl: './tag-upsert.component.html',
  styleUrls: ['./tag-upsert.component.css']
})
export class TagUpsertComponent implements OnInit {

  tagForm: FormGroup;
  submitted = false;
  isError = false;
  errorMessage = '';
  tag: Tag;
  loading = false;
  isSaving = false;
  loadingTags = false;
  tags: Tag[];
  title: string;
  id: string;

  constructor(private api: ApiDataServices,
    private formBuilder: FormBuilder,
    private route: Router,
    private _ar: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this._ar.data.subscribe(data => {
      this.title = data.type;
    });
    this.formGroupInit();

    this._ar.params.subscribe(
      params => {
        this.id = params.id;
        if (this.id) {
          this.getTag();
        }
      })


  }

  public formGroupInit() {
    this.tagForm = this.formBuilder.group({
      tagName: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.tagForm.controls; }



  public onSubmit() {
    this.submitted = true;
    if (this.tagForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    if (!this.id) {
      this.api.postApiData(apiUrls.tagCreate, this.tagForm.getRawValue()).pipe()
        .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Tag created successfully', 'Done');
            this.route.navigate(['/tag/list']);
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });
    } else {
      let data = {
          tagName: this.f.tagName.value
      }
      this.api.putApiData(apiUrls.tagUpdate(this.id), data).pipe()
        .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Tag Updated successfully', 'Done');
            this.route.navigate(['/tag/list']);
          },
          (error: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
            this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong.';
          });
    }
  }

  getTag() {
    this.loading = true;
    this.api.postApiData(apiUrls.tagGet(this.id)).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.tag = data.data;
          this.tagForm.patchValue({
            tagName: this.tag.tagName,
          });
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong';
        });
  }

}
