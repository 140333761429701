<!-- ========== TOP NAVBAR ========== -->
<nav class="navbar top-navbar bg-white box-shadow">
	<div class="container-fluid">
		<div class="row">
			<div class="navbar-header no-padding">
				<a class="navbar-brand" href="/dashboard">
					<img src="../../assets/images/xoomla.png" alt="Admin Template" class="logo">
				</a>
				<span class="small-nav-handle hidden-sm hidden-xs"><i class="fa fa-outdent"></i></span>
				<button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
					data-target="#navbar-collapse-1" aria-expanded="false">
					<span class="sr-only">Toggle navigation</span>
					<i class="fa fa-ellipsis-v"></i>
				</button>
				<button type="button" class="navbar-toggle mobile-nav-toggle">
					<i class="fa fa-bars"></i>
				</button>
			</div>
			<!-- /.navbar-header -->

			<div class="collapse navbar-collapse" id="navbar-collapse-1">
				<!-- /.nav navbar-nav -->

				<ul class="nav navbar-nav navbar-right" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">

                    <li class="dropdown" dropdown>
    					<a href="#" dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" (click)="stopPro($event)">{{username}} <span class="caret"></span></a>
    					<ul class="dropdown-menu profile-dropdown" *dropdownMenu role="menu">
    						<li class="profile-menu bg-gray">
    						    <div class="">
    						        <img src="../../assets/images/user.png" alt="John Doe" class="img-circle profile-img">
                                    <div class="profile-name">
                                        <h6>{{username}}</h6>
                                        <a [routerLink]="'/update/profile'">Update Profile</a>
                                    </div>
                                    <div class="clearfix"></div>
    						    </div>
    						</li>
    						<li role="separator" class="divider"></li>
    						<li><a href="javascript:;" class="color-danger text-center" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a></li>
    					</ul>
    				</li>
                    <!-- /.dropdown -->
    			</ul>
                <!-- /.nav navbar-nav navbar-right -->
    		</div>
    		<!-- /.navbar-collapse -->
        </div>
        <!-- /.row -->
	</div>
	<!-- /.container-fluid -->
</nav>