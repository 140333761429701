import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  constructor() { }
  @Input() totalItems = 0;
  @Input() paginationConfig: any = {};
  ngOnInit(): void {
  }

  pageChanged(event) {
    this.paginationConfig.currentPage = event;
  }

}
