import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiDataServices } from '../../_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { apiUrls } from '../../api-urls';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  constructor(
    private _ac: ActivatedRoute,
    private _fb: FormBuilder,
    private _api: ApiDataServices,
    private _router: Router,
    private toastr: ToastrService
  ) { }

  @Input() actionType: string = 'Add';
  @Input() title: string = 'Add New User';
  userForm: FormGroup;
  idUser: any = '';
  submitted = false;
  isError = false;
  isAdd = true;
  loading = false;
  errorMessage = "";
  isSaving = false;
  userData: any = '';
  usertypeList: any[];


  ngOnInit() {
    this.createUserFormInit();
    this.getusertypeList();
    this._ac.params.subscribe(
      params => {
        this.idUser = params.id;
        if (this.idUser) {
          this.isAdd = false;
          this.getUser();
        }
      }
    )
  }

  //Initializing Userform for validation
  createUserFormInit() {
    this.userForm = this._fb.group({
      'idUserType': ['', [Validators.required]],
      'userName': ['', [Validators.required]],
      'email': ['', this.actionType == 'Add' ? [Validators.required] : []],
      'password': ['', this.actionType == 'Add' ? [Validators.required] : []]

    });
  }

  get f() { return this.userForm.controls; }

  //Calling Submit function
  onSubmit() {
    this.submitted = true;

    //Checking form validaton
    if (this.userForm.valid) {
      this.isSaving = true;

      //Creating Users
      if (this.actionType == 'Add') {
        this.isError = false;
        let data: any = {
          idUserType: this.f.idUserType.value,
          userName: this.f.userName.value,
          email: this.f.email.value,
          password: this.f.password.value
        }
        this._api.postApiData(apiUrls.userCreate, data).subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('User created successfully', "Created");
            this._router.navigate(['user/list']);
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });
      }

      //Editing Users details
      if (this.actionType == 'Edit') {
        let data: any = {
          idUserType: this.f.idUserType.value,
          userName: this.f.userName.value
        }
        this._api.putApiData(apiUrls.userUpdate(this.idUser), data).subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('User Updated successfully', "Updated");
            this._router.navigate(['user/list'])
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });

      }
    } else {
      return;
    }

  }

  //Getting User data using (idUser)
  getUser() {
    this.loading = true;
    this._api.postApiData(apiUrls.userGet(this.idUser)).subscribe(
      (data: any) => {
        this.userData = data.data;
        if (this.userData) {
          this.loading = false;
          this.setuserData(this.userData);
        }
      }
    )
  }

  //Setting User data in edit form
  setuserData(data) {
    this.userForm.patchValue({
      'idUserType': data.idUserType,
      'userName': data.userName,
      'email': data.email
    });
  }

  //Getting Usertypelist
  getusertypeList() {
    this.loading = true;
    this._api.postApiData(apiUrls.userTypeList).subscribe(
      (data: any) => {
        this.loading = false;
        this.usertypeList = data.data;
      },
      (error:any) => {
        this.loading = false;
      }
    )
  }

  //Cancel User
  cancelUsercreate() {
    this._router.navigate(['user/list']);
  }



}
