import {AfterViewInit, Component, ComponentRef, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {UtilityService} from "../../utilities/utilities.services";
import {DataTransferService} from "../../_services/data-transfer/data-transfer.service";
import { BlockFormService } from "../../_services/block-form.service";
import {componentMap} from "../../constants";

type ValueOf<T> = T[keyof T];

@Component({
  selector: 'app-create-options',
  templateUrl: './create-options.component.html',
  styleUrls: ['./create-options.component.css']
})
export class CreateOptionsComponent implements OnInit, AfterViewInit {
    parentForm: FormGroup;
    blockName: string;
    blockIdx: number;
    formIndex: number;
    formType: string;
    parentCompList: any[];
    blockEditData: any[];
    public localFg: FormGroup;
    public flatList: any[];
    public optionValues: number[] = [0];
    public optionsValue = {
        name: '',
        isSelected: true
    };

    constructor(
        private _fb: FormBuilder,
        private _util: UtilityService,
        private _dt: DataTransferService,
        private blockForm: BlockFormService
    ) { }

    ngOnInit(): void {
        this.getCategoryForm();
        this.initForm();
        this.getNewFlatList();
    }

    ngAfterViewInit(): void {
        if (typeof this.blockEditData !== 'undefined') {
            for(let i in this.blockEditData['values']) {
                if (parseInt(i) !== 0) {
                    this.addNewOption();
                }

                this.localFg.patchValue({
                    filterable: this.blockEditData['filterable']
                });

                let options = this.localFg.controls.options as FormArray;
                let selected = (this.formType === 'options') ? this.blockEditData['values'][i]['selected'] : this.blockEditData['values'][i]['checked'];
                selected = (selected === null || typeof selected === 'undefined') ? false : selected;
                options.controls[i].patchValue({
                    name: this.blockEditData['title'],
                    description: this.blockEditData['values'][i]['lable'],
                    isRequired: this.blockEditData['isRequired'],
                    selected: selected,
                    idParamSettings: this.blockEditData['values'][i]['idParamSettings']
                });
            }
        }
    }

    private getNewFlatList() {
        this._dt.mappedFlatList.subscribe(res => {
            this.flatList = res;
        });
    }

    private getCategoryForm() {
        this._dt.categoryForm.subscribe(res => {
            this.parentForm = res;
        });
    }

    public initForm() {
        this.localFg = this.blockForm.getForm(true);
        this.localFg.patchValue({
            blockName: this.blockName,
            inputtype: this.formType
        });
        let options = this.localFg.controls.options as FormArray;
        options.push(this.blockForm.getForm())
        this.prepareDefault();
        let pFa = this.parentForm.controls.params as FormArray;
        pFa.push(this.localFg);
        this._dt.updateForm(this.parentForm);
    }

    public getOptionForms(): FormArray {
        return this.localFg.controls.options as FormArray;
    }

    private prepareDefault() {
        const optOptions = this.localFg.controls.options as FormArray;
        optOptions.controls[0].patchValue({
            blockName: this.blockName,
            inputtype: this.formType,
            name: this.optionsValue.name,
            selected: true
        });
    }

    public getFormArrayName() {
        return 'options';
    }

    public addNewOption() {
        let options = this.localFg.controls.options as FormArray;
        options.push(this.blockForm.getForm())

        if(this.formType === 'radio' || this.formType === 'options') {
            const optFG = options.controls[0] as FormGroup;
            this.optionsValue.name = optFG.value.name;
        }

        options.controls[options.controls.length - 1].patchValue({
            blockName: this.blockName,
            inputtype: this.formType,
            name: this.optionsValue.name,
            selected: false
        });
    }

    public deleteOption(value) {
        this.optionValues = this.optionValues.filter(function (val) {
            return val !== parseInt(value);
        });
        let options = this.localFg.controls.options as FormArray;
        options.removeAt(value);
    }

    private destroyCompFromList (comp: ComponentRef<ValueOf<typeof componentMap>>) {
        if (!!comp === false) {
            return;
        }

        comp.destroy();
    }

    deleteElementBlock(compObj) {
        this.destroyCompFromList(this.parentCompList[compObj.formIndex]);
        this.blockForm.deleteElementBlock(compObj, this.blockName);
    }

    public updateName(idx) {
        let options = this.localFg.controls.options as FormArray;
        const control = options.controls[idx] as FormGroup;
        const optName = control.controls.name.value;

        options.controls.forEach(opFG => {
            opFG.patchValue({
                name: optName
            });
        });
    }

    public updateRequired(idx) {
        let options = this.localFg.controls.options as FormArray;
        const control = options.controls[idx] as FormGroup;
        const optReq = control.controls.isRequired.value;

        options.controls.forEach(opFG => {
            opFG.patchValue({
                isRequired: optReq
            });
        });
    }

    public updateSelected(idx) {
        let options = this.localFg.controls.options as FormArray;
        const control = options.controls[idx] as FormGroup;
        const optSel = control.controls.selected.value;

        if (optSel) {
            options.controls.forEach((opFG, index) => {
                if (index !== idx) {
                    opFG.patchValue({
                        selected: false
                    });
                }
            });
        }
    }

    public changeFilterable() {
        const filterableValue = this.localFg.controls.filterable.value;
        const options = this.localFg.controls.options as FormArray;
        options.controls.forEach((opFG, index) => {
            opFG.patchValue({
                filterable: filterableValue
            });
        });
    }
}
