<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-sm-6">
            <h2 class="title">Dashboard</h2>
            <p class="sub-title">Under dev</p>
        </div>
        <!-- /.col-sm-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-sm-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li class="active">Dashboard</li>
            </ul>
        </div>
        <!-- /.col-sm-6 -->
    </div>
    <!-- /.row -->
</div>
<!-- /.container-fluid -->
<section class="section pt-10">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel border-primary no-border border-3-top" data-panel-control>
                    <div class="panel-heading">
                        <div class="panel-title text-center">
                            <h1 class="mt-20 mb-20">Under development</h1>
                        </div>
                    </div>
                </div>
                <!-- /.panel -->
            </div>
            <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.section -->