import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { CategoryList } from 'src/app/models/category';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/utilities/utilities.services';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { ToastrService } from 'ngx-toastr';
import { paginationConfig } from 'src/app/constants';
import { apiUrls } from 'src/app/api-urls';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  constructor(
    private auth : AuthenticationService,
    private toastr: ToastrService,
    private api: ApiDataServices,
    private dialog: MatDialog,
    private _utils: UtilityService
  ) { auth.checkAuth(); }
  panelOpenState = false;
  isError = false;
  categoryList: any = [];
  parentCategories: any = [];
  parentCategoryNames: any = [];
  selectedCategoryList: CategoryList = new CategoryList();
  loading = true;
  errorMessage = "Sorry, Something went wrong";
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize=20;


  ngOnInit(): void {
    this.getCategoryList();
    // console.log(this.paginationConfig);
  }

  pageChanged(event) {
    this.paginationConfig.currentPage = event;
    this.getCategoryList(event);
  }

  //Getting Category list
  getCategoryList(page=1) {
    this.loading = true;
    let data = {
      withChild : 1
    }
    this.api.postApiData(apiUrls.categoryList,data)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.parentCategories = data.data;
          this.categoryList = data.data;
          // console.log(data.data);
          // var allCategories = data.data;
          // var jj =0;
          // for (let i = 0; i < allCategories.length; i++) {
          //   if (allCategories[i].parentName) {
          //     if(!this.parentCategoryNames.includes(allCategories[i].parentName)){
          //       this.parentCategoryNames.push(allCategories[i].parentName);
          //       this.parentCategories[jj] = { };
          //       this.parentCategories[jj].id = allCategories[i].parent;
          //       this.parentCategories[jj].name = allCategories[i].parentName;
          //       jj+=1;
          //     }
          //   }
          // }
          // console.log(this.parentCategories);
          // console.log(this.parentCategories.length);

          if(data.meta.pageLength && data.meta.page) {
            this.paginationConfig.totalItems = data.meta.total;
            this.paginationConfig.currentPage = data.meta.page;
            this.paginationConfig.itemsPerPage = data.meta.pageLength;
            this.paginationConfig.maxSize = data.meta.pageLength;
            this.tableloading = false;
          } else {
            this.tableloading = true;
          }
        },
        error => {
          this.loading = false;
          this.tableloading = false;
        }

      );
  }

  //Deleting Category using (idAdCategory)
  confirmDelete(res) {
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) this.deleteCategory(res);
      }
    );
  }
  deleteCategory(res) {
    this.api.putApiData(apiUrls.categoryDelete(res.idadcategory)).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.toastr.success('Category  deleted successfully', "Deleted");
          this.deletecategoryFromList(res);
        },
        error => {

        },
        () => { }
      );
  }
  deletecategoryFromList(res) {
    this.categoryList = this._utils.filterArrayObj(this.categoryList, 'idAdCategory', res.idAdCategory);
  }

  change(e,idAdCategory) {
    let element = document.querySelector(".category_status_" + idAdCategory);
    // console.log(element);
    let status = element.getAttribute('data-status');
    if (status == '0') {
      var nature = false;
    } else {
      var nature = true;
    }
      const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
        width: '400px',
        height: '170px',
        data: {
          message: (nature == true) ? 'Are you sure, you want to activate this Category?' : 'Are you sure, you want to deactivate this Category?'
        }
      });
      deleteDialog.afterClosed().subscribe(
        data => {
          if (data) {
            this.activateCategory(idAdCategory, nature, e);
          } else {
            if (nature == true) {
              e.source.checked = false;
            } else {
              e.source.checked = true;
            }
          }
        }
      );
  }

  activateCategory(idAdCategory, nature, e) {
    document.getElementById('category_'+idAdCategory).classList.remove('hide');
    let data = {
      "isActive": nature
    }
    let element = document.querySelector(".category_status_"+idAdCategory);
    element.classList.add('mat-disabled');
    (element.children[0].children[0].children[0] as HTMLButtonElement).disabled = true;
    let status = element.getAttribute('data-status');
    this.api.putApiData(apiUrls.categoryActivate(idAdCategory), data).pipe()
      .subscribe(
        (data: any) => {
          let response = nature == true ? 'Category Activated successfully' : 'Category Deactivated successfully';
          this.toastr.success(response, 'Done');
          document.getElementById('category_'+idAdCategory).classList.add('hide');
          if (status == '0') {
            element.setAttribute('data-status',"1");
          } else {
            element.setAttribute('data-status',"0");
          }
          (element.children[0].children[0].children[0] as HTMLButtonElement).disabled = false;
          element.classList.remove('mat-disabled');
        },
        (error: any) => {
          (element.children[0].children[0].children[0] as HTMLButtonElement).disabled = false;
          element.classList.remove('mat-disabled');
          document.getElementById('category_'+idAdCategory).classList.add('hide');
          if (nature == true) {
            e.source.checked = false;
          } else {
            e.source.checked = true;
          }
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
          this.isError = true;
        });
  }

}
