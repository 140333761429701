<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Category</h2>
        </div>
        <!-- /.col-md-6 -->
        <div class="col-md-6 right-side">
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/category/add'">Add</a>
        </div>
        <!-- /.col-md-6 text-right -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li class="active">Category</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>


<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>CategoryList</h5>
                        </div>
                        <div *ngIf="!tableloading" class="text-right">
                            <!-- <pagination-controls
                                (pageChange)="pageChanged($event)"
                                (pageBoundsCorrection)="pageChanged($event)"
                                maxSize={{maxsize}}
                                previousLabel="Previous"
                                nextLabel="Next">
                            </pagination-controls> -->
                        </div>
                    </div>
                    <div class="panel-body p-20">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <div *ngIf="!loading">
                            <div *ngFor="let category of parentCategories; let i = index">
                                <mat-expansion-panel class="mb-20" (opened)="panelOpenState = true"
                                (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header class="main-title">
                                      <mat-panel-title>
                                        <b>{{category.categorytitle}}</b>
                                      </mat-panel-title>
                                      <mat-panel-description class="text-center">
                                        <span class="category-code">Category Code : {{category.categorycode}}</span>
                                        <a class="btn btn-default icon-only"
                                            [routerLink]="['/category/update',category.idadcategory]"><i
                                                class="fa fa-pencil"></i>
                                        </a>
                                        <button type="button" class="btn btn-danger icon-only ml-10 mr-10"
                                            (click)="confirmDelete(category)"><i class="fa fa-trash-o"></i>
                                        </button>
                                        <mat-slide-toggle *ngIf="category.isactive" class="category_status_{{category.idadcategory}} mt-5" data-status = "0" checked (change)="change($event,category.idadcategory)"></mat-slide-toggle>
                                        <mat-slide-toggle *ngIf="!category.isactive" class="category_status_{{category.idadcategory}} mt-5" data-status = "1" (change)="change($event,category.idadcategory)"></mat-slide-toggle>
                                        <span class="hide" id="category_{{category.idadcategory}}">
                                            <h6 class="c-spinner"><i class="fa fa-spinner fa-spin"></i></h6>
                                        </span>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div *ngIf="category.subCategories.length == 0">
                                        <span class="gray">No SubCategories Found!</span>
                                    </div>
                                    <div *ngFor="let subcategory1 of category.subCategories; let j = index">
                                        <mat-expansion-panel class="mb-20" (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false">
                                            <mat-expansion-panel-header>
                                              <mat-panel-title>
                                                <b>{{subcategory1.categorytitle}}</b>
                                              </mat-panel-title>
                                              <mat-panel-description class="text-center">
                                                <span class="category-code">Category Code : {{subcategory1.categorycode}}</span>
                                                <a class="btn btn-default icon-only"
                                                    [routerLink]="['/category/update',subcategory1.idadcategory]"><i
                                                        class="fa fa-pencil"></i>
                                                </a>
                                                <button type="button" class="btn btn-danger icon-only ml-10 mr-10"
                                                    (click)="confirmDelete(subcategory1)"><i class="fa fa-trash-o"></i>
                                                </button>
                                                <mat-slide-toggle *ngIf="subcategory1.isactive" class="category_status_{{subcategory1.idadcategory}} mt-5" data-status = "0" checked (change)="change($event,subcategory1.idadcategory)"></mat-slide-toggle>
                                                <mat-slide-toggle *ngIf="!subcategory1.isactive" class="category_status_{{subcategory1.idadcategory}} mt-5" data-status = "1" (change)="change($event,subcategory1.idadcategory)"></mat-slide-toggle>
                                                <span class="hide" id="category_{{subcategory1.idadcategory}}">
                                                    <h6 class="c-spinner"><i class="fa fa-spinner fa-spin ml-20"></i></h6>
                                                </span>
                                              </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div *ngIf="subcategory1.subCategories.length == 0">
                                                <span class="gray">No SubCategories Found!</span>
                                            </div>
                                            <div *ngFor="let subcategory2 of subcategory1.subCategories; let j = index">
                                                <mat-expansion-panel class="mb-20" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                                                    <mat-expansion-panel-header>
                                                      <mat-panel-title>
                                                        {{subcategory2.categorytitle}}<br>
                                                        Category Code : {{subcategory2.categorycode}}
                                                      </mat-panel-title>
                                                      <mat-panel-description class="text-center">
                                                        <a class="btn btn-default icon-only"
                                                            [routerLink]="['/category/update',subcategory2.idadcategory]"><i
                                                                class="fa fa-pencil"></i>
                                                        </a>
                                                        <button type="button" class="btn btn-danger icon-only ml-10 mr-10"
                                                            (click)="confirmDelete(subcategory2)"><i class="fa fa-trash-o"></i>
                                                        </button>
                                                        <mat-slide-toggle *ngIf="subcategory2.isactive" class="category_status_{{subcategory2.idadcategory}} mt-5" data-status = "0" checked (change)="change($event,subcategory2.idadcategory)"></mat-slide-toggle>
                                                        <mat-slide-toggle *ngIf="!subcategory2.isactive" class="category_status_{{subcategory2.idadcategory}} mt-5" data-status = "1" (change)="change($event,subcategory2.idadcategory)"></mat-slide-toggle>
                                                        <span class="hide" id="category_{{subcategory2.idadcategory}}">
                                                            <h6 class="c-spinner"><i class="fa fa-spinner fa-spin ml-20"></i></h6>
                                                        </span>
                                                      </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                    <div *ngIf="subcategory2.subCategories.length == 0">
                                                        <span class="gray">No SubCategories Found!</span>
                                                    </div>
                                                    
                                                </mat-expansion-panel>            
                                            </div>
                                            
                                        </mat-expansion-panel>            
                                    </div>
                                </mat-expansion-panel>            
                            </div>
                            
                        </div>
                        <div *ngIf="!loading && categoryList.length == 0" class="text-center">
                            <h4>No data found!</h4>
                        </div>
                        
                    </div>
                    <!-- /.panel -->
                </div>
            </div>
        </div>
    </div>
</section>