<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Create Advertisement</h2>
        </div>
    </div>
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/advertisements/list'">Advertisements</a></li>
                <li>Post</li>
            </ul>
        </div>
    </div>
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Create Advertisement</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="mainarea">
                            <div class="container">
                                <h4 *ngIf="loadingCategories || isLoading" class="text-center">Loading...<i
                                        class="fa fa-spinner fa-spin"></i></h4>
                                <div class="container-fluid" *ngIf="!loadingCategories && !isLoading">
                                    <div class="row cat-sel " *ngIf="catSel">
                                        <div class="col-md-4 pl-2 pr-2 ">
                                            <div class="cat-container box expand">
                                                <h4
                                                    [ngClass]="!isSelectedSubCat && !isSubCategories ? 'center selected' : 'unselected center'">
                                                    Select a Category</h4>
                                                <perfect-scrollbar style=" height: 60vh; padding: 0 20px;">
                                                    <div class="cat-div border-bottom center"
                                                        (click)="getSubCat(category)"
                                                        *ngFor="let category of categories"
                                                        [ngClass]="activatedParentCat == category?.categorycode ?'active':''"
                                                        id="{{'post' + category?.categorycode }}">
                                                        {{category.categorytitle}} <i
                                                            *ngIf="activatedParentCat == category?.categorycode && secondLevelCategories"
                                                            class="fa fa-chevron-right chevron"
                                                            aria-hidden="true"></i>
                                                    </div>
                                                </perfect-scrollbar>

                                            </div>
                                        </div>
                                        <div class="col-md-4 pl-2 pr-2">
                                            <div [ngClass]="isSubCategories ? 'cat-container box expand' : ''">
                                                <div *ngIf="isSubCategories">
                                                    <h4 [ngClass]="!isSelectedSubCat  ? 'selected' : 'unselected'">Sub
                                                        Category</h4>


                                                    <perfect-scrollbar style=" height: 60vh; padding: 0 20px;">
                                                        <div class="cat-div border-bottom center"
                                                            (click)="selectSubCat(subCategory)"
                                                            *ngFor="let subCategory of secondLevelCategories"
                                                            [ngClass]="activatedChildCat == subCategory?.categorycode ?'active':''"
                                                            id="{{'post' + subCategory?.categorycode }}">
                                                            {{subCategory.categorytitle}}<i
                                                                *ngIf="activatedChildCat == subCategory?.categorycode && subCategoriesLists"
                                                                class="fa fa-chevron-right chevron"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                    </perfect-scrollbar>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-4 pl-2 pr-2">
                                            <div [ngClass]="subCategoriesLists ? 'cat-container box expand' : ''">
                                                <div *ngIf="subCategoriesLists">
                                                    <h4 [ngClass]="!isSelectedSubCatList ? 'selected' : 'unselected'">
                                                        Sub Level</h4>
                                                    <perfect-scrollbar style=" height: 60vh; padding: 0 20px;">
                                                        <div class="cat-div border-bottom center"
                                                            (click)="selectSubCatList(subCategoryList)"
                                                            *ngFor="let subCategoryList of subCategoriesLists"
                                                            [ngClass]="activesubcatList == subCategoryList?'active':''"
                                                            id="{{'post' + subCategoryList?.categorycode }}">
                                                            {{subCategoryList.categorytitle}}
                                                        </div>
                                                    </perfect-scrollbar>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="Postd_dtls" *ngIf="xoAdDetails && !adSuccess">

                                    <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
                                        <div class="row">
                                            <div class="col-6">
                                                <h4>Post Your Ad</h4>
                                            </div>
                                            <!-- <div class="col-6">
                               
                              </div> -->
                                        </div>
                                        <div class="Postd_box" *ngIf="!isLoading">
                                            <div class="form-group">
                                                <label class="text">Ad Title</label><span class="req-span"><i
                                                        class="fa fa-asterisk" aria-hidden="true"></i></span>
                                                <textarea class="form-control" name="title" id="title" minlength="8"
                                                    required formControlName="adTitle"></textarea>
                                                <div *ngIf="form.controls['adTitle'].invalid && (form.controls['adTitle'].dirty || form.controls['adTitle'].touched)"
                                                    class="alert  mt-2" role="alert">
                                                    <div *ngIf="form.controls['adTitle'].errors.required" class="error">
                                                        Ad title is required
                                                    </div>
                                                    <div *ngIf="form.controls['adTitle'].invalid" class="error">
                                                        Minimum 8 characters required
                                                    </div>
                                                </div>
                                                <!-- <p class="mt-2 pl-1">
                                  <input type="checkbox" id="isUrgent" formControlName="isUrgent" value="isUrgent">
                                  <label class="pl-2" for="isUrgent">Immediate  within 7 days</label>
                                </p> -->

                                            </div>

                                            <div class="form-group">
                                                <label class="text breadform">Selected Category</label>
                                                <ol class="catbread">
                                                    <li *ngIf="isSubCategories">{{activecat?.categorytitle}} <i
                                                            class="fa fa-angle-double-right angle"
                                                            aria-hidden="true"></i> </li>
                                                    <li *ngIf="isSelectedSubCat">{{activesubcat?.categorytitle}} <i
                                                            class="fa fa-angle-double-right angle"
                                                            aria-hidden="true"></i> </li>
                                                    <li class="breadcrumb-item active" aria-current="page">
                                                        {{currentCateg}}</li>
                                                    <span class="back-link" (click)="postNewAd(true);scroll()"><i
                                                            class="fa fa-refresh" aria-hidden="true"></i>
                                                        Change Category</span>
                                                </ol>
                                            </div>

                                            <div class="form-group mt-3" *ngIf="this.activatedParentCat == 9967471">
                                                <label class="text">Job Offered By</label><span class="req-span"><i
                                                        class="fa fa-asterisk" aria-hidden="true"></i></span>
                                                <p>
                                                    <input type="radio" id="Individual" formControlName="offeredBy"
                                                        value="Individual" checked="">
                                                    <label for="Individual">Individual
                                                    </label>
                                                </p>
                                                <p>
                                                    <input type="radio" id="Business" formControlName="offeredBy"
                                                        value="Business">
                                                    <label for="Business">Professional employer
                                                    </label>
                                                </p>
                                                <p>
                                                    <input type="radio" id="External" formControlName="offeredBy"
                                                        value="External">
                                                    <label for="External">Third party employer
                                                    </label>
                                                </p>
                                                <div *ngIf="form.controls['offeredBy'].invalid && (form.controls['offeredBy'].dirty || form.controls['offeredBy'].touched)"
                                                    class="alert  mt-2" role="alert">
                                                    <div *ngIf="form.controls['offeredBy'].errors.required"
                                                        class="error">
                                                        Job Offered By Required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <label class="text">Ad Description</label> <span class="req-span"><i
                                                        class="fa fa-asterisk" aria-hidden="true"></i></span>
                                                <angular-editor formControlName="adDesc" [config]="editorConfig"
                                                    minlength="8" required>
                                                </angular-editor>
                                                <div *ngIf="form.controls['adDesc'].invalid && (form.controls['adDesc'].dirty || form.controls['adDesc'].touched)"
                                                    class="alert  mt-2" role="alert">
                                                    <div *ngIf="form.controls['adDesc'].errors.required" class="error">
                                                        Ad Description is required
                                                    </div>
                                                    <div *ngIf="form.controls['adDesc'].invalid" class="error">
                                                        Minimum 8 characters required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngFor="let data of dataForm;index as i">
                                            <app-ad-builder [formData]="data" [form]="form" [ind]="i"></app-ad-builder>
                                        </ng-container>

                                        <!-- <div class="btn-group mt-4 buttonpost">
                                            <input type="submit" value="Post your ad"
                                                class="btn btn-primary float-right">
                                        </div> -->
                                        <div class="text-right mt-50 mb-20">
                                            <a [routerLink]="'/advertisements/list'" class="btn btn-default mr-20">Cancel</a>
                                            <button type="submit" class="btn btn-primary mr-20">{{isSaving ? 'Creating...' : 'Create' }} 
                                                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>