import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Language } from 'src/app/models/language';
import { apiUrls } from 'src/app/api-urls';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-language-upsert',
  templateUrl: './language-upsert.component.html',
  styleUrls: ['./language-upsert.component.css']
})
export class LanguageUpsertComponent implements OnInit {

  languageForm: FormGroup;
  submitted = false;
  isError = false;
  errorMessage = "";
  language: Language;
  loading = false;
  isSaving = false;
  loadingLanguages = false;
  languages: Language[];
  title: string;
  id: string;
  languageFlag: string = '';

  constructor(private api: ApiDataServices,
    private formBuilder: FormBuilder,
    private route: Router,
    private _ar: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this._ar.data.subscribe(data => {
      this.title = data.type;
    });
    this.formGroupInit();

    this._ar.params.subscribe(
      params => {
        this.id = params.id;
        if (this.id) {
          this.getLanguage();
        }
      })


  }

  public formGroupInit() {
    this.languageForm = this.formBuilder.group({
      languageName: ['', Validators.required],
      languageCode: ['', Validators.required],
      languageFlag: ['', this.title == 'add' ? [Validators.required] : []]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.languageForm.controls; }



  public onSubmit() {
    this.submitted = true;
    if (this.languageForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    if (!this.id) {
      this.api.postApiData(apiUrls.languageCreate, this.languageForm.getRawValue()).pipe()
        .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Language created successfully', 'Done');
            this.route.navigate(['/language/list']);
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });
    } else {
      let data = {
        languageCode: this.f.languageCode.value,
        languageName: this.f.languageName.value
      }
      this.f.languageFlag.value ? (data['languageFlag'] = this.f.languageFlag.value) : '';
      this.api.putApiData(apiUrls.languageUpdate(this.id), data).pipe()
        .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Language Updated successfully', 'Done');
            this.route.navigate(['/language/list']);
          },
          (error: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
            this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong.';
          });
    }
  }

  getLanguage() {
    this.loading = true;
    this.api.postApiData(apiUrls.languageGet(this.id)).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.language = data.data
          
          this.languageForm.patchValue({
            languageName: this.language.languageName,
            languageCode: this.language.languageCode
          })
          this.languageFlag = this.language.languageFlag;
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong';
        });
  }

  //Function for uploading image
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.languageFlag = reader.result;
    this.languageForm.patchValue({ languageFlag: this.languageFlag })
  }

}
