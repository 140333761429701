<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Tags</h2>
        </div>
        <div class="col-md-6 right-side">
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/tag/list'">Back</a>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/tag/list'">Tags</a></li>
                <li class=" text-capitalize active">{{title}}</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5 class="text-capitalize">{{title}} Tag</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center"><h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4></div>
                        <form [formGroup]="tagForm" (ngSubmit)="onSubmit()" *ngIf="!loading">
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.tagName.errors }">
                                <label for="tagName" class="control-label">Tag Name</label>
                                <div class="">
                                    <input type="text" formControlName="tagName" class="form-control"
                                        placeholder="Tag Name" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.tagName.errors }" />
                                    <span *ngIf="submitted && f.tagName.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <div *ngIf="title ==='add'; else updateButton">

                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!tagForm.valid">{{isSaving ? 'Creating...' : 'Create' }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </div>
                            <ng-template #updateButton>
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!tagForm.valid">{{isSaving ? 'Updating...' : 'Update' }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </ng-template>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>
