import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { UtilityService } from 'src/app/utilities/utilities.services';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { paginationConfig } from 'src/app/constants';
import { apiUrls } from 'src/app/api-urls';
import { AuthenticationService } from 'src/app/authentication.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(
    private auth : AuthenticationService,
    private _api: ApiDataServices,
    private dialog: MatDialog,
    private _utils: UtilityService,
    private toastr: ToastrService
  ) { auth.checkAuth(); }

  isError = false;
  errorMessage = "Sorry, Something went wrong";
  userList: any = [];
  loading = true;
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize=20;

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  ngOnInit(): void {
    this.getuserList();
  }

  pageChange(page) {
    this.getuserList(page);
  }

  //Getting Userlist
  getuserList(page=1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this._api.postApiData(apiUrls.userList, data).pipe().subscribe(
      (data: any) => {
        this.loading = false;
        this.userList = data.data;
        if(data.meta.pageLength && data.meta.page) {
          this.collectionsize = data.meta.total;
          this.maxPages = data.meta.pages;
          this.endCount = (this.page == this.maxPages) ?
            (this.collectionsize < this.pageLength) ? this.collectionsize :
              ((this.collectionsize % this.pageLength == 0)) ?
                this.collectionsize :
                ((this.page == this.maxPages)) ? this.collectionsize :
                  this.endCount + (this.collectionsize % this.pageLength) :
            (this.page * this.pageLength);
        } else {
          this.tableloading = true;
        }
      },
      error => {
        this.loading = false;
        this.tableloading = false;
      }
    );
  }

  //Deleting User using (idUser)
  confirmDelete(res) {
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) this.deleteUser(res);
      }
    );
  }
  deleteUser(res) {
       this._api.putApiData(apiUrls.userDelete(res.idUser)).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.toastr.success('User deleted successfully', "Deleted");
          this.deleteuserFromList(res);
        },
        error => {

        },
        () => { }
      );
  }
  deleteuserFromList(res) {
    this.userList = this._utils.filterArrayObj(this.userList, 'idUser', res.idUser);
  }

  //Function for toggle button
  toggleActive(user, e) {
    let status = e.checked ? 'true' : 'false';
    let active = e.checked ? '0' : '1';
    const activeDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to ' + status + ' the ' + user.userName + '?'
      }
    });

    activeDialog.afterClosed().subscribe(
      data => {
        (data) ? 'this.toggleActiveUser(user.id, e)' : this.userList = this._utils.changeArrayObjProp(this.userList, 'id', user.id, 'false', !e.checked);
      }
    );
  }

  toggleActiveUser(id, e) {
    let data = {
      id: id,
      active: e.checked ? 1 : 0
    };
    this.userList = this._utils.changeArrayObjProp(this.userList, 'id', id, 'false', e.checked);
    this._api.putApiData('this._urls.userActiveUrl', data).subscribe(
      (data: any) => {
        let id = data.data.id;
        this.userList = this._utils.changeArrayObjProp(this.userList, 'id', id, 'false', e.checked);
      },
      error => {

      },
      () => {

      });
  }

  change(e,userId) {
    let element = document.querySelector(".user_status_"+userId);
    let status = element.getAttribute('data-status');
    if (status == '0') {
      var nature = false;
    } else {
      var nature = true;
    }
      const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
        width: '400px',
        height: '170px',
        data: {
          message: (nature == true) ? 'Are you sure, you want to activate this User?' : 'Are you sure, you want to deactivate this User?'
        }
      });
      deleteDialog.afterClosed().subscribe(
        data => {
          if (data) {
            this.activateUser(userId, nature, e);
          } else {
            if (nature == true) {
              e.source.checked = false;
            } else {
              e.source.checked = true;
            }
          }
        }
      );
  }

  activateUser(userId, nature, e) {
    document.getElementById('user_'+userId).classList.remove('hide');
    let data = {
      "isActive": nature
    }
    let element = document.querySelector(".user_status_"+userId);
    element.classList.add('mat-disabled');
    (element.children[0].children[0].children[0] as HTMLButtonElement).disabled = true;
    let status = element.getAttribute('data-status');
    this._api.putApiData(apiUrls.userActivate(userId), data).pipe()
      .subscribe(
        (data: any) => {
          let response = nature == true ? 'User Activated successfully' : 'User Deactivated successfully';
          this.toastr.success(response, 'Done');
          document.getElementById('user_'+userId).classList.add('hide');
          if (status == '0') {
            element.setAttribute('data-status',"1");
          } else {
            element.setAttribute('data-status',"0");
          }
          (element.children[0].children[0].children[0] as HTMLButtonElement).disabled = false;
          element.classList.remove('mat-disabled');
        },
        (error: any) => {
          (element.children[0].children[0].children[0] as HTMLButtonElement).disabled = false;
          element.classList.remove('mat-disabled');
          document.getElementById('user_'+userId).classList.add('hide');
          if (nature == true) {
            e.source.checked = false;
          } else {
            e.source.checked = true;
          }
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
          this.isError = true;
        });
  }

}
