<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Locations</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
				<li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/locations/list'">Locations</a></li>
				<li class="active">Add</li>
			</ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Add New Location</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <form [formGroup]="locationForm" (ngSubmit)="onSubmit()">
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.locationName.errors }">
                                <label for="locationName" class="control-label">Location Name</label>
                                <div class="">
                                    <input type="text" formControlName="locationName" class="form-control" placeholder="Location Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.locationName.errors }" />
                                    <span *ngIf="submitted && f.locationName.errors" class="help-block">This field is required.</span>
                                </div>
                            </div>
                            <h6 *ngIf="loadingLocations">Loading locations...<i class="fa fa-spinner fa-spin"></i></h6>
                            <div *ngIf="!loadingLocations" class="form-group" [ngClass]="{ 'has-error': submitted && f.parent.errors }">
                                <label for="parent" class="control-label">Parent</label>
                                <div class="">
                                    <select formControlName="parent" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.parent.errors }">
                                        <option value="">-- Select Location --</option>
                                        <option *ngFor="let location of locations" value="{{location.idLocation}}">{{location.locationName}}</option>
                                    </select>
                                    <span *ngIf="submitted && f.parent.errors" class="help-block">This field is required.</span>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">{{isSaving ? 'Creating...' : 'Create' }} 
                                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>