import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiDataServices } from '../../_services/apiservices/api.service';
import { apiUrls } from '../../api-urls';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-banner-add',
  templateUrl: './banner-add.component.html',
  styleUrls: ['./banner-add.component.css']
})
export class BannerAddComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '500px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter your ad description here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Heading',
        class: 'titleText',
        tag: 'h1',
      }, {
        name: 'Sub HEading',
        class: 'subtitleText',
        tag: 'h2',
      }
    ],
    // uploadUrl: "https://xoomcdn.business-software.in/api/v1/uploadImage",
    // uploadWithCredentials: true,
    // uploadUrl: "v1/uploadImage?path='/banner'&apiKey=5b9916097d01514fd63f322cead0ac53&slug=banner&fileType=image",
    sanitize: true,
    toolbarPosition: 'top'
  };
  public Editor = ClassicEditor;
  constructor(
    private toastr: ToastrService,
    private _fb: FormBuilder,
    private router: Router,
    private _api: ApiDataServices,
    private _ac: ActivatedRoute
  ) { }

  @Input() actionType: string = 'Add';
  @Input() title: string = 'Create New Banner';
  bannerForm: FormGroup;
  submitted = false;
  loading = false;
  errorMessage = "";
  isSaving = false;
  idBanner: any = '';
  bannerData: any = '';
  bannerList: any[];
  image: string = '';

  ngOnInit(): void {
    this.createBannerFormInit();
    this._ac.params.subscribe(
      params => {
        this.idBanner = params.id;
        if (this.idBanner) {
          this.getBanner();
        }
      }
    )
  }
  //Initializing Bannerform for validation
  createBannerFormInit() {
    this.bannerForm = this._fb.group({
      'bannerName': ['', [Validators.required]],
      'texteditor': ['', [Validators.required]],
      'location': ['', [Validators.required]],
      'pageCode': ['', [Validators.required]]
    });
  }
  onChange2(event) {
    console.log(this.bannerForm.value)
    console.warn(this.bannerForm.value);
  }

  get f() { return this.bannerForm.controls; }

  //Calling Submit function
  onSubmit() {
    this.submitted = true;

    //Checking form validaton
    if (this.bannerForm.valid) {
      this.isSaving = true;
      let data: any = {
        bannerName: this.f.bannerName.value,
        text: this.f.text.value,
        location: this.f.location.value,
        pageCode: this.f.pageCode.value
      }
      if (this.f.text.value.includes('base64')) {
        let image = this.f.text.value.replace('<img src="', '');
        image = image.replace('">', '');
        image = image.replace('alt=\""', '');
        image = image.trim();
        this.uploadImage(data, image);
      } else {
        this.actionType == 'Add' ? this.addBannerDetails(data) : this.updateBannerDetails(data);
      }

    } else {
      // console.log('error')
      return;
    }

  }

  uploadImage(formData, image) {
    let params: any = {
      apiKey: '5b9916097d01514fd63f322cead0ac53',
      image: image,
      slug: 'banner',
      path: '/banner',
      fileType: 'image'
    }
    this._api.postApiData(apiUrls.uploadImage, params).subscribe(
      (data: any) => {
        let url = data.data;
        url = apiUrls.storagePath + url;
        formData['text'] = "<img src=" + url + ">";
        this.actionType == 'Add' ? this.addBannerDetails(formData) : this.updateBannerDetails(formData);
      },
      (error: any) => {
        this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
        this.isSaving = false;
        this.submitted = false;
      });
  }
  // add banner details
  addBannerDetails(data) {
    this._api.postApiData(apiUrls.bannerCreate, data).subscribe(
      (data: any) => {
        this.isSaving = false;
        this.submitted = false;
        this.toastr.success('Banner created successfully');
        this.router.navigate(['banner/list']);
      },
      (error: any) => {
        this.errorMessage = error && error.message ? error.message : 'Sorry, Something went wrong';
        this.isSaving = false;
        this.submitted = false;
      }
    );
  }
  // update banner details
  updateBannerDetails(data) {
    this._api.putApiData(apiUrls.bannerUpdate(this.idBanner), data).subscribe(
      (data: any) => {
        this.isSaving = false;
        this.submitted = false;
        this.toastr.success('Banner  updated successfully');
        this.router.navigate(['banner/list'])
      },
      (error: any) => {
        this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
        this.isSaving = false;
        this.submitted = false;
      });
  }

  // Getting Banner data using (idBanner)
  getBanner() {
    this.loading = true;
    this._api.postApiData(apiUrls.bannerGet(this.idBanner)).subscribe(
      (data: any) => {
        this.bannerData = data.data;
        if (this.bannerData) {
          this.loading = false;
          this.setbannerData(this.bannerData);
        }
      }
    )
  }

  //Setting Banner data in edit form
  setbannerData(data) {
    this.bannerForm.patchValue({
      'bannerName': data.bannerName,
      'text': data.text,
      'location': data.location,
      'pageCode': data.pageCode
    });
  }


  //Cancel Banner create
  cancelbannercreate() {
    this.router.navigate(['banner/list']);
  }

}
