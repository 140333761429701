<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">User Type</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
				<li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
				<li><a [routerLink]="'/user-types/list'">User Types</a></li>
				<li class="active">Edit</li>
			</ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<!-- /.container-fluid -->
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Edit User Type</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center"><h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4></div>
                        <form *ngIf="!loading" [formGroup]="userTypeForm" (ngSubmit)="onSubmit()">
                            <div class="col-sm-6">
                                <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userTypeName.errors }">
                                    <label for="userTypeName" class="control-label">User Type Name</label>
                                    <div class="">
                                        <input type="text" formControlName="userTypeName" class="form-control" placeholder="User Type Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userTypeName.errors }" />
                                        <span *ngIf="submitted && f.userTypeName.errors" class="help-block">This field is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userTypeNum.errors }">
                                    <label for="userTypeNum" class="control-label">User Type Number</label>
                                    <div class="">
                                        <input type="text" formControlName="userTypeNum" class="form-control" placeholder="User Type Number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userTypeNum.errors }" />
                                        <span *ngIf="submitted && f.userTypeNum.errors" class="help-block">This field is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userTypeSlug.errors }">
                                    <label for="userTypeSlug" class="control-label">User Type Slug</label>
                                    <div class="">
                                        <input type="text" formControlName="userTypeSlug" class="form-control" placeholder="User Type Slug" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.usertypeslug.errors }" />
                                        <span *ngIf="submitted && f.userTypeSlug.errors" class="help-block">This field is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 text-right">
                                <button type="submit" class="btn btn-primary">{{isSaving ? 'Saving...' : 'Save' }} 
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>

<!-- UserAcls -->

<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Sync {{userType.userTypeName}} with User ACL</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loadingAcls" class="text-center"><h4>Loading ACLs...<i class="fa fa-spinner fa-spin"></i></h4></div>
                        <div *ngIf="!loadingAcls">
                            <div class="form-group">
                                <label for="userTypeName" class="control-label">User ACLs</label>
                                <div class="col-sm-12" *ngFor="let acl of userAcls">
                                    <input type="checkbox" [(ngModel)]="acl.isChecked" (change)="changeUserAclSelection()"> {{acl.path}}
                                </div>
                            </div>
                            <div class="col-sm-12 text-right">
                                <button type="button" class="btn btn-primary" (click)="saveAcls()">{{isSavingAcls ? 'Saving...' : 'Save' }} 
                                    <i *ngIf="isSavingAcls" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>