// import { AdParams, AdParamsDetails } from '../../../shared/models/postads/ad-params.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
// import { InitService } from '../init.service';
import { Injectable } from '@angular/core';
// import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PostAdsService {

    //   private adParamsListURL = environment.serviceUrls.adParamsList;
    //   private postAdParamsURL = environment.serviceUrls.postAdParams;

    //   categoriesData$ = this.initservice.categoriesData$;

    constructor(
        //   private initservice: InitService,
        private http: HttpClient
    ) { }

    getAdParams(id: string) {
        // return this.http.get<AdParams>(this.adParamsListURL + "/" + id);
    }

    toFormGroup(data: any, activatedParentCat) {

        let group: any = {};
        group["adTitle"] = new FormControl('', Validators.required);
        // group["isUrgent"] = new FormControl('', Validators.required);
        activatedParentCat == 9967471 ? group["offeredBy"] = new FormControl('', Validators.required) : null;
        group["adDesc"] = new FormControl('', Validators.required);
        data.forEach(data => {
            if (data.block != "postHint") {
                data.params.forEach((pdata) => {
                    switch (pdata.paramType) {
                        case 'text':
                            pdata.values.forEach((data) => {
                                if (pdata.inputName == "price") {
                                    group[pdata.inputName] = (data.regex != null) ?
                                        (pdata.isRequired) ? new FormControl(0.0, [Validators.required, Validators.pattern(data.regex)]) :
                                            new FormControl(0.0, Validators.pattern(data.regex))
                                        : (pdata.isRequired) ? new FormControl(0.0, [Validators.required]) :
                                            new FormControl(0.0);
                                } else if (pdata.inputName == "city") {
                                    //city removed from form
                                } else if (pdata.inputName == "salary") {
                                    group['minsalary'] = (data.regex != null) ?
                                        (pdata.isRequired) ? new FormControl('', [Validators.required, Validators.pattern(data.regex)]) :
                                            new FormControl('', Validators.pattern(data.regex))
                                        : (pdata.isRequired) ? new FormControl('', [Validators.required]) :
                                            new FormControl('');
                                    group['maxsalary'] = (data.regex != null) ?
                                        (pdata.isRequired) ? new FormControl('', [Validators.required, Validators.pattern(data.regex)]) :
                                            new FormControl('', Validators.pattern(data.regex))
                                        : (pdata.isRequired) ? new FormControl('', [Validators.required]) :
                                            new FormControl('');
                                } else {
                                    group[pdata.inputName] = (data.regex != null) ?
                                        (pdata.isRequired) ? new FormControl('', [Validators.required, Validators.pattern(data.regex)]) :
                                            new FormControl('', Validators.pattern(data.regex))
                                        : (pdata.isRequired) ? new FormControl('', [Validators.required]) :
                                            new FormControl('');
                                }
                            })
                            break;

                        case 'image':
                            pdata.values.forEach((data, i) => {
                                group[data.name] = pdata.isRequired ? new FormControl('', Validators.required)
                                    : new FormControl('');
                            })
                            break;

                        default:
                            pdata.values.forEach((data) => {
                                group[pdata.inputName] = pdata.isRequired ? new FormControl('', Validators.required)
                                    : new FormControl('');
                            })
                    }
                })
            }


        });
        return new FormGroup(group);
    }

    postAdParams(data) {
        // return this.http.post(this.postAdParamsURL, data);
    }
    adActivation(idAdvertisement) {
        let adActivationURL = '';//environment.serviceUrls.adActivation;
        return this.http.put(adActivationURL + '/' + idAdvertisement, { isActive: true }).pipe(
            catchError(errorRes => {
                // console.log(errorRes);
                return (errorRes.error.errors) ?
                    ((errorRes.error.errors.message) ? throwError(errorRes.error.errors.message) : throwError('Some unknown error occured'))
                    : throwError('Some unknown error occured');
            })
        )
    }
    searchKeyword(key) {
        let tagSuggestionsURL = '';//environment.serviceUrls.tagSuggestions;
        return this.http.get(tagSuggestionsURL + '?searchKeyword=' + key).pipe(
            catchError(errorRes => {
                // console.log(errorRes);
                return (errorRes.error.errors) ?
                    ((errorRes.error.errors.message) ? throwError(errorRes.error.errors.message) : throwError('Some unknown error occured'))
                    : throwError('Some unknown error occured');
            })
        )
    }
    // it will return list of ad posted based on offered by (business or individdual or external)
    postedAdCount(offeredBy) {
        let offeredByURL = ''; // environment.serviceUrls.offeredBy;
        return this.http.post(offeredByURL, { offeredBy: offeredBy }).pipe(
            catchError(errorRes => {
                // console.log(errorRes);
                return (errorRes.error.errors) ?
                    ((errorRes.error.errors.message) ? throwError(errorRes.error.errors.message) : throwError('Some unknown error occured'))
                    : throwError('Some unknown error occured');
            })
        )
    }
}
