import { Injectable } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {DataTransferService} from "./data-transfer/data-transfer.service";

@Injectable({
  providedIn: 'root'
})
export class BlockFormService {
    private flatList: any[];
    private parentForm: FormGroup;

    constructor(
        private _fb: FormBuilder,
        private _dt: DataTransferService)
    {
        this.getCategoryForm();
        this.getNewFlatList();
    }

    private getNewFlatList() {
        this._dt.mappedFlatList.subscribe(res => {
            this.flatList = res;
        });
    }
    private getCategoryForm() {
        this._dt.categoryForm.subscribe(res => {
            this.parentForm = res;
        });
    }

    public getForm(embedArray = false): FormGroup {
        if (embedArray) {
            return this._fb.group({
                blockName: [''],
                name: [''],
                inputtype: [''],
                isRequired: [false],
                filterable: [false],
                idParamSettings: [''],
                selected: [false],
                options: this._fb.array([])
            });
        } else {
            return this._fb.group({
                blockName: [''],
                name: [''],
                inputtype: [''],
                description: [''],
                isRequired: [false],
                placeholder: [''],
                regexType: [''],
                regexValue: [''],
                filterable: [false],
                idParamSettings: [''],
                selected: [false],
            });
        }
    }

    private deleteFormElement(idx) {
        let control = this.parentForm.controls.params as FormArray;
        control.removeAt(idx);
        this._dt.updateForm(this.parentForm);
    }

    deleteElementBlock(compObj, blockName) {
        this.deleteFormElement(this.flatList[compObj.formIndex]);
        this._dt.removeElementFromFormList(blockName, compObj.formIndex);
    }
}
