import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserType } from 'src/app/models/user-type';
import { UserAcl } from 'src/app/models/user-Acl';
import { apiUrls } from 'src/app/api-urls';
import { ToastrService } from 'ngx-toastr';
import { ApiurlsService } from 'src/app/_services/api-urls/apiUrls.service';
@Component({
  selector: 'app-edit-user-type',
  templateUrl: './edit-user-type.component.html',
  styleUrls: ['./edit-user-type.component.css']
})
export class EditUserTypeComponent implements OnInit {

  constructor(
    private api: ApiDataServices, 
    private url: ApiurlsService, 
    private formBuilder: FormBuilder,
    private route: Router, 
    private actRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  userTypeForm: FormGroup;
  submitted = false;
  isError = false;
  loading = true;
  isSaving = false;
  errorMessage = "";
  userType: UserType;
  ngOnInit(): void {
    this.loading = true;
    this.userType = new UserType();
    this.actRoute.paramMap.subscribe(params => {
      this.userType.idUserType = params.get('id');
      this.getUserType();
    });
  }

  getUserType(){
    this.loading = true;
    this.api.postApiData(this.url.userTypeGet(this.userType.idUserType)).pipe()
    .subscribe(
        (data: any) => {
          this.loading = false;
          this.userType = data.data;
          this.getUserAcls();
          this.userTypeForm = this.formBuilder.group({
            userTypeName: [this.userType.userTypeName, Validators.required],
            userTypeNum: [this.userType.userTypeNum, Validators.required],
            userTypeSlug: [this.userType.userTypeSlug, Validators.required]
          });
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong';
        });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.userTypeForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.userTypeForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    this.api.putApiData(this.url.userTypeUpdate(this.userType.idUserType), this.userTypeForm.getRawValue()).pipe()
    .subscribe(
        (data: any) => {
          this.isSaving = false;
          this.submitted = false;
          this.route.navigate(['/user-types/list']);
        },
        (error:any) => {
          this.isSaving = false;
          this.submitted = false;
          this.isError = true;
          this.errorMessage = 'Sorry, something went wrong.';
        });
  }

  //#region User ACL

  loadingAcls = true;
  isSavingAcls = false;
  userAcls: UserAcl[];
  selectedUserAcls: UserAcl[];
  getUserAcls(){
    this.userAcls = [];
    this.selectedUserAcls = [];
    this.loadingAcls = true;
    this.api.postApiData(apiUrls.userAclList).pipe()
    .subscribe(
        (data: any) => {
          this.userAcls = data.data
          let userTypeUserAcls = this.userType.userAcls;
          for(let acl of this.userAcls){
            acl.isChecked = userTypeUserAcls.find(a => a.idUserAcl == acl.idUserAcl) != null;
          }
          this.loadingAcls = false;
        },
        error => {
          this.loadingAcls = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong';
        });
  }

  changeUserAclSelection(){
    this.selectedUserAcls = this.userAcls.filter((value, index) => {
      return value.isChecked
    });
  }

  saveAcls(){
    let data = {
      acls: this.selectedUserAcls.filter(a => a.isChecked == true).map(m => m.idUserAcl)
    }
    this.isSavingAcls = true;
    this.api.putApiData(apiUrls.userTypeSyncAcl(this.userType.idUserType), data).pipe()
    .subscribe(
        (data: any) => {
          this.isSavingAcls = false;
          this.toastr.success(`${this.userType.userTypeName} synched successfully`, 'Done');
          this.route.navigate(['/user-types/list']);
        },
        (error:any) => {
          this.isSavingAcls = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong.';
        });
  }

  //#endregion
}
