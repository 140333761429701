import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { apiUrls } from 'src/app/api-urls';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { PostAdsService } from 'src/app/core/services/postads/post-ads.service';
import { ToastrService } from 'ngx-toastr';
import { AdminUser } from 'src/app/models/admin-user';

@Component({
  selector: 'app-post-ad',
  templateUrl: './post-ad.component.html',
  styleUrls: ['./post-ad.component.css']
})
export class PostAdComponent implements OnInit {

  constructor(
    private postadService: PostAdsService,
    private api: ApiDataServices,
    private toastr: ToastrService,
    private route: Router
  ) { }

  loadingCategories: boolean;
  categories: any;
  errorMessage: any;
  accordion: any;
  isLoading: boolean = false;
  isPaymentLoading: boolean = false;
  dataForm: any = [];
  activecat: any;
  activesubcat: any;
  activesubcatList: any;
  form: FormGroup;
  selectedTitle: string;
  adTitle: string;
  currentCateg: string;
  catSel: boolean = true;
  xoAdDetails: boolean = false;
  adSuccess: boolean = false;
  idAdCategory: string;
  userId: string;
  isSubCategories: boolean = false
  secondLevelCategories: any = null;
  subCategoriesLists: any = null;
  isSelectedSubCat: boolean = false;
  isSelectedSubCatList: boolean = false;
  selectedCategory: any = null;
  activatedParentCat: any;
  activatedChildCat: any;
  activatedGrandChildCat: any;
  allCategoriesList: any;
  dateRangeParam: any;
  dateParam: any;
  priceParam: boolean = false;
  isPreview: boolean = false;
  isSaving: boolean;

  adminUser = new AdminUser();


  isShowPhone: any;
  hint: any;
  hintData: any;
  tagParam: any;
  imgDataObj: any = {};
  videoInputName: any;
  pipe = new DatePipe('en-US');
  @ViewChild('post_ur_ad') postDiv: ElementRef;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '500px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter your ad description here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Heading',
        class: 'titleText',
        tag: 'h1',
      }, {
        name: 'Sub HEading',
        class: 'subtitleText',
        tag: 'h2',
      }
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage',
      'insertVideo']]
  };


  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.loadingCategories = true;
    let data = {
      withChild: 1
    }
    this.api.postApiData(apiUrls.categoryList, data).pipe()
      .subscribe(
        (data: any) => {
          this.loadingCategories = false;
          this.categories = data.data;
        },
        (error: any) => {
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
          this.loadingCategories = false;
        });
  }
  postNewAd(isChangeCat = false) {
    this.catSel = true;
    this.xoAdDetails = false;
    this.adSuccess = false;
    if (!isChangeCat) {
      this.activesubcatList = null;
      this.currentCateg = null;
      this.isSelectedSubCat = false;
      // this.subCategories = false;
      this.subCategoriesLists = false;
      this.activesubcat = false;
      this.activecat = false;
      this.isSubCategories = false;
      this.activatedParentCat = false;
      this.activatedChildCat = false;
    }
    this.selectedTitle = '';
    this.priceParam = false;
  }
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;

  @ViewChild('mydiv') divView: ElementRef;
  scroll() {
    setTimeout(() => {
      this.componentRef.directiveRef.scrollToElement('#post' + this.activatedParentCat)
      if (this.activatedChildCat) {
        this.componentRef.directiveRef.scrollToElement('#post' + this.activatedChildCat)
      }
      if (this.activesubcatList) {
        this.componentRef.directiveRef.scrollToElement('#post' + this.activesubcatList.categorycode)
      }
    }, 500)
  }

  getSubCat(categories) {
    this.activatedParentCat = categories.categorycode;
    this.subCategoriesLists = null;
    this.isSelectedSubCat = false;
    this.activatedChildCat = false;
    this.activesubcatList = null;
    this.activecat = categories;
    if (categories.subCategories.length > 0) {
      this.secondLevelCategories = categories.subCategories;
      this.isSubCategories = true;
    } else {
      this.secondLevelCategories = false;
      this.isSubCategories = false;
      this.selectedCategory = categories;
      this.getAdParams(this.selectedCategory, this.selectedCategory.categorytitle);
    }
  }
  selectSubCat(subCategoriesData) {
    this.activatedChildCat = subCategoriesData.categorycode;
    if (subCategoriesData.subCategories.length > 0) {
      this.activesubcat = subCategoriesData;
      this.subCategoriesLists = subCategoriesData.subCategories;
      this.isSelectedSubCat = true;
    } else {
      this.subCategoriesLists = false;
      this.selectedCategory = subCategoriesData;
      this.activesubcat = subCategoriesData;
      this.isSelectedSubCat = false;
      this.getAdParams(this.selectedCategory, this.selectedCategory.categorytitle);
    }

  }
  selectSubCatList(subCategoriesList) {
    this.selectedCategory = subCategoriesList;
    this.activesubcatList = subCategoriesList;
    this.isSelectedSubCat = true;
    this.getAdParams(this.selectedCategory, this.selectedCategory.categorytitle)
  }
  getAdParams(category, title) {
    this.isLoading = true;
    this.idAdCategory = category.idadcategory;
    this.api.postApiData(apiUrls.getCategoryParams(category.idadcategory), {}).pipe().subscribe(
      (data: any) => {
        //as default show phone is checked
        localStorage.setItem('showphone', '1');
        this.isLoading = false;
        this.catSel = false;
        this.xoAdDetails = true;
        this.buildForm(data.data);
        this.form = this.postadService.toFormGroup(data.data, this.activatedParentCat);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    )
    this.currentCateg = title;
  }


  buildForm(data) {
    this.dateParam = [];
    this.dateRangeParam = [];
    this.tagParam = [];
    data.forEach(blockElement => {
      if (blockElement['block'] == "Details") {
        blockElement.params.forEach(element => {
          if (element.paramType == 'date') {
            this.dateParam.push(element.inputName)
          } else if (element.paramType == 'daterange') {
            this.dateRangeParam.push(element.inputName)
          } else if (element.paramType == 'tag') {
            this.tagParam.push(element.inputName)
          }
        });
      } else if (blockElement['block'] == "Price") {
        this.activatedParentCat == 9967471 ? blockElement['block'] = 'Salary' : null;
        this.priceParam = true
      } else if (blockElement['block'] == "postHint") {
        blockElement.params.forEach(element => {
          this.hint = element.text;
          this.hintData = element.title;
        })
      } else if (blockElement['block'] == "Location") {
        this.activatedParentCat == 9967471 ? blockElement['block'] = 'Job Location' : null;
        blockElement['params'].forEach((data) => {
          data['inputName'] == 'city' ? blockElement['params'].splice(blockElement['params'].indexOf(data), 1) : null;
        })
      } else if (blockElement['block'] == "Media") {
        blockElement.params.forEach(element => {
          if (element.paramType == 'video') {
            this.videoInputName = element.inputName
          }
          // if (element.paramType == 'image') {
          //   element.values.forEach(val => {
          //     let tempObj = {};
          //     tempObj["image"] = val.value;
          //     tempObj["order"] = val.order;
          //     this.imgDataObj[val.name] = tempObj;
          //   })
          // }

        });
      }
      blockElement['block'] == "postHint" ? null : this.dataForm.push(blockElement);
    });
  }

  // checkDateanddateRange(data) {
  //   this.dateParam = [];
  //   this.dateRangeParam = [];
  //   data.forEach(blockElement => {
  //     if (blockElement['block'] == "Details") {
  //       blockElement.params.forEach(element => {
  //         if (element.paramType == 'date') {
  //           this.dateParam.push(element.inputName)
  //         } else if (element.paramType == 'daterange') {
  //           this.dateRangeParam.push(element.inputName)
  //         }
  //       });
  //     }
  //   });
  // }
  // checkPriceBlock(data) {
  //   data.forEach(blockElement => {
  //     blockElement['block'] == "Price" ? this.priceParam = true : null;
  //   });
  // }
  // checkpostHintBlock(data) {
  //   data.forEach(blockElement => {
  //     if (blockElement['block'] == "postHint"){
  //       data.blockElement.params.forEach(element => {
  //         this.hint = element.text;
  //         this.hintData = element.title;
  //       })
  //     }
  //   });
  // }
  imageOrder: any = [];
  getImageOrder(data) {
    // let i = 1
    // data.forEach(element => {
    //   this.imageOrder[element.name] = i++;
    // });
  }
  isvalidVideoLink: boolean = true;
  validateVideoLink() {
    if (this.videoInputName) {
      if (this.form.getRawValue()[this.videoInputName]) {
        (this.form.getRawValue()[this.videoInputName].includes('youtube.com') || this.form.getRawValue()[this.videoInputName].includes('vimeo.com')) ?
          this.isvalidVideoLink = true : this.isvalidVideoLink = false
      }
    }
  }

  onSubmit() {
    this.catSel = false;
    let userInfo = {};
    let additionalData = {};
    userInfo['idUser'] = this.userId;
    userInfo['idAdCategory'] = this.idAdCategory;
    this.validateVideoLink();
    if (this.form.valid && this.isvalidVideoLink) {
      if (this.form.getRawValue()) {
        let postForm = {};
        let min = this.form.getRawValue()['minsalary'];
        let max = this.form.getRawValue()['maxsalary'];
        if (min === 0 || max === 0 || min || max) {
          if (min === 0 || min) {
            if (max) {
              if (min <= max) {
                postForm['salary'] = min + ',' + max;
              } else {
                // 'Maximum value should be greater than or equal to minimum value';
                this.isPreview = false;
                return
              }
            } else if (max === 0) {
              // 'Maximum value should be greater than zero';
              this.isPreview = false;
              return
            } else {
              // 'Maximum value is required';
              this.isPreview = false;
              return
            }
          } else {
            // 'Minimum value is required'
            this.isPreview = false;
            return
          }
        }
        this.isSaving = true;
        this.dateRangeParam.forEach(element => {
          if (this.form.getRawValue()[element]) {
            let from = this.pipe.transform(this.form.getRawValue()[element][0], 'MM/dd/yyyy');
            let to = this.pipe.transform(this.form.getRawValue()[element][1], 'MM/dd/yyyy');
            postForm[element] = from + ',' + to;
          }
        });
        if (this.form.getRawValue()['tags']) {
          let tagArr = [];
          this.form.getRawValue()['tags'].forEach(keyword => {
            tagArr.push(keyword.value);
          })
          postForm['tags'] = tagArr.toString();

        }
        this.dateParam.forEach(element => {
          if (this.form.getRawValue()[element]) {
            postForm[element] = this.pipe.transform(this.form.getRawValue()[element], 'MM/dd/yyyy');
          }
        });
        this.priceParam ? (this.form.getRawValue().price ? null : postForm['price'] = 0.0) : null;


        let data: any = {
          ...userInfo, ...additionalData, ...this.form.getRawValue(), ...postForm
        }
        data['tempData'] = this.isPreview ? 1 : 0;
        //only for Job category type is job and offering for others
        data['adType'] = this.activatedParentCat == 9967471 ? 'Job' : 'Offering';
        data['adPlan'] = 'Free';
        data['isUrgent'] = 0;
        data['city'] = this.form.getRawValue()['address'];
        data['showPhone'] = localStorage.getItem('showphone') ? localStorage.getItem('showphone') : 0;
        data['idUser'] = this.adminUser.get().idUser;
        this.api.postApiData(apiUrls.advertisementCreate, data).pipe()
          .subscribe(
            (data: any) => {
              this.isSaving = false;
              this.xoAdDetails = false;
              this.adSuccess = true;
              this.scrollTop();
              this.toastr.success('Advertisement created successfully', 'Done');
              this.route.navigate(['/advertisements/list']);
              localStorage.removeItem('showphone')
            },
            (error: any) => {
              this.isSaving = false;
              let message = error.error?.errors ? error.error.errors.price ? error.error.errors.price[0] : error.error.errors.message ? error.error.errors.message : '' : '';
              message = message ? message : "Something Went Wrong";
              this.toastr.error(message, 'Error');
              // console.log(error);
            });
      }
    } else {
      this.xoAdDetails = true;
      this.form.markAllAsTouched();
    }
  }
  scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

}
