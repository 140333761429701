<div class="container-fluid">
    <div class="row page-title-div">
      <div class="col-md-6">
        <h2 class="title">User</h2>
      </div>
      <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
      <div class="col-md-6">
        <ul class="breadcrumb">
          <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
          <li><a [routerLink]="'/user/list'">User</a></li>
          <li class="active">{{actionType}}</li>
        </ul>
      </div>
      <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
<section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <div class="panel">
            <div class="panel-heading">
              <div class="panel-title">
                <h5>{{title}}</h5>
              </div>
            </div>
            <div class="panel-body">
                <div *ngIf="loading" class="text-center">
                  <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                </div>
              <form *ngIf="!loading" [formGroup]="userForm" (ngSubmit)="onSubmit()">


                <div class="form-group" [ngClass]="{ 'has-error': submitted && f.idUserType.errors }">
                    <label for="idUserType" class="control-label">User Type</label>
                    <select formControlName="idUserType" class="form-control">
                       <option *ngFor="let usertype of usertypeList" value="{{usertype.idUserType}}">
                            {{usertype.userTypeName}}</option>
                    </select>
                    <span *ngIf="submitted && f.idUserType.errors" class="help-block">This field is
                        required.</span>
                </div>


                <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userName.errors }">
                  <label for="userName" class="control-label">UserName</label>
                  <div class="">
                    <input type="text" formControlName="userName" class="form-control" placeholder="userName"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
                    <span *ngIf="submitted && f.userName.errors" class="help-block">This field is required.</span>
                  </div>
                </div>

                <div class="form-group" [ngClass]="{ 'has-error': submitted && f.email.errors }">
                  <label for="email" class="control-label">Email</label>
                  <div class="">
                    <input type="text"  formControlName="email" class="form-control" placeholder="email" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [readonly]="!isAdd"/>
                    <span *ngIf="submitted && f.email.errors" class="help-block">This field is required.</span>
                  </div>
                </div>

                <div class="form-group" *ngIf="isAdd"[ngClass]="{ 'has-error': submitted && f.password.errors }">
                  <label for="password" class="control-label">Password</label>
                  <div class="">
                    <input type="password" formControlName="password" class="form-control" placeholder="password" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <span *ngIf="submitted && f.password.errors" class="help-block">This field is required.</span>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary">{{actionType == 'Add'? (isSaving ? 'Creating...' : 'Create') : (isSaving ? 'Updating...' : 'Update') }}
                  <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        <!-- /.col-md-8 col-md-offset-2 -->
      </div>
    </div>
  </section>