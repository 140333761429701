import {AfterViewInit, Component, ComponentRef, Input, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { UtilityService } from "../../utilities/utilities.services";
import {DataTransferService} from "../../_services/data-transfer/data-transfer.service";
import { BlockFormService } from "../../_services/block-form.service";
import {componentMap} from "../../constants";

type ValueOf<T> = T[keyof T];

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css']
})
export class FieldsComponent implements OnInit, AfterViewInit {

    @Input() type:string;
    parentForm: FormGroup;
    blockName: string;
    blockIdx: number;
    formIndex: number;
    formType: string;
    parentCompList: any[];
    blockEditData: any[];
    regexTypes: any[] = ['Phone', 'Email', 'Url', 'Other'];
    public regexPattern: string[] = [
        '\\D*([2-9]\\d{2})(\\D*)([2-9]\\d{2})(\\D*)(\\d{4})\\D*',
        '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+).([a-zA-Z]{2,5})$',
        '(http(s)?:\\\\)?([\\w-]+\\.)+[\\w-]+[.com|.in|.org]+(\\[\\?%&=]*)?',
        ''
    ];
    public localFg: FormGroup;
    public flatList: any[];
    private componentMap: object;

    constructor(
        private _fb: FormBuilder,
        private _util: UtilityService,
        private _dt: DataTransferService,
        private blockForm: BlockFormService
    ) { }

    ngOnInit(): void {
        this.getCategoryForm();
        this.initForm();
        this.getNewFlatList();
        this.setSelf();
    }

    ngAfterViewInit(): void {
        if (typeof this.blockEditData !== 'undefined') {
            this.localFg.patchValue({
                name: this.blockEditData['title'],
                description: this.blockEditData['values'][0]['lable'],
                isRequired: this.blockEditData['isRequired'],
                placeholder: this.blockEditData['values'][0]['placeholder'],
                regexValue: this.blockEditData['values'][0]['regex'],
                filterable: this.blockEditData['filterable'],
                idParamSettings: this.blockEditData['values'][0]['idParamSettings']
            });
        }
    }

    public setSelf() {
        componentMap.textField = FieldsComponent;
    }

    private getNewFlatList() {
        this._dt.mappedFlatList.subscribe(res => {
            this.flatList = res;
        });
    }

    private getCategoryForm() {
        this._dt.categoryForm.subscribe(res => {
            this.parentForm = res;
        });
    }

    public initForm() {
        this.localFg = this.blockForm.getForm();
        this.localFg.patchValue({
            blockName: this.blockName,
            inputtype: this.formType
        });
        let pFa = this.parentForm.controls.params as FormArray;
        pFa.push(this.localFg);
        this._dt.updateForm(this.parentForm);
    }

    public setRegexValue(compObj) {
        const rgIdx = this._util.getIndexByValFromArray(this.regexTypes, compObj.localFg.controls.regexType.value);
        compObj.localFg.patchValue({regexValue: this.regexPattern[rgIdx]});
    }

    private destroyCompFromList (comp: ComponentRef<ValueOf<typeof componentMap>>) {
        if (!!comp === false) {
            return;
        }

        comp.destroy();
    }

    deleteElementBlock(compObj) {
        this.destroyCompFromList(this.parentCompList[compObj.formIndex]);
        this.blockForm.deleteElementBlock(compObj, this.blockName);
    }
}
