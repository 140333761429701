import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Translation } from 'src/app/models/translation';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrls } from 'src/app/api-urls';
import { ToastrService } from 'ngx-toastr';
import { Language } from 'src/app/models/language';

@Component({
  selector: 'app-translation-upsert',
  templateUrl: './translation-upsert.component.html',
  styleUrls: ['./translation-upsert.component.css']
})
export class TranslationUpsertComponent implements OnInit {

  translationForm: FormGroup;
  submitted = false;
  isError = false;
  errorMessage = "";
  translation: Translation;
  loading = false;
  isSaving = false;
  loadingTranslations = false;
  languages: Language[];
  title: string;
  id: string;

  constructor(private api: ApiDataServices,
    private formBuilder: FormBuilder,
    private route: Router,
    private _ar: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getLanguages();
    this._ar.data.subscribe(data => {
      this.title = data.type;
    });
    this.formGroupInit();

    this._ar.params.subscribe(
      params => {
        this.id = params.id;
        if (this.id) {
          this.getTranslation();
        }
      })

  }
  public formGroupInit() {
    this.translationForm = this.formBuilder.group({
      idLanguage: ['', Validators.required],
      slug: ['', Validators.required],
      text: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.translationForm.controls; }


  public getTranslation() {
    this.loading = true;
    this.api.postApiData(apiUrls.translationGet(this.id)).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.translation = data.data;
          this.translationForm = this.formBuilder.group({
            idLanguage: [this.translation.idLanguage, Validators.required],
            slug: [this.translation.slug, Validators.required],
            text: [this.translation.text, Validators.required]
          });
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong';
        });
  }

  getLanguages(){
    this.loading = true;
    this.api.postApiData(apiUrls.languageList, {}).pipe()
    .subscribe(
        (data: any) => {
          this.loading = false;
          this.languages = data.data;
        },
        error => {
          this.loading = false;
          this.isError = true;
        });
  }

  public onSubmit() {
    this.submitted = true;
    if (this.translationForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    if (!this.id) {
      this.api.postApiData(apiUrls.translationCreate, this.translationForm.getRawValue()).pipe()
        .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Translation created successfully', 'Done');
            this.route.navigate(['/translation/list']);
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });
    } else {
      let data = this.translationForm.getRawValue();
      data['idLanguage'] = this.translation.idLanguage;
      this.api.putApiData(apiUrls.translationUpdate(this.id), data).pipe()
        .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Translation updated successfully', 'Done');
            this.route.navigate(['/translation/list']);
          },
          (error: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
            this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong.';
          });
    }
  }

}
