<div class="panel bg-light text-dark">
    <div class="panel-heading bg-info text-white">
        <div class="panel-title">
            <div class="row">
                <div class="col-md-4">{{ blockName }}</div>
                <div class="col-md-2">
                    <button
                        (click)="deletePageBlock( blockIdx, blockName )"
                        type="button"
                        class="btn btn-danger">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="col-md-6"></div>
            </div>
        </div>
    </div>
    <div class="panel-heading bg-light-black text-white">
        <div class="form-group">
            <div class="row">
                <div class="col-md-2">Add Form Element</div>
                <div class="col-md-4">
                    <select [(ngModel)]="selectedElem" class="form-control mb-20">
                        <option *ngFor="let type of inputTypes" value="{{type}}">
                            {{type}}
                        </option>
                    </select>
                </div>
                <div class="col-md-2">
                    <button
                        (click)="addNewBlock()"
                        class="btn btn-primary type-button"
                        type="button">
                        <i aria-hidden="true" class="fa fa-plus"></i>
                    </button>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <ng-container #textBlock></ng-container>
        <ng-container #optionBlock></ng-container>
        <ng-container #dtImgBlock></ng-container>
    </div>
</div>
