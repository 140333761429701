<div [formGroup]="parentForm">
    <div [formGroup]="localFg">
        <input type="hidden" formControlName="blockName" value="{{localFg.value.blockName}}">
        <div class="row title-row">
            <div class="col-md-2">
                <div class="form-title">Form Element: {{formType}}</div>
            </div>
            <div class="col-md-2">
                <label style="display: inline-block;">Filterable</label>
                <span class="ml-5">
                    <select formControlName="filterable"
                            style="height: 32px;">
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                </span>
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-2">
                <button
                    (click)="deleteElementBlock(this)"
                    class="btn btn-danger ml-10">
                    <i aria-hidden="true" class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div *ngIf="formType==='image'" class="red-alert">Total ten (10) image elements will be automatically created.</div>
        <div class="row">
            <div class="col-md-3">
                <label>Name</label>
                <input formControlName="name" type="text">
            </div>
            <div class="col-md-3">
                <label>Description</label>
                <input formControlName="description" type="text">
            </div>
            <div class="col-md-3">
                <label>Required</label>
                <select class="form-control mb-20"
                        formControlName="isRequired">
                    <option value=true>Yes</option>
                    <option value=false>No</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Placeholder</label>
                <input formControlName="placeholder" type="text">
            </div>
        </div>
    </div>
</div>
