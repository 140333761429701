import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { apiUrls } from '../../api-urls';
import { ApiDataServices } from '../../_services/apiservices/api.service';


@Component({
  selector: 'app-add-user-acl',
  templateUrl: './add-user-acl.component.html',
  styleUrls: ['./add-user-acl.component.css'],
})
export class AddUserAclComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private _fb: FormBuilder,
    private _api: ApiDataServices,
    private router: Router,
    private _ac: ActivatedRoute
  ) { }
  @Input() actionType: string = 'Add';
  @Input() title: string = 'Add ACL';
  userAclForm: FormGroup;
  submitted = false;
  loading = false;
  errorMessage = "";
  isSaving = false;
  idUserAcl: any = '';
  UserAclData: any = '';
  UsertypeList: any[];
  usertype: string;



  ngOnInit() {
    this.createUserACLFormInit();
    this._ac.params.subscribe(
      params => {
        this.idUserAcl = params.id;
        if (this.idUserAcl) {
          this.getUserAcl();
        }
      }
    )
  }

  //Initializing Useracl form for validation
  createUserACLFormInit() {
    this.userAclForm = this._fb.group({
      'namespace': ['', [Validators.required]],
      'route': ['', [Validators.required]],
      'path': ['', [Validators.required]]
    });
  }

  get f() { return this.userAclForm.controls; }

  //Calling Submit function
  onSubmit() {
    this.submitted = true;

    //Checking form validaton
    if (this.userAclForm.valid) {
      this.isSaving = true;

      //Creating Useracl
      if (this.actionType == 'Add') {
        let data: any = {
          namespace: this.f.namespace.value,
          path: this.f.path.value,
          route: this.f.route.value
        }
        this._api.postApiData(apiUrls.userAclCreate, data).subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('UserACL created successfully', "Created");
            this.router.navigate(['user-acl/list']);
            },

            (error: any) => {
              this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
              this.isSaving = false;
              this.submitted = false;
          });
      }

      //Editing Useracl details
      if (this.actionType == 'Edit') {
        let data: any = {
          namespace: this.f.namespace.value,
          path: this.f.path.value,
          route: this.f.route.value
        }
        this._api.putApiData(apiUrls.userAclUpdate(this.idUserAcl), data).subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('UserACL updated successfully', "Updated");
            this.router.navigate(['user-acl/list'])
            },
            (error: any) => {
              this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
              this.isSaving = false;
              this.submitted = false;
          });
      }
    } else {
      return;
    }

  }

  //Getting Usertypelist
  getUsertypeList() {
    this._api.postApiData(apiUrls.userTypeList).subscribe(
      (data: any) => {
        let resSucc = data.data;
        if (resSucc != '') this.UsertypeList = resSucc;
      },
      error => {

      },
      () => {

      }

    )
  }

  // Getting Useracl data using (idUserAcl)
  getUserAcl() {
    this.loading = true;
    this._api.postApiData(apiUrls.userAclDetail(this.idUserAcl)).subscribe(
      (data: any) => {
        this.UserAclData = data.data;
        if (this.UserAclData) {
          this.loading = false;
          this.setUserAclData(this.UserAclData);
        }
      }
    )
  }

  //Setting Useracl data in edit form
  setUserAclData(data) {
    this.userAclForm.patchValue({
      'namespace': data.namespace,
      'route': data.route,
      'path': data.path,
      'rule': data.rule
    });
  }

  //Cancel UserAcl
  cancelUserAclcreate() {
    this.router.navigate(['user-acl/list']);
  }

}
