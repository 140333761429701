import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { apiUrls } from 'src/app/api-urls';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Component({
	selector: 'app-top-navbar',
	templateUrl: './top-navbar.component.html',
	styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
	username = localStorage.getItem('Username');
	constructor(
		private api: ApiDataServices, 
		private route: Router,
		private actRoute: ActivatedRoute,
		private toastr: ToastrService
	) { }

	ngOnInit() {
	}

    stopPro(event) {
        event.stopPropagation();
        return false;
	}
	
	logout() {
		var currentUrl = window.location.href;
		document.querySelector('.top-navbar-fixed').innerHTML = '<center>Logging Out ..</center>';
		this.api.putApiData(apiUrls.userLogout()).subscribe(
			(data: any) => {
				window.location.href = '/accounts/login';
				localStorage.clear();		
			},
			error => {
				setTimeout(function(){ window.location.href = currentUrl }, 2000);
			});
	}

}
