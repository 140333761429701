import { Routes } from '@angular/router';

// Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { Error404Component } from './error-404/error-404.component';
import { Error500Component } from './error-500/error-500.component';
import { LoginComponent } from './accounts/login/login.component';
import { ProfileComponent } from './accounts/profile/profile.component';
import { UserACLComponent } from './user-acl/user-acl.component';
import { UserTypeListComponent } from './user-type/user-type-list/user-type-list.component';
import { AddUserTypeComponent } from './user-type/add-user-type/add-user-type.component';
import { EditUserTypeComponent } from './user-type/edit-user-type/edit-user-type.component';
import { LocationsListComponent } from './locations/locations-list/locations-list.component';
import { LocationsAddComponent } from './locations/locations-add/locations-add.component';
import { LocationsEditComponent } from './locations/locations-edit/locations-edit.component';
import { UpdateUserAclComponent } from './user-acl/update-user-acl/update-user-acl.component';
import { AddUserAclComponent } from './user-acl/add-user-acl/add-user-acl.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { AddCategoryComponent } from './category/add-category/add-category.component';
import { EditCategoryComponent } from './category/edit-category/edit-category.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserAddComponent } from './user/user-add/user-add.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { BadgeconfigListComponent } from './badge-config/badgeconfig-list/badgeconfig-list.component';
import { BadgeconfigAddComponent } from './badge-config/badgeconfig-add/badgeconfig-add.component';
import { BadgeconfigEditComponent } from './badge-config/badgeconfig-edit/badgeconfig-edit.component';
import { BannerListComponent } from './banner/banner-list/banner-list.component';
import { BannerAddComponent } from './banner/banner-add/banner-add.component';
import { BannerEditComponent } from './banner/banner-edit/banner-edit.component';
import { MessagesComponent } from './messages/messages.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { LanguageListComponent } from './language/language-list.component';
import { LanguageUpsertComponent } from './language/upsert/language-upsert.component';
import { TranslationListComponent } from './translation/translation-list.component';
import { TranslationUpsertComponent } from './translation/upsert/translation-upsert.component';
import { PostAdComponent } from './post-ad/post-ad/post-ad.component';
import { EditAdComponent } from './post-ad/edit-ad/edit-ad.component';
import { ListAdComponent } from './post-ad/list-ad/list-ad.component';
import {TagListComponent} from './tag/tag-list.component';
import {TagUpsertComponent} from './tag/upsert/tag-upsert.component';
import { LinkAdComponent } from './post-ad/link-ad/link-ad.component';


// Routes
export const routes: Routes = [
    { path: 'accounts/login', component: LoginComponent },
    { path: 'update/profile', component: ProfileComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'chat', component: MessagesComponent },
    {
        path: 'user-types',
        children: [
            { path: 'list', component: UserTypeListComponent },
            { path: 'add', component: AddUserTypeComponent },
            { path: 'edit/:id', component: EditUserTypeComponent },
        ]
    },
    {
        path: 'user-acl',
        children: [
            { path: 'list', component: UserACLComponent },
            { path: 'add', component: AddUserAclComponent },
            { path: 'update/:id', component: UpdateUserAclComponent }
        ]
    },
    {
        path: 'category',
        children: [
            { path: 'list', component: CategoryListComponent },
            { path: 'add', component: AddCategoryComponent },
            { path: 'update/:id', component: EditCategoryComponent }
        ]
    },
    {
        path: 'locations',
        children: [
            { path: 'list', component: LocationsListComponent },
            { path: 'add', component: LocationsAddComponent },
            { path: 'edit/:id', component: LocationsEditComponent },
        ]
    },
    {
        path: 'user',
        children: [
            { path: 'list', component: UserListComponent },
            { path: 'add', component: UserAddComponent },
            { path: 'edit/:id', component: UserEditComponent },
        ]
    },
    {
        path: 'advertisements',
        children: [
            { path: 'list', component: ListAdComponent },
            { path: 'postAd', component: PostAdComponent },
            { path: 'edit/:id', component: EditAdComponent },
            { path: 'add-badge/:id', component: LinkAdComponent },
        ]
    },
    {
        path: 'badge-config',
        children: [
            { path: 'list', component: BadgeconfigListComponent },
            { path: 'add', component: BadgeconfigAddComponent },
            { path: 'edit/:id', component: BadgeconfigEditComponent },
        ]
    },
    {
        path: 'banner',
        children: [
            { path: 'list', component: BannerListComponent },
            { path: 'add', component: BannerAddComponent },
            { path: 'edit/:id', component: BannerEditComponent },
        ]
    },
    {
        path: 'language',
        children: [
            { path: 'list', component: LanguageListComponent },
            { path: 'add', component: LanguageUpsertComponent, data: { type: 'add' } },
            { path: 'edit/:id', component: LanguageUpsertComponent, data: { type: 'edit' } },
        ]
    },
    {
        path: 'translation',
        children: [
            { path: 'list', component: TranslationListComponent },
            { path: 'add', component: TranslationUpsertComponent, data: { type: 'add' } },
            { path: 'edit/:id', component: TranslationUpsertComponent, data: { type: 'edit' } },
        ]
    },
    { path: 'settings', component: SettingsComponent },
    {
        path: 'tag',
        children: [
            { path: 'list', component: TagListComponent },
            { path: 'add', component: TagUpsertComponent, data: { type: 'add' } },
            { path: 'edit/:id', component: TagUpsertComponent, data: { type: 'edit' } },
        ]
    },
    { path: 'error-404', component: Error404Component },
    { path: 'error-500', component: Error500Component },
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: '/dashboard' },

];
