<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">User Type</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
				<li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
				<li><a [routerLink]="'/user-types/list'">User Types</a></li>
				<li class="active">Add</li>
			</ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<!-- /.container-fluid -->
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Add New User Type</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <form [formGroup]="userTypeForm" (ngSubmit)="onSubmit()">
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userTypeName.errors }">
                                <label for="UserTypeName" class="control-label">User Type Name</label>
                                <div class="">
                                    <input type="text" formControlName="userTypeName" class="form-control" placeholder="User Type Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userTypeName.errors }" />
                                    <span *ngIf="submitted && f.userTypeName.errors" class="help-block">This field is required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userTypeNum.errors }">
                                <label for="usertypenum" class="control-label">User Type Number</label>
                                <div class="">
                                    <input type="text" formControlName="userTypeNum" class="form-control" placeholder="User Type Number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userTypeNum.errors }" />
                                    <span *ngIf="submitted && f.userTypeNum.errors" class="help-block">This field is required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.userTypeSlug.errors }">
                                <label for="usertypeslug" class="control-label">User Type Slug</label>
                                <div class="">
                                    <input type="text" formControlName="userTypeSlug" class="form-control" placeholder="User Type Slug" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userTypeSlug.errors }" />
                                    <span *ngIf="submitted && f.userTypeSlug.errors" class="help-block">This field is required.</span>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">{{isSaving ? 'Creating...' : 'Create' }} 
                                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>