import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrls } from 'src/app/api-urls';
import { ToastrService } from 'ngx-toastr';
import { AdminUser } from 'src/app/models/admin-user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private api: ApiDataServices,  
    private formBuilder: FormBuilder,
    private route: Router,
    private toastr: ToastrService) { }

    profileForm: FormGroup;
    submitted = false;
    isError = false;
    errorMessage = "";
    isSaving = false;
    loadingBadges = false;
    loading = true;
    userName = null;
    email = null;
    password = null;
    adminUser = new AdminUser();
    ngOnInit(): void {
      this.loading = false;
      this.setForm();
    }
   

    setForm(){
      this.userName = localStorage.getItem('Username');
      this.email = localStorage.getItem('Email');
      this.password = localStorage.getItem('Password');
      this.profileForm = this.formBuilder.group({
        userName: [this.userName, Validators.required],
        password: [this.password, [Validators.required, Validators.minLength(5)]]
      });

    }
    get f() { return this.profileForm.controls; }

    change(a,item) {
      if (item == 'username') {
        this.profileForm.patchValue({
          userName: a.srcElement.value
        });
      }
      if (item == 'password') {
        this.profileForm.patchValue({
          password: a.srcElement.value
        });
      }
    }
    
    onSubmit(){
      this.submitted = true;
      this.isError = false;
      this.isSaving = true;
      let data = this.profileForm.getRawValue();
      let newpassword = data.password;
      if (this.profileForm.invalid) {
        this.isSaving = false;
        this.toastr.warning('Please fill in all the fields to continue', 'Wait');
        return;
      }
      
      this.api.putApiData(apiUrls.profileUpdate, data).pipe()
      .subscribe(
          (data: any) => {
            this.isSaving = false;
            this.submitted = false;
            this.toastr.success('Profile updated successfully', 'Done');
            localStorage.setItem('Username', data.data['userName']);
            localStorage.setItem('Password', newpassword);
            this.route.navigate(['/dashboard']);
            setTimeout(function(){ window.location.reload(); }, 1000);
          },
          (error: any) => {
            this.errorMessage = 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;
            this.isError = true;
          });
    }
  

}
