import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormArray, FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

    /**
     * Global form index. It stores the last updated value
     */
    public formIndex = new BehaviorSubject<number>(null);
    /**
     * Global form structure. Stored as an Array with associative keys as block name and the associated form indexes
     */
    public blockFormList = new BehaviorSubject<any>([]);
    /**
     * Flat list of the all form indexes. It's key always stays the same to map the form index with updated position in form array
     */
    public mappedFlatList = new BehaviorSubject<any>([]);
    /**
     * Global object for Category form group
     */
    public categoryForm = new BehaviorSubject<FormGroup>(null);
    /**
     * Global array for page blocks
     */
    public pageBlocks = new BehaviorSubject<string[]>([]);
    /**
     * Page block names
     */
    private pageBlockNames = ['Details', 'Media', 'Price'];

    cast = this.formIndex.asObservable();
    cast2 = this.blockFormList.asObservable();
    cast3 = this.mappedFlatList.asObservable();
    cast4 = this.categoryForm.asObservable();
    cast5 = this.pageBlocks.asObservable();

    constructor() {   }

    /**
     * Update global form index value
     * @param data
     */
    public updateIndex(data) {
        this.formIndex.next(data);
    }

    /**
     * Initializes page block names
     */
    public initForm() {
        this.pageBlocks.next(this.pageBlockNames);
        this.formIndex.next(null);
        this.blockFormList.next([]);
        this.mappedFlatList.next([]);
        this.categoryForm.next(null);
    }

    /**
     * Update global form group
     * @param formObj FormGroup FormGroup object
     */
    public updateForm(formObj: FormGroup) {
        this.categoryForm.next(formObj);
    }

    /**
     * Add a new element in page block array
     * @param blockName string Page block name
     */
    public addPageBlock(blockName: string) {
        let pageBlocks = this.pageBlocks.getValue();
        pageBlocks.push(blockName);
        this.pageBlocks.next(pageBlocks);
    }

    /**
     * Update the page block array
     * @param pageBlocks Array Page block names array
     */
    public updatePageBlock(pageBlocks: string[]) {
        this.pageBlocks.next(pageBlocks);
    }

    /**
     * Add new value to the form list
     * @param block string Name of the block
     * @param index number Global index in the form array
     */
    public addToFormList(block, index) {
        let listData = this.blockFormList.getValue();
        // Update the reference to self
        listData = {...listData};
        let doesExists = false;

        for (let key in listData) {
            if (key === block) {
                listData[key].push(index);
                doesExists = true;
                break;
            }
        }

        if (!doesExists) {
            listData[block] = [index];
        }

        this.blockFormList.next(listData);
        this.updateMappedFlatList(index);
    }

    /**
     * Update mapped flat list array with new index
     * @param idx number form index
     */
    private updateMappedFlatList(idx) {
        let listData = this.mappedFlatList.getValue();
        // Update the reference to self
        listData = {...listData};
        if (!isNaN(idx)) {
            let hNum = this.getHighestValueofArray(listData);

            listData[idx] = ++hNum;
        }

        this.mappedFlatList.next(listData);
    }

    /**
     * Get the highest numeric value that is present in an array
     * @param arr Array Array to process
     * @return number
     */
    private getHighestValueofArray(arr) {
        let highestValue = -1;

        for (let i in arr) {
            if (highestValue < parseInt(arr[i])) {
                highestValue = parseInt(arr[i]);
            }
        }

        return highestValue;
    }

    /**
     * Removed the entire block from the form list
     * @param data string Block name
     */
    public removeBlockFromFormList(data) {
        let flatList = this.getFlattenArray(this.blockFormList.getValue());
        let listData = this.blockFormList.getValue();
        let newArr = [];
        let truncArr = [];

        for (let key in listData) {
            if (key !== data) {
                newArr[key] = listData[key];
            } else {
                truncArr = listData[key];
            }
        }

        this.blockFormList.next(this.rearrangeListArray(newArr, flatList, truncArr));
    }

    /**
     * Remove a particular form group member from a block
     * @param block string block name
     * @param index number form index
     */
    public removeElementFromFormList(block, index) {
        let flatList = this.getFlattenArray(this.blockFormList.getValue());
        let listData = this.blockFormList.getValue();
        let newArr = [];
        let truncArr = [];
        index = this.mappedFlatList.getValue()[parseInt(index)];

        for (let key in listData) {
            if (key !== block) {
                newArr[key] = listData[key];
            } else {
                newArr[block] = [];
                for (let tKey in listData[block]) {
                    if (parseInt(listData[block][tKey]) !== parseInt(index)) {
                        newArr[block].push(listData[block][tKey]);
                    } else {
                        truncArr.push(listData[block][tKey]);
                    }
                }
            }
        }

        this.blockFormList.next(this.rearrangeListArray(newArr, flatList, truncArr));
    }

    /**
     * Return a flattened array out of structured block form list
     * @param arr Array Block form list array
     * @return Array
     */
    private getFlattenArray(arr) {
        let flatArr = [];

        for (let i in arr) {
            if (Array.isArray(arr[i])) {
                for (let j in arr[i]) {
                    flatArr.push(arr[i][j]);
                }
            } else {
                flatArr.push(arr[i]);
            }
        }

        return flatArr.sort(function(a, b){return a-b;});
    }

    /**
     * Re-arrange the form list array to match with the current form array
     * @param newArr Array updated form list with new form array index
     * @param flatList Array Flat list of the current block form list array
     * @param truncArr Array The array that's being truncated from the block form list
     * @return Array
     */
    private rearrangeListArray(newArr, flatList, truncArr) {
        let newFlatList = [];
        let newMappedList = this.mappedFlatList.getValue();
        // update the reference with self. so we don't update the Subject on real-time
        newMappedList = {...newMappedList};
        let mapIndex = null;

        flatList.forEach((val, idx) => {
            if (!truncArr.includes(val)) {
                newFlatList.push(val);
            } else {
                mapIndex = this.getArrayKey(newMappedList, parseInt(val));
                newMappedList[mapIndex] = -1;
            }
        });

        for (let i in newMappedList) {
            if ( parseInt(i) > parseInt(truncArr[0])) {
                newMappedList[i] = parseInt(newMappedList[i]) - 1;
            }
        }

        for (let i in newArr) {
            if (Array.isArray(newArr[i])) {
                for (let j in newArr[i]) {
                    for (let idx in newFlatList) {
                        if (newFlatList[idx] === newArr[i][j]) {
                            newArr[i][j] = parseInt(idx);
                            break;
                        }
                    }
                }
            }
        }

        this.mappedFlatList.next(newMappedList);

        return newArr;
    }

    /**
     * Get the array index based on the value
     * @param arr Array The haystack
     * @param value any The needle
     * @return number
     */
    private getArrayKey(arr, value) {
        let res = -1;
        for (let i in arr) {
            if (parseInt(arr[i]) === value) {
                res = parseInt(i);
            }
        }

        return res;
    }
}
