import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/_services/accounts-service.service';
import { LocalStorageKeys } from 'src/app/enums';
import { StorageService } from 'src/app/_services/Localstorage/storage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  isError = false;
  userData: any = [];
  errorMessage : String = '';

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder, 
    private router: Router, private accountsService: AccountsService,
    public localStorage: StorageService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.errorMessage =  params['msg'];// Print the parameter to the console. 
    });
    if (this.errorMessage == 'token') {
      this.toastr.warning('Token Expired.', 'Login to continue!');
    }
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isError = false;
    this.accountsService.login({email: this.f.email.value, password: this.f.password.value}).pipe()
    .subscribe(
        (data: any) => {
            this.submitted = false;
            localStorage.setItem(LocalStorageKeys.AdminUser, JSON.stringify(data.data));
            localStorage.setItem('Username', data.data['userName']);
            localStorage.setItem('Email', this.f.email.value);
            localStorage.setItem('Password', this.f.password.value);
            window.location.href = '/dashboard';
            
            // should refresh the page while redirecting
        },
        error => {
          this.submitted = false;
          this.isError = true;
        });
  }

}
