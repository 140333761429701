<div [formGroup]="parentForm">
    <div [formGroup]="localFg">
        <input type="hidden" formControlName="blockName" value="{{localFg.value.blockName}}">
        <div class="row title-row">
            <div class="col-md-2">
                <div class="form-title">Form Element: {{formType}}</div>
            </div>
            <div class="col-md-2">
                <label style="display: inline-block;">Filterable</label>
                <span class="ml-5">
                    <select formControlName="filterable"
                            (change)="changeFilterable()"
                            style="height: 32px;">
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                </span>
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-2">
                <button
                    (click)="deleteElementBlock(this)"
                    class="btn btn-danger ml-10">
                    <i aria-hidden="true" class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <button
                    *ngIf="formType === 'radio' || formType === 'options'"
                    (click)="addNewOption()"
                    class="btn btn-primary type-button"
                    type="button">
                    <i aria-hidden="true" class="fa fa-plus"></i>
                    Add Option
                </button>
            </div>
        </div>
        <div [formArrayName]="getFormArrayName()">
            <div
                [formGroupName]="i"
                *ngFor="let optForm of getOptionForms().controls; let i = index;"
                class="row">
                <div class="col-md-2">
                    <label>Name</label>
                    <input formControlName="name" type="text" (change)="updateName(i)">
                </div>
                <div class="col-md-2">
                    <label>Description</label>
                    <input formControlName="description" type="text">
                </div>
                <div class="col-md-2">
                    <label>Required</label>
                    <select class="form-control mb-20"
                            formControlName="isRequired"
                            (change)="updateRequired(i)"
                    >
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label>Selected</label>
                    <select class="form-control mb-20"
                            formControlName="selected"
                            (change)="updateSelected(i)"
                    >
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                </div>
                <div class="col-md-2" *ngIf="formType === 'radio' || formType === 'options'">
                    <button
                        (click)="deleteOption(i)"
                        class="btn btn-danger ml-10">
                        <i aria-hidden="true" class="fa fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
