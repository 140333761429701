import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { AdParams } from '../../../shared/models/postads/ad-params.model';
// import { EditAdParams } from '../../../shared/models/editads/edit-ads.model';
import { HttpClient } from '@angular/common/http';
// import { InitService } from '../init.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditAdsService {
  //   private editAdParamsListURL = environment.serviceUrls.editAdParamsList;
  //   private updateAdParamsURL = environment.serviceUrls.updateAdParams;

  adId: string;
  //   categoriesData$ = this.initservice.categoriesData$;

  constructor(
    //   private initservice: InitService,
    private http: HttpClient) { }

  getAdParams(id: string) {
    this.adId = id;
    // return this.http.get<AdParams>(this.editAdParamsListURL + "/" + id);
  }

  toFormGroup(data: any) {
    let group: any = {};
    group["adTitle"] = new FormControl(data.adTitle, Validators.required);
    group["advertiserType"] = new FormControl(data.advertiserType, Validators.required);
    group["adDesc"] = new FormControl(data.adDesc, Validators.required);
    data.additionalParams.forEach(data => {
      if (data.block != "postHint") {
        data.params.forEach((pdata) => {
          switch (pdata.paramType) {
            case 'text':
              if (pdata.inputName == 'salary') {
                pdata.values.forEach((data) => {
                  let salaryRange = data.value ? data.value.split(",") : '';
                  let min = salaryRange ? salaryRange[0] : '';
                  let max = salaryRange ? salaryRange[1] : '';
                  group['minsalary'] = (data.regex != null) ?
                    (pdata.isRequired) ? new FormControl(min, [Validators.required, Validators.pattern(data.regex)]) :
                      new FormControl(min, Validators.pattern(data.regex))
                    : (pdata.isRequired) ? new FormControl(min, [Validators.required]) :
                      new FormControl(min);
                  group['maxsalary'] = (data.regex != null) ?
                    (pdata.isRequired) ? new FormControl(max, [Validators.required, Validators.pattern(data.regex)]) :
                      new FormControl(max, Validators.pattern(data.regex))
                    : (pdata.isRequired) ? new FormControl(max, [Validators.required]) :
                      new FormControl(max);
                })
              } else {
                if (pdata.inputName != 'city') {
                  pdata.values.forEach((data) => {
                    group[pdata.inputName] = (data.regex != null) ?
                      (pdata.isRequired) ? new FormControl(data.value, [Validators.required, Validators.pattern(data.regex)]) :
                        new FormControl(data.value, Validators.pattern(data.regex))
                      : new FormControl(data.value);
                    // pdata.inputName == "city" ? (data.value ? localStorage.setItem('postAdCity', data.value) : '') : '';
                  })
                }
              }
              break;

            case 'radio':
              pdata.values.forEach((data) => {
                group[pdata.inputName] = pdata.isRequired ? new FormControl(data.value, Validators.required)
                  : new FormControl(data.value);
              })
              break;

            case 'checkbox':
              pdata.values.forEach((data) => {
                group[pdata.inputName] = pdata.isRequired ? new FormControl(data.value, Validators.required)
                  : new FormControl(data.value);
              })
              break;

            case 'options':
              let selectedData;
              pdata.values.forEach((data) => {
                if (data.selected == 'true') {
                  selectedData = data.value;
                }
              })

              group[pdata.inputName] = pdata.isRequired ? new FormControl(selectedData, Validators.required)
                : new FormControl(selectedData);
              break;

            case 'image':
              pdata.values.forEach((data, i) => {
                (data.value) ?
                  group[data.name] = new FormControl('') :
                  group[data.name] = (pdata.isRequired) ? new FormControl('', Validators.required)
                    : new FormControl('');
              })
              break;
            case 'daterange':
              pdata.values.forEach((data) => {
                let dateRange = data.value ? data.value.replace(/,/g, ' - ') : '';
                group[pdata.inputName] = pdata.isRequired ? new FormControl(dateRange, Validators.required)
                  : new FormControl(dateRange);
              })
              break;
            case 'tag':
              pdata.values.forEach((data) => {
                let dateRange = data.value ? data.value.split(",") : ''; // console.log(dateRange)
                group[pdata.inputName] = pdata.isRequired ? new FormControl(dateRange, Validators.required)
                  : new FormControl(dateRange);
              })
              break;

            default:
              pdata.values.forEach((data) => {
                group[pdata.inputName] = pdata.isRequired ? new FormControl(data.value, Validators.required)
                  : new FormControl(data.value);
              })
          }
        })
      }


    });
    return new FormGroup(group);
  }

  postAdParams(data) {
    // return this.http.put(this.updateAdParamsURL + '/' + this.adId, data);
  }
}
