import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef, EventEmitter,
    Input,
    OnInit, Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { FieldsComponent } from '../create-fields/fields.component';
import { CreateDtimageComponent } from '../create-dtimage/create-dtimage.component';
import { CreateOptionsComponent } from '../create-options/create-options.component';
import {DataTransferService} from '../../_services/data-transfer/data-transfer.service';

const componentMap = {
    textField: FieldsComponent,
    dtImageField: CreateDtimageComponent,
    optionsField: CreateOptionsComponent
};

type ValueOf<T> = T[keyof T];

@Component({
  selector: 'app-config-block',
  templateUrl: './config-block.component.html',
  styleUrls: ['./config-block.component.css']
})
export class ConfigBlockComponent implements OnInit, AfterViewInit {
    @ViewChild('textBlock', { read: ViewContainerRef, static: true }) textBlock: ViewContainerRef;
    @ViewChild('dtImgBlock', { read: ViewContainerRef, static: true }) dtImgBlock: ViewContainerRef;
    @ViewChild('optionBlock', { read: ViewContainerRef, static: true }) optionBlock: ViewContainerRef;

    @Output() addElement = new EventEmitter();

    private _crtComp: keyof typeof componentMap = null;
    private componentList: any[] = [];
    private formList: number[] = [];
    parentForm: FormGroup;
    blockName: string;
    blockIdx: number;
    formIndex: number = 0;
    parentCompList: any[];
    editData: any[];
    public inputTypes: any[] = ['text', 'radio', 'options', 'checkbox', 'date', 'daterange', 'tag', 'image', 'video', 'file'];
    public selectedElem: string;
    private blockEditData: any[];

    constructor(
        private _fb: FormBuilder,
        private cfr: ComponentFactoryResolver,
        private _dt: DataTransferService
    ) { }

    ngOnInit(): void {
        this.getCategoryForm();
        this.getFormIndex();
        this.getFormList();
    }

    ngAfterViewInit(): void {
        if (typeof this.editData !== 'undefined' &&
            this.editData !== null) {
            this.generateFormForEdit();
        }
    }

    private generateFormForEdit() {
        for (let idx in this.editData) {
            this.selectedElem = this.editData[idx]['paramType'];
            this.blockEditData = this.editData[idx];
            this.addNewBlock(false);
        }
    }

    private getFormIndex() {
        this._dt.formIndex.subscribe( res => {
            this.formIndex = res;
        });
    }

    private getFormList() {
        this._dt.blockFormList.subscribe(res => {
            for (let key in res) {
                if (key === this.blockName) {
                    this.formList = res[key];
                }
            }
        });
    }

    private getCategoryForm() {
        this._dt.categoryForm.subscribe(res => {
            this.parentForm = res;
        });
    }

    private setFormIndex(val) {
        this._dt.updateIndex(val);
    }

    private createFormBlock(compClass, type) {
        let compObj;
        const compFactory = this.cfr.resolveComponentFactory(compClass);

        switch (type) {
            case 'date':
            case 'daterange':
            case 'image':
            case 'video':
            case 'file':
            case 'tag':
                compObj = this.dtImgBlock.createComponent(compFactory);
                break;
            case 'checkbox':
            case 'radio':
            case 'options':
                compObj = this.optionBlock.createComponent(compFactory);
                break;
            default:
                compObj = this.textBlock.createComponent(compFactory);
        }

        return compObj;
    }

    private loadNewComponentList(blockName, type, isNewBlock) {
        const comp = this.createFormBlock(componentMap[this._crtComp], type);

        (comp.instance as any).blockName = blockName;
        (comp.instance as any).blockIdx = this.blockIdx;
        (comp.instance as any).parentCompList = this.componentList;
        (comp.instance as any).formIndex = this.formIndex;
        (comp.instance as any).formType = type;
        if (!isNewBlock) {
            (comp.instance as any).blockEditData = this.blockEditData;
        }

        comp.changeDetectorRef.detectChanges();

        this.componentList[this.formIndex] = comp;
        this._dt.addToFormList(blockName, this.formIndex);
    }

    public addNewBlock(isNewBlock = true) {
        if (this.formIndex === null) {
            this.formIndex = 0;
        } else {
            this.formIndex++;
        }
        this.setFormIndex(this.formIndex);

        switch (this.selectedElem) {
            case 'checkbox':
            case 'radio':
            case 'options':
                this._crtComp = 'optionsField';
                break;
            case 'date':
            case 'daterange':
            case 'image':
            case 'video':
            case 'file':
            case 'tag':
                this._crtComp = 'dtImageField';
                break;
            default:
                this._crtComp = 'textField';
        }
        this.loadNewComponentList(this.blockName, this.selectedElem, isNewBlock);
    }

    private destroyCompFromList (comp: ComponentRef<ValueOf<typeof componentMap>>) {
        if (!!comp === false) {
            return;
        }

        comp.destroy();
    }

    private deleteFormElements() {
        let control = this.parentForm.controls.params as FormArray;
        this.formList.forEach(value => {
            control.removeAt(value);
        });
        this._dt.updateForm(this.parentForm);
    }

    deletePageBlock(index, blockName) {
        this.deleteFormElements();
        this.destroyCompFromList(this.parentCompList[index]);
        this._dt.removeBlockFromFormList(this.blockName);
        this._dt.addPageBlock(this.blockName);
    }

}
