<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Languages</h2>
        </div>
        <div class="col-md-6 right-side">
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/language/list'">Back</a>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/language/list'">Languages</a></li>
                <li class=" text-capitalize active">{{title}}</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5 class="text-capitalize">{{title}} Language</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center"><h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4></div>
                        <form [formGroup]="languageForm" (ngSubmit)="onSubmit()" *ngIf="!loading">
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.languageName.errors }">
                                <label for="languageName" class="control-label">Language Name</label>
                                <div class="">
                                    <input type="text" formControlName="languageName" class="form-control"
                                        placeholder="Language Name" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.languageName.errors }" />
                                    <span *ngIf="submitted && f.languageName.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.languageCode.errors }">
                                <label for="languageCode" class="control-label">Language Code</label>
                                <div class="">
                                    <input type="text" formControlName="languageCode" class="form-control"
                                        placeholder="Language Code" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.languageCode.errors }" />
                                    <span *ngIf="submitted && f.languageCode.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <!-- <div class="form-group" [ngClass]="{ 'has-error': submitted && f.languageFlag.errors }">
                                <label for="languageFlag" class="control-label">Language Flag</label>
                                <div class="">
                                    <input type="text" formControlName="languageFlag" class="form-control" placeholder="Language Flag" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.languageFlag.errors }" />
                                    <span *ngIf="submitted && f.languageFlag.errors" class="help-block">This field is required.</span>
                                </div>
                            </div> -->

                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.languageFlag.errors }">
                                <label for="image" class="control-label">Language Flag</label>
                                <div class="image-upload">
                                    <img [src]="languageFlag" style="max-width:200px;max-height:200px" />
                                    <input type="file" (change)="handleInputChange($event)" />
                                    <input formControlName="languageFlag" type="hidden" />
                                </div>
                            </div>
                            <!-- <div *ngIf="!loadingLocations" class="form-group" [ngClass]="{ 'has-error': submitted && f.parent.errors }">
                                <label for="parent" class="control-label">Parent</label>
                                <div class="">
                                    <select formControlName="parent" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.parent.errors }">
                                        <option value="">-- Select Location --</option>
                                        <option *ngFor="let location of locations" value="{{location.idLocation}}">{{location.locationName}}</option>
                                    </select>
                                    <span *ngIf="submitted && f.parent.errors" class="help-block">This field is required.</span>
                                </div>
                            </div> -->
                            <div *ngIf="title ==='add'; else updateButton">

                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!languageForm.valid">{{isSaving ? 'Creating...' : 'Create' }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </div>
                            <ng-template #updateButton>
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!languageForm.valid">{{isSaving ? 'Updating...' : 'Update' }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </ng-template>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>