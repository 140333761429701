import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrls } from 'src/app/api-urls';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    constructor(private auth : AuthenticationService,
        private api: ApiDataServices, ) {
        auth.checkAuth();
    }

	ngOnInit(): void {
        this.getDashboard();
      }

    ngOnDestroy() {}

    getDashboard() {//checking dummy url so that sessionkey will be checked automatically
        return this.api.postApiData(apiUrls.advertisementList, {}).pipe()
          .subscribe();
    }

}
