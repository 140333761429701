<section>
  <div class="container-fluid">
    <div class="row">
      <div class="">
        <div class="panel">
          <div class="panel-heading">
            <div class="panel-title">
              <h5>Edit Setting </h5>
            </div>
          </div>
          <div class="panel-body">

            <form [formGroup]="settingsForm">
              <div class="form-group" [ngClass]="{ 'has-error': submitted && f.key.errors }">
                <label for="key" class="control-label">Key</label>
                <div class="">
                  <input type="text" formControlName="key" class="form-control" placeholder="Key" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.key.errors }" />
                </div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': submitted && f.value.errors }">
                <label for="value" class="control-label">Value</label>
                <div class="">
                  <input type="text" formControlName="value" class="form-control" placeholder="Value"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.value.errors }" />
                </div>
              </div>
              <div>
                <button type="submit" class="btn btn-primary" style="margin-right: 5px;" (click)="onSubmit()"
                  [disabled]="!settingsForm.valid" [mat-dialog-close]="true"> Update
                </button>
                <button type="submit" class="btn btn-warning" [mat-dialog-close]="false">Cancel

                </button>
              </div>




              <!-- <button type="submit" class="btn btn-primary">{{(isSaving ? 'Updating...' : 'Update') }}
                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                  </button> -->


            </form>
          </div>
        </div>
      </div>
      <!-- /.col-md-8 col-md-offset-2 -->
    </div>
  </div>
</section>