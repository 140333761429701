<div class="container-fluid">
  <div class="row page-title-div">
    <div class="col-md-6">
      <h2 class="title">Settings</h2>
    </div>
    <!-- /.col-md-6 -->
  </div>
  <!-- /.row -->
  <div class="row breadcrumb-div">
    <div class="col-md-6">
      <ul class="breadcrumb">
        <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
        <li><a [routerLink]="'/settings'">Settings</a></li>

      </ul>
    </div>
    <!-- /.col-md-6 -->
  </div>
  <!-- /.row -->
</div>
<!-- /.container-fluid -->
<section class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="panel">
          <div class="panel-heading">
            <div class="panel-title">
              <h5>Add Settings </h5>
            </div>
          </div>
          <div class="panel-body">

            <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-5">

                  <div class="form-group" [ngClass]="{ 'has-error': submitted && f.key.errors }">
                    <label for="key" class="control-label">Key</label>
                    <div class="">
                      <input type="text" formControlName="key" class="form-control" placeholder="Key"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.key.errors }" />
                    </div>
                  </div>
                </div>
                <div class="col-md-5">

                  <div class="form-group" [ngClass]="{ 'has-error': submitted && f.value.errors }">
                    <label for="value" class="control-label">Value</label>
                    <div class="">
                      <input type="text" formControlName="value" class="form-control" placeholder="Value"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.value.errors }" />
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div style="margin-top: 24px;">
                    <button type="submit" class="btn btn-primary"
                      [disabled]="!settingsForm.valid">{{isSaving ? 'Creating...' : 'Create' }}
                      <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                    </button>
                  </div>

                </div>
              </div>


              <!-- <button type="submit" class="btn btn-primary">{{(isSaving ? 'Updating...' : 'Update') }}
                  <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                </button> -->


            </form>
          </div>
        </div>
      </div>
      <!-- /.col-md-8 col-md-offset-2 -->
    </div>
  </div>
</section>


<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="panel">
          <div class="panel-heading">
            <div class="panel-title">
              <h5>Settings</h5>
            </div>
          </div>
          <div class="panel-body p-20">
            <div *ngIf="loading" class="text-center">
              <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
            </div>
            <div class="table-responsive">
              <table *ngIf="!loading" class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Sr. #</th>
                    <th>Key</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let setting of settings let i = index">
                    <td>{{(((page - 1 )*(pageLength))) + (i + 1)}}</td>
                    <td scope="row">{{setting.key}}</td>
                    <td>{{setting.value}}</td>
                    <td>
                      <a href="javascript:void(0);" class="btn btn-default icon-only mr-10"
                        (click)="openEdit(setting)"><i class="fa fa-pencil"></i></a>
                      <button type="button" class="btn btn-danger icon-only" (click)="showDeleteModal(setting)"><i
                          class="fa fa-trash-o"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="!loading && settings.length == 0" class="text-center">
              <h4>No data found!</h4>
            </div>
          </div>
          <div class="col-12" *ngIf="collectionsize > pageLength && !loading">
            <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" [maxSize]="3" [rotate]="true"
              [pageSize]="pageLength" [boundaryLinks]="true" (pageChange)="pageChange($event)" class="float-right">
            </ngb-pagination>
          </div>
        </div>
        <!-- /.panel -->
      </div>
    </div>
  </div>
</section>

<!-- Delete Modal tigger button -->
<input type="button" style="display:none;" #deleteModalButton id="deleteModalButton" (click)="defaultModal.show()" />
<!-- Delete Modal -->

<div class="modal fade" bsModal id="myModal" tabindex="-1" role="dialog" #defaultModal="bs-modal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Delete</h4>
      </div>
      <div class="modal-body">
        <div *ngIf="isError" class="text-center">
          <div class="alert alert-danger">{{errorMessage}}</div>
        </div>
        <h4 class="text-center">Are you sure you want to delete {{selectedSetting.key}} ? </h4>
      </div>
      <div class="modal-footer">
        <div class="btn-group" role="group">
          <button type="button" #cancelModalButton class="btn btn-gray mr-10"
            (click)="defaultModal.hide()">Cancel</button>
          <button type="button" class="btn bg-danger" (click)="delete()">{{isDeleting ? 'Deleting...' : 'Delete'}}
            <i *ngIf="isDeleting" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
        <!-- /.btn-group -->
      </div>
    </div>
  </div>
</div>