<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Advertisments</h2>
        </div>
        <!-- /.col-md-6 -->
        <div class="col-md-6 right-side">
            <!-- <a class="btn bg-black toggle-code-handle" [routerLink]="'/advertisements/create'">Create</a> -->
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/advertisements/postAd'">Create</a>
        </div>
        <!-- /.col-md-6 text-right -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li class="active">Advertisments</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Advertisments</h5>
                        </div>
                    </div>
                    <div class="panel-body p-20">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <div class="table-responsive">
                            <table *ngIf="!loading" class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>Image</th>
                                        <th style="width: 300px;">Title</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                        <th>Status</th>
                                        <th>Add Badge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let advertisement of advertisements let i = index">
                                        <td>{{(((page - 1 )*(pageLength))) + (i + 1)}}
                                        </td>
                                        <td scope="row"><img src="{{advertisement.imageLink}}" width="70" height="70">
                                        </td>
                                        <td>{{advertisement.adTitle}}</td>
                                        <td>${{advertisement.price}}</td>
                                        <td>
                                            <a class="btn btn-default icon-only mr-10"
                                                [routerLink]="['/advertisements/edit',advertisement.idAdvertisement]"><i
                                                    class="fa fa-pencil"></i></a>
                                            <button type="button" class="btn btn-danger icon-only mr-10"
                                                (click)="showDeleteModal(advertisement.idAdvertisement)"><i
                                                    class="fa fa-trash-o"></i></button>
                                            <button type="button" class="btn btn-primary icon-only"
                                                (click)="bumpAd(advertisement.idAdvertisement)"><i
                                                    class="fa fa-arrow-circle-up"></i></button>
                                        </td>
                                        <td>
                                            <mat-slide-toggle [(ngModel)]="advertisement.isActive"
                                                (change)="activateAd(i,advertisement)">
                                            </mat-slide-toggle>
                                            <span *ngIf="isLoading && advertisement.idAdvertisement == activatingAdId">
                                                <h4><i class="fa fa-spinner fa-spin ml-20"></i></h4>
                                            </span>
                                        </td>
                                        <td>
                                            <a class="btn btn-primary" (click)=promoteAd(advertisement)><b>+</b>
                                                Badge</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="!loading && advertisements.length == 0" class="text-center">
                            <h4>No data found!</h4>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="collectionsize > pageLength && !loading">
                        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" [maxSize]="3" [rotate]="true"
                            [pageSize]="pageLength" [boundaryLinks]="true" (pageChange)="pageChange($event)"
                            class="float-right"></ngb-pagination>
                    </div>
                </div>
                <!-- /.panel -->
            </div>
        </div>
    </div>
</section>