import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/utilities/utilities.services';
import { paginationConfig } from 'src/app/constants';
import { apiUrls } from 'src/app/api-urls';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private _api: ApiDataServices,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private _utils: UtilityService

  ) { auth.checkAuth(); }

  loading = true;
  bannerList: any = [];
  isDeleting = false;
  isError = false;
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize = 20;


  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  ngOnInit(): void {
    this.getbannerList();
  }


  pageChange(page) {
    this.getbannerList(page);
  }

  //Getting Bannerlist
  getbannerList(page = 1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this._api.postApiData(apiUrls.bannerList, data).subscribe(
      (data: any) => {
        this.loading = false;
        this.bannerList = data.data;
        if (data.meta.pageLength && data.meta.page) {
          this.collectionsize = data.meta.total;
          this.maxPages = data.meta.pages;
          this.endCount = (this.page == this.maxPages) ?
            (this.collectionsize < this.pageLength) ? this.collectionsize :
              ((this.collectionsize % this.pageLength == 0)) ?
                this.collectionsize :
                ((this.page == this.maxPages)) ? this.collectionsize :
                  this.endCount + (this.collectionsize % this.pageLength) :
            (this.page * this.pageLength);
        } else {
          this.tableloading = true;
        }
      },
      (error: any) => {
        this.loading = false;
        this.isError = true;
        this.tableloading = false;
      }
    );
  }

  //Deleting Banner using (idBanner)
  confirmDelete(res) {
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure want to delete?'
      }
    });

    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) this.deleteBanner(res);
      }
    )
  }

  deleteBanner(res) {
    this._api.putApiData(apiUrls.bannerDelete(res.idBanner)).subscribe(
      (data: any) => {
        this.isDeleting = false;
        this.toastr.success('Banner deleted successfully', "Deleted");
        this.deletebannerFromList(res);
      },
      error => {
      },
      () => { }
    )
  }

  deletebannerFromList(res) {
    this.bannerList = this._utils.filterArrayObj(this.bannerList, 'idBanner', res.idBanner);
  }

  //Function for toggle button
  toggleActive(banner, e) {
    let status = e.checked ? 'true' : 'false';
    let active = e.checked ? '0' : '1';
    const activeDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to ' + status + ' the ' + banner.bannerName + '?'
      }
    });

    activeDialog.afterClosed().subscribe(
      data => {
        (data) ? 'this.toggleActiveBanner(user.id, e)' : this.bannerList = this._utils.changeArrayObjProp(this.bannerList, 'id', banner.id, 'false', !e.checked);
      }
    );
  }

  toggleActiveBanner(id, e) {
    let data = {
      id: id,
      active: e.checked ? 1 : 0
    };
    this.bannerList = this._utils.changeArrayObjProp(this.bannerList, 'id', id, 'false', e.checked);
    this._api.putApiData('this._urls.bannerActiveUrl', data).subscribe(
      (data: any) => {
        let id = data.data.id;
        this.bannerList = this._utils.changeArrayObjProp(this.bannerList, 'id', id, 'false', e.checked);
      },
      error => {

      },
      () => {

      });
  }

}
