<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Banner</h2>
        </div>
        <!-- /.col-md-6 -->
        <div class="col-md-6 right-side">
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/banner/add'">Add</a>
        </div>
        <!-- /.col-md-6 text-right -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li class="active">Banner</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>BannerList</h5>
                        </div>
                    </div>
                    <div class="panel-body p-20">
                        <div *ngIf="isDeleting" class="text-center">
                            <h4>Deleting...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <div class="table-responsive">
                            <table *ngIf="!loading" class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>BannerName</th>
                                        <th>Text</th>
                                        <th>Location</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let banner of bannerList let i = index">
                                        <td>{{(((page - 1 )*(pageLength))) + (i + 1)}}</td>
                                        <td>{{banner.bannerName}}</td>
                                        <td>{{banner.text}}</td>
                                        <td>{{banner.location }}</td>
                                        <td>
                                            <mat-slide-toggle color="$mat-green" [(ngModel)]="banner.isActive"
                                                (change)="toggleActive(banner, $event)"></mat-slide-toggle>
                                        </td>
                                        <td>
                                            <a class="btn btn-default icon-only margin"
                                                [routerLink]="['/banner/edit',banner.idBanner]"><i
                                                    class="fa fa-pencil"></i></a>
                                            <button type="button" class="btn btn-danger icon-only"
                                                (click)="confirmDelete(banner)"><i class="fa fa-trash-o"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="!loading && bannerList.length == 0" class="text-center">
                            <h4>No data found!</h4>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="collectionsize > pageLength && !loading">
                        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" [maxSize]="3" [rotate]="true"
                            [pageSize]="pageLength" [boundaryLinks]="true" (pageChange)="pageChange($event)"
                            class="float-right"></ngb-pagination>
                    </div>
                </div>
                <!-- /.panel -->
            </div>
        </div>
    </div>
</section>