import { Component, OnInit } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/utilities/utilities.services';
import { ToastrService } from 'ngx-toastr';
import { paginationConfig } from 'src/app/constants';
import { apiUrls } from 'src/app/api-urls';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-badgeconfig-list',
  templateUrl: './badgeconfig-list.component.html',
  styleUrls: ['./badgeconfig-list.component.css']
})
export class BadgeconfigListComponent implements OnInit {

  constructor(
    private auth : AuthenticationService,
    private toastr: ToastrService,
    private _api: ApiDataServices,
    private dialog: MatDialog,
    private _utils: UtilityService
  ) { auth.checkAuth();}

  badgeList:any=[];
  loading = true;
  isDeleting = false;
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize=20;

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  ngOnInit(): void {
    this.getbadgeList();
  }

  pageChange(page) {
    this.getbadgeList(page);
  }
  //Getting BadgeConfiglist
  getbadgeList(page=1){
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this._api.postApiData(apiUrls.badgeList, data).subscribe(
      (data:any)=>{
        this.loading=false;
        this.badgeList = data.data;
        if(data.meta.pageLength && data.meta.page) {
          this.collectionsize = data.meta.total;
          this.maxPages = data.meta.pages;
          this.endCount = (this.page == this.maxPages) ?
            (this.collectionsize < this.pageLength) ? this.collectionsize :
              ((this.collectionsize % this.pageLength == 0)) ?
                this.collectionsize :
                ((this.page == this.maxPages)) ? this.collectionsize :
                  this.endCount + (this.collectionsize % this.pageLength) :
            (this.page * this.pageLength);
        } else {
          this.tableloading = true;
        }
      },
      error=>{
        this.loading=false;
        this.tableloading = false;
      }
    );

  }
  
  //Deleting BadgeConfig using (idBadgeConfig)
  confirmDelete(res){
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) this.deleteBadge(res);
      }
    );
  }  

  deleteBadge(res) {
    this._api.putApiData(apiUrls.badgeDelete(res.idBadgeConfig))
      .subscribe(
        (data: any) => {
          this.isDeleting = false;
          this.toastr.success('BadgeConfig  deleted successfully', "Deleted");
          this.deletebadgeFromList(res);
          },
          error => {

          },
          () => { }
      );
  }
  deletebadgeFromList(res) {
    this.badgeList = this._utils.filterArrayObj(this.badgeList, 'idBadgeConfig', res.idBadgeConfig);
  }

}
