import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from 'src/app/models/location';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { apiUrls } from 'src/app/api-urls';

@Component({
  selector: 'app-locations-edit',
  templateUrl: './locations-edit.component.html',
  styleUrls: ['./locations-edit.component.css']
})
export class LocationsEditComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private api: ApiDataServices,
    private route: Router, private actRoute: ActivatedRoute) { }

  locationForm: FormGroup;
  submitted = false;
  isError = false;
  loading = true;
  isSaving = false;
  errorMessage = "";
  location: Location;
  loadingLocations = false;
  locations: any[] = [];
  ngOnInit(): void {
    this.loading = true;
    this.location = new Location();
    this.actRoute.paramMap.subscribe(params => {
      this.location.idLocation = params.get('id');
      this.getLocation();
      this.getLocations();
    });
  }

  getLocations(){
    this.loadingLocations = true;
    this.api.postApiData(apiUrls.locationList).pipe()
    .subscribe(
        (data: any) => {
          this.loadingLocations = false;
          this.locations = data.data;
        },
        error => {
          this.loadingLocations = false;
        });
  }

  getLocation(){
    this.loading = true;
    this.api.postApiData(apiUrls.locationGet(this.location.idLocation)).pipe()
    .subscribe(
        (data: any) => {
          this.loading = false;
          this.location = data.data
          this.locationForm = this.formBuilder.group({
            locationName: [this.location.locationName, Validators.required],
            parent: [(this.location.parent)]
          });
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong';
        });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.locationForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.locationForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    this.api.putApiData(apiUrls.locationUpdate(this.location.idLocation), this.locationForm.getRawValue()).pipe()
    .subscribe(
        (data: any) => {
          this.isSaving = false;
          this.submitted = false;
          this.route.navigate(['/locations/list']);
        },
        (error:any) => {
          this.isSaving = false;
          this.submitted = false;
          this.isError = true;
          this.errorMessage = error && error.error.errors.error ? error.error.errors.error : 'Sorry, something went wrong.';
        });
  }


}
