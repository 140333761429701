<div class="container" (fileDropped)="onFileDropped($event)">
	<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
	<svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
		<g fill="#3B454F" fill-rule="nonzero">
			<path
				d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
			<path
				d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
			<path
				d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
		</g>
	</svg>

	<h3>Drag and drop file(s) here</h3>
	<h3>or</h3>
	<label for="fileDropRef">Browse for file</label>
</div>
<div class="files-list">
	<div class="col-md-3 b-b-1eee mt-50" *ngFor="let file of mediaFiles; let i = index">
        <div class="text-center">
            <img class="dnd-img" src="{{file.url ? file.url : file.base64}}">
        
			<h4 class="name text-overflow">
				{{ file?.name }}
			</h4>
			<p class="size">
				{{ formatBytes(file?.size) }}
			</p>
		</div>
        <div class="text-center">
            <div class="mt-20 mb-20" (click)="deleteFile(i)"> <button _ngcontent-gil-c169="" type="button" class="btn btn-danger icon-only ml-15 mt-10"><i _ngcontent-gil-c169="" class="fa fa-trash-o"></i></button>
            </div>
        </div>
	</div>
</div>
<div class="hide" id="{{mediaFilesDivId}}">{{mediaFiles | json}}</div>