<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Tags</h2>
        </div>
        <!-- /.col-md-6 -->
        <div class="col-md-6 right-side">
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/tag/add'">Add</a>
        </div>
        <!-- /.col-md-6 text-right -->
    </div>
    <!-- /.row --> 
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
				<li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
				<li class="active">Tags</li>
			</ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>

<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Tags</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <input class="form-control" placeholder="Search by name" (change)="searchByName($event)" />
                            </div>
                            <div class="col-md-3">
                                <input class="form-control" placeholder="Search by code" (change)="searchByCode($event)" />
                            </div>
                            <div class="col-md-6">
                            </div>
                        </div>
                    </div>
                    <div class="panel-body p-20">
                        <div *ngIf="loading" class="text-center"><h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4></div>
                        <div class="table-responsive">
                            <table *ngIf="!loading" class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>Name</th>
                                        <th>Code</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let tag of tags | paginate: paginationConfig let i = index">
                                        <td>{{(((page - 1 )*(pageLength))) + (i + 1)}}</td>
                                        <td>{{tag.tagName}}</td>
                                        <td>{{tag.tagCode}}</td>
                                        <td>
                                            <a class="btn btn-default icon-only mr-10" [routerLink]="['/tag/edit',tag.idTag]"><i class="fa fa-pencil"></i></a>
                                            <button type="button" class="btn btn-danger icon-only" (click)="showDeleteModal(tag)"><i class="fa fa-trash-o"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="!loading && tags.length == 0" class="text-center"><h4>No data found!</h4></div>
                    </div>
                    <div class="col-12" *ngIf="collectionsize > pageLength && !loading">
                        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" [maxSize]="3" [rotate]="true"
                            [pageSize]="pageLength" [boundaryLinks]="true" (pageChange)="pageChange($event)"
                            class="float-right"></ngb-pagination>
                    </div>
                </div>
                <!-- /.panel -->
            </div>
        </div>
    </div>
</section>

<!-- Delete Modal tigger button -->
<input type="button" style="display:none;" #deleteModalButton id="deleteModalButton" (click)="defaultModal.show()" />
<!-- Delete Modal -->

<div class="modal fade" bsModal id="myModal" tabindex="-1" role="dialog" #defaultModal="bs-modal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Delete</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="isError" class="text-center"><div class="alert alert-danger">{{errorMessage}}</div></div>
                <h4 class="text-center">Are you sure you want to delete {{selectedTag.tagName}} ? </h4>
            </div>
            <div class="modal-footer">
                <div class="btn-group" role="group">
                    <button type="button" #cancelModalButton class="btn btn-gray mr-10" (click)="defaultModal.hide()">Cancel</button>
                    <button type="button" class="btn bg-danger" (click)="delete()">{{isDeleting ? 'Deleting...' : 'Delete'}} 
                        <i *ngIf="isDeleting" class="fa fa-spinner fa-spin"></i>
                    </button>
                </div>
                <!-- /.btn-group -->
            </div>
        </div>
    </div>
</div>
