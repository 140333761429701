import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { concatMap, retryWhen, timeout, catchError, mergeMap } from 'rxjs/operators';
import { StorageService } from '../Localstorage/storage.service';
import { AdminUser } from "../../models/admin-user";
import { LocalStorageKeys } from "../../enums";
import { apiUrls } from "../../api-urls";
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ApiDataServices {
    private _adminUser: AdminUser;
    private _httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    constructor(
        public _http: HttpClient,
        public _lstore: StorageService,
        private toastr: ToastrService,
        private _route: Router
    ) {
        this._adminUser = new AdminUser();
        this._adminUser = JSON.parse(localStorage.getItem(LocalStorageKeys.AdminUser));
    }

    postApiData(url: any, jsonData: any = {}, authenticateAdmin: boolean = true) {
        if (authenticateAdmin) {
            return this._http.post(apiUrls.getSessionKeyUrl, this._adminUser).pipe(
                mergeMap((data: any) => {
                    if (!data.data.sessionKey) {
                        localStorage.clear();
                        window.location.href = '/accounts/login?msg=token';
                    }
                    jsonData.sessionKey = data.data.sessionKey;
                    jsonData.publicKey = this._adminUser.publicKey;
                    jsonData.accessKey = this._adminUser.accessKey;
                    // jsonData.idUser = this._adminUser.idUser;
                    return this._http.post(url, jsonData, this._httpOptions);
                }),
                (catchError(err => {
                    if (err.url == 'https://xoomlapi.business-software.in/api/get/sessionkey' ||
                        err.url == 'https://xoomstageapi.business-software.in/api/get/sessionkey') {
                        if ((err.status == 422) || (err.status == 401)) {
                            localStorage.clear();
                            window.location.href = '/accounts/login?msg=token';
                        }
                    }
                    this.apiError(err);
                    return throwError(err);
                }))
            )
        } else {
            return this._http.post(url, jsonData, this._httpOptions).pipe(
                (catchError(err => {
                    this.apiError(err);
                    return throwError(err);
                }))
            );
        }
    }

    putApiData(url: any, jsonData: any = {}, authenticateAdmin: boolean = true) {
        if (authenticateAdmin) {
            return this._http.post(apiUrls.getSessionKeyUrl, this._adminUser).pipe(
                mergeMap((data: any) => {
                    if (!data.data.sessionKey) {
                        localStorage.clear();
                        window.location.href = '/accounts/login?msg=token';
                    }
                    jsonData.sessionKey = data.data.sessionKey;
                    jsonData.publicKey = this._adminUser.publicKey;
                    jsonData.accessKey = this._adminUser.accessKey;
                    // jsonData.idUser = this._adminUser.idUser;
                    return this._http.put(url, jsonData, this._httpOptions);
                }),
                (catchError(err => {
                    if (err.url == 'https://xoomlapi.business-software.in/api/get/sessionkey') {
                        if ((err.status == 422) || (err.status == 401)) {
                            localStorage.clear();
                            window.location.href = '/accounts/login?msg=token';
                        }
                    }
                    this.apiError(err);
                    return throwError(err);
                }))
            );
        } else {
            return this._http.put(url, jsonData, this._httpOptions).pipe(
                (catchError(err => {
                    this.apiError(err);
                    return throwError(err);
                }))
            );
        }
    }

    apiError(error: any) {
        let errorMessage = 'Sorry, Something went wrong!';
        if (error && error.error && error.error.errors) {
            errorMessage = '';
            for (var key of Object.keys(error.error.errors)) {
                if (key) {
                    errorMessage += `${key}: ${error.error.errors[key]}`;
                }
            }
        }
        this.toastr.error(errorMessage, 'Error!', { timeOut: 25000 }); // 30 seconds, user atleast get enough time to read about errors
    }
}
