<section class="section">
    <div class="container-fluid">
        <div class="col-md-offset-3 col-md-6 mt-50 pt-50">
            <div class="panel">
                <div class="panel-body p-30">
                    <div class="error-box">
                        <i class="fa fa-exclamation-circle error-icon"></i>
                        <h1 class="error-title">500</h1>
                        <p class="sub-title">Error Occurred!</p>
                        <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.</p>
                        <a [routerLink]="'/dashboard'" class="btn btn-primary btn-lg btn-rounded">Back To Dashboard</a>
                    </div>
                    <!-- /.error-box -->
                </div>
            </div>
            <!-- /.panel -->
        </div>
        <!-- /.col-md-offset-3 col-md-6 -->
    </div>
    <!-- /.container-fluid -->
</section>
