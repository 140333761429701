<div [formGroup]="form" class="Postd_box">
    <h3>{{formData.block}}</h3>
    <div class="postd_body">
        <ng-container *ngFor="let fdata of formData.params">
            <div class="form-group" [ngSwitch]="fdata.paramType">
                <ng-container *ngSwitchCase="'radio'">
                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span mr-3"
                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                    <ng-container *ngFor="let idata of fdata.values;index as i;">
                        <div class="btn-group btn-group-toggle mt-2" ngbRadioGroup name="radioBasic">
                            <p>
                                <label ngbButtonLabel class="btn btn-radio radioad"
                                    [ngClass]="selectedVal[fdata?.inputName] == idata.value ? i  == 0 ? 'fchildBradius activeRadio' : i + 1 == fdata?.values.length  ? 'lchildBradius activeRadio' : ' activeRadio' : i == 0 ? 'fchildBradius' : i + 1 == fdata?.values.length ? 'lchildBradius' : ''"
                                    (click)="changeRadio(fdata.inputName,idata.value)" [attr.for]="fdata.inputName+i"
                                    [innerHTML]="idata.lable">
                                    <input ngbButton type="radio" [id]="fdata.inputName+i"
                                        [formControlName]="fdata.inputName" [value]="idata.value"></label>
                            </p>
                        </div>
                    </ng-container>

                </ng-container>
                <ng-container *ngSwitchCase="'checkbox'">
                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                    <p *ngFor="let idata of fdata.values">
                        <label class="switch">
                            <input type="checkbox" [id]="fdata.inputName" [formControlName]="fdata.inputName"
                                [checked]="idata.checked" [value]="idata.value"><span
                                class="slider round"></span></label>
                        <label [attr.for]="fdata.inputName" [innerHTML]="idata.lable" class="pl-3">
                        </label>
                    </p>

                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <ng-container *ngFor="let idata of fdata.values">
                        <ng-container *ngIf="fdata.inputName == 'address'">
                            <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span><span
                                class="options mr-3" *ngIf="!fdata.isRequired">(Optional)</span>
                            <input (keydown.enter)="$event.preventDefault()" type="text" class="form-control"
                                [formControlName]="fdata.inputName" [attr.placeholder]="idata.placeholder"
                                [value]="fdata.inputValue" #addressacd>
                        </ng-container>
                        <!-- <ng-container *ngIf="fdata.inputName == 'city'">
                      <label class="text" [innerHTML]="fdata.title"></label><span class="req-span" *ngIf="fdata.isRequired"><i
                          class="fa fa-asterisk" aria-hidden="true"></i></span><span class="options mr-3"
                        *ngIf="!fdata.isRequired">{{(Optional)}}</span>
                      <input (focusout)="focusOutFunction()" (keydown.enter)="$event.preventDefault()" type="text"
                        class="form-control" [formControlName]="fdata.inputName" [attr.placeholder]="idata.placeholder"
                        [value]="fdata.inputValue" #locationacd>
                    </ng-container> -->
                        <ng-container *ngIf="fdata.inputName == 'email'">
                            <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span><span
                                class="options mr-3" *ngIf="!fdata.isRequired">(Optional)</span>
                            <input (keydown.enter)="$event.preventDefault()" type="text" class="form-control"
                                [formControlName]="fdata.inputName" [attr.placeholder]="idata.placeholder"
                                [value]="fdata.inputValue">
                            <h6 class="tagclass">Email cannot be visible to user</h6>
                        </ng-container>
                        <ng-container *ngIf="fdata.inputName == 'phone'">
                            <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span><span
                                class="options mr-3" *ngIf="!fdata.isRequired">(Optional)</span>
                            <input type="checkbox" [checked]="isShowPhone" class="showphone" (click)="isChecked()">
                            <label class="showphone"> Show Phonenumber </label><br>
                            <input (keydown.enter)="$event.preventDefault()" type="text" class="form-control"
                                maxlength="10" [formControlName]="fdata.inputName"
                                [attr.placeholder]="idata.placeholder" [value]="fdata.inputValue">
                        </ng-container>
                        <ng-container *ngIf="fdata.inputName == 'salary'">
                            <div class="row">
                                <div class="col-3">
                                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk"
                                            aria-hidden="true"></i></span><span class="options mr-3"
                                        *ngIf="!fdata.isRequired">(Optional)</span></div>
                                <div class="col-3">
                                    <p>
                                        <input (focusout)="focusOutSalary()" type="number" id="minsalary"
                                            class="form-control" placeholder="Min $" formControlName="minsalary">
                                        <label for="minsalary" innerHTML="Min"></label>
                                    </p>
                                    <div *ngIf="minsalaryError" class="alert " role="alert">
                                        <div *ngIf="minsalaryError" class="error">
                                            {{minsalaryError}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <p>
                                        <input (focusout)="focusOutSalary()" type="number" id="maxsalary"
                                            class="form-control" placeholder="Max $" formControlName="maxsalary">
                                        <label for="maxsalary" innerHTML="Max"></label>
                                    </p>
                                    <div *ngIf="maxsalaryError" class="alert " role="alert">
                                        <div *ngIf="maxsalaryError" class="error">
                                            {{maxsalaryError}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="fdata.inputName == 'price'">
                            <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span><span
                                class="options mr-3" *ngIf="!fdata.isRequired">(Optional)</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                    <input (keydown.enter)="$event.preventDefault()" type="number" step='0.01'
                                        value='0.00' class="form-control" [formControlName]="fdata.inputName"
                                        [attr.placeholder]="idata.placeholder" [value]="fdata.inputValue">
                                </div>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="fdata.inputName != 'email' && fdata.inputName != 'address'  && fdata.inputName != 'phone'  && fdata.inputName != 'salary' && fdata.inputName != 'price'">
                            <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span><span
                                class="options mr-3" *ngIf="!fdata.isRequired">(Optional)</span>
                            <input (keydown.enter)="$event.preventDefault()" type="text" class="form-control"
                                [formControlName]="fdata.inputName" [attr.placeholder]="idata.placeholder"
                                [value]="fdata.inputValue">
                        </ng-container>


                    </ng-container>

                </ng-container>

                <ng-container *ngSwitchCase="'image'">
                    <div>
                        <h5 [innerHTML]="fdata.title"></h5>
                        <h6 class="tagclass">(Maximum upload size: 4MB, 1st image will be main image)</h6>
                    </div>
                    <div [innerHTML]="fdata.desc"></div>
                    <div class="clearfix mt-5" cdkDropListGroup *ngIf="croute == 'edit';else defaultImg">
                        <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate"
                            (cdkDropListDropped)="dropListDropped()"></div>

                        <div class="wrap-custom-file" cdkDropList *ngFor="let idata of imageId;index as i"
                            [cdkDropListEnterPredicate]="dropListEnterPredicate"
                            (cdkDropListDropped)="dropListDropped()">
                            <div cdkDrag class="wrap-custom-file" (cdkDragMoved)="dragMoved($event);">
                                <input type="file" [formControlName]="idata.name" id="{{idata.name}}"
                                    accept=".gif, .jpg, .jpeg, .png" multiple
                                    (change)="onFileChange($event, idata.name,fdata.inputName,i,true)">
                                <label attr.for="{{idata.name}}" class="img-preview" id="{{'lbl'+idata.name}}"
                                    [ngStyle]="{'background-image': 'url(' + idata.value + ')'}">
                                    <ng-container *ngIf="(!idata?.value && !isUplink[i]) || (isUplink[i] == 2)">
                                        <i class="fa fa-plus-circle"></i>
                                    </ng-container>
                                </label>
                                <ng-container *ngIf="(idata?.value && !isUplink[i]) || (isUplink[i] == 1) ">
                                    <button type="button" class="close" (click)="editClose(idata.name,i)"
                                        data-dismiss="alert" aria-hidden="true">×</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-template #defaultImg>
                        <div class="clearfix mt-5" cdkDropListGroup>
                            <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate"
                                (cdkDropListDropped)="dropListDropped()"></div>

                            <div class="wrap-custom-file" cdkDropList *ngFor="let idata of imageId;index as i"
                                [cdkDropListEnterPredicate]="dropListEnterPredicate"
                                (cdkDropListDropped)="dropListDropped()">
                                <div cdkDrag class="wrap-custom-file" (cdkDragMoved)="dragMoved($event);">
                                    <input type="file" [formControlName]="idata.name" id="{{idata.name}}"
                                        accept=".gif, .jpg, .jpeg, .png" multiple
                                        (change)="onFileChange($event, idata.name,fdata.inputName,i)">
                                    <label attr.for="{{idata.name}}" class="img-preview" id="{{'lbl'+idata.name}}">
                                        <ng-container *ngIf="!isUplink[i]">
                                            <i class="fa fa-plus-circle"></i>
                                        </ng-container>
                                    </label>
                                    <ng-container *ngIf="isUplink[i]">
                                        <button type="button" class="close" (click)="close(idata.name,i)"
                                            data-dismiss="alert" aria-hidden="true">×</button>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <span class="help-block error" *ngIf="!isValidSize">Please give file within 4MB</span>
                </ng-container>

                <ng-container *ngSwitchCase="'options'">
                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                    <select class="form-control" class="form-control" [formControlName]="fdata.inputName">
                        <option [value]="idata.value" *ngFor="let idata of fdata.values">{{idata.lable}}</option>
                    </select>
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                    <ng-container *ngFor="let idata of fdata.values;index as i">
                        <p>
                            <input type="text" [id]="fdata.inputName+i" placeholder="MM/DD/YYYY" class="form-control"
                                [formControlName]="fdata.inputName" [value]="idata.value" bsDatepicker>
                            <label [attr.for]="fdata.inputName+i" class="dateAlign" [innerHTML]="idata.lable"></label>
                        </p>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'daterange'">
                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                    <ng-container *ngFor="let idata of fdata.values;index as i">
                        <p>
                            <input type="text" [id]="fdata.inputName+i" placeholder="MM/DD/YYYY - MM/DD/YYYY"
                                class="form-control" [formControlName]="fdata.inputName" [value]="idata.value"
                                bsDaterangepicker [bsConfig]="{ isAnimated: true }">
                            <label [attr.for]="fdata.inputName+i" class="dateAlign" [innerHTML]="idata.lable"></label>
                        </p>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tag'">

                    <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                        *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span>
                    <h6 class="tagclass">(Press enter to add tags, You can add upto 5 tags)</h6>
                    <ng-container *ngFor="let idata of fdata.values">
                        <p class="inputTagcontain" id="tags">
                            <tag-input [formControlName]="fdata.inputName" [maxItems]="5" [ripple]="false"
                                [attr.placeholder]="idata.placeholder">
                                <tag-input-dropdown [showDropdownIfEmpty]="false" [keepOpen]="false"
                                    [autocompleteObservable]="requestAutocompleteItems">
                                    <ng-template let-item="item" let-index="index">
                                        <div>{{ item.value }} </div>
                                    </ng-template>
                                </tag-input-dropdown>
                            </tag-input>
                            <label [attr.for]="fdata.inputName" [innerHTML]="idata.lable" class="pl-3"></label>
                        </p>
                    </ng-container>

                </ng-container>
                <ng-container *ngSwitchCase="'video'">
                    <ng-container *ngFor="let idata of fdata.values">
                        <ng-container>
                            <label class="text" [innerHTML]="fdata.title"></label><span class="req-span"
                                *ngIf="fdata.isRequired"><i class="fa fa-asterisk" aria-hidden="true"></i></span><span
                                class="options mr-3" *ngIf="!fdata.isRequired">(Optional)</span>
                            <h6 class="tagclass">(Supported videos: Youtube, Vimeo)</h6>
                            <input (focusout)="focusOutVideo($event)" (keydown.enter)="$event.preventDefault()"
                                type="text" class="form-control" [formControlName]="fdata.inputName"
                                [attr.placeholder]="idata.placeholder" [value]="fdata.inputValue">
                            <div *ngIf="isvalidVideoLink" class="error">
                                Please give youtube or vimeo URL
                            </div>
                        </ng-container>
                    </ng-container>

                </ng-container>


            </div>

            <ng-container
                *ngIf="fdata?.inputName != 'image' && fdata?.inputName != 'salary' && fdata?.inputName != 'address'">
                <div *ngIf="form.controls[fdata?.inputName].invalid && (form.controls[fdata?.inputName].dirty || form.controls[fdata?.inputName].touched)"
                    class="alert " role="alert">
                    <div *ngIf="form.controls[fdata?.inputName].errors.required" class="error">
                        {{fdata.title}} is Required
                    </div>
                    <div *ngIf="form.controls[fdata?.inputName].errors.pattern" class="error">
                        Please give a valid {{fdata.title}}
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="fdata?.inputName == 'city'">
                <div *ngIf="form.controls[fdata?.inputName].invalid && (form.controls[fdata?.inputName].dirty || form.controls[fdata?.inputName].touched)"
                    class="alert " role="alert">
                    <div *ngIf="form.controls[fdata?.inputName].errors.required" class="error">
                        Please select and give a specific classified city
                    </div>
                    <div *ngIf="form.controls[fdata?.inputName].errors.pattern" class="error">
                        Please give a valid {{fdata.title}}
                    </div>
                </div>
            </ng-container> -->
            <ng-container *ngIf="fdata?.inputName == 'address'">
                <div *ngIf="form.controls[fdata?.inputName].invalid && (form.controls[fdata?.inputName].dirty || form.controls[fdata?.inputName].touched)"
                    class="alert " role="alert">
                    <div *ngIf="form.controls[fdata?.inputName].errors.required" class="error">
                        Please select and give a specific classified address
                    </div>
                    <div *ngIf="form.controls[fdata?.inputName].errors.pattern" class="error">
                        Please give a valid {{fdata.title}}
                    </div>
                </div>
            </ng-container>

        </ng-container>

    </div>

</div>