import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiDataServices } from '../_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { Language } from 'src/app/models/language';
import { paginationConfig } from '../constants';
import { apiUrls } from '../api-urls';
import { ConfirmdialogComponent } from '../common/dialog/confirmdialog/confirmdialog.component';

@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.css']
})
export class LanguageListComponent implements OnInit {

  @ViewChild('deleteModalButton') deleteModalButton: ElementRef;
  @ViewChild('cancelModalButton') cancelModalButton: ElementRef;
  languages: Language[];
  loading = true;
  isDeleting = false;
  isError = false;
  errorMessage = "Sorry, Something went wrong";
  selectedLanguage: Language = new Language();
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize = 20

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  constructor(private auth: AuthenticationService,
    private dialog: MatDialog,
    private api: ApiDataServices,
    private toastr: ToastrService) {
    auth.checkAuth();
  }


  ngOnInit(): void {
    this.languages = [];
    this.getLanguages();
  }

  pageChange(page) {
    this.getLanguages(page);
  }

  getLanguages(page = 1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this.api.postApiData(apiUrls.languageList, data).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.languages = data.data;
          if (data.meta.pageLength && data.meta.page) {
            this.paginationConfig.totalItems = data.meta.total;
            this.paginationConfig.currentPage = data.meta.page;
            this.paginationConfig.itemsPerPage = data.meta.pageLength;
            this.paginationConfig.maxSize = data.meta.pageLength;
            this.tableloading = false;
          } else {
            this.tableloading = true;
          }
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.tableloading = false;
        });
  }

  showDeleteModal(language) {
    this.isDeleting = false;
    // let inputElement: HTMLElement = this.deleteModalButton.nativeElement as HTMLElement;
    // inputElement.click();

    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: `Are you sure, you want to delete ${language.languageName} Language?`
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedLanguage = language;
          this.delete();
        }
      }
    );
  }

  public delete() {
    if (this.selectedLanguage) {
      this.isDeleting = true;
      this.api.putApiData(apiUrls.locationDelete(this.selectedLanguage.idLanguage)).pipe()
        .subscribe(
          (data: any) => {
            this.isDeleting = false;
            this.toastr.success('Language deleted successfully', "Deleted");
            let index = this.languages.indexOf(this.selectedLanguage);
            if (index >= -1) {
              this.languages.splice(index, 1);
            }
            this.selectedLanguage = new Language();
          },
          error => {
            this.loading = false;
            this.isError = true;
          });
    } else {
      alert('Language not found');
    }
  }
}
