import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageKeys } from '../enums';
@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: HttpClient) { }
  login(json) {
    return this.http.post(`${environment.apiUrl}/auth/admin/login`, json).pipe(res => res);
  }
}
