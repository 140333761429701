import { Injectable } from '@angular/core';
import { LocalStorageKeys } from './enums';
import { AdminUser } from './models/admin-user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  currenturl = null;
  adminUser: AdminUser;
  constructor() {
    
  }
  checkAuth() {
    if(localStorage.getItem(LocalStorageKeys.AdminUser)) {
      this.adminUser = JSON.parse(localStorage.getItem(LocalStorageKeys.AdminUser));
    } else {
      window.location.href = '/accounts/login';
    }
  }

}
