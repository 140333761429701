import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { Advertisement } from 'src/app/models/advertisement';
import { apiUrls } from 'src/app/api-urls';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { ToastrService } from 'ngx-toastr';
import { paginationConfig } from 'src/app/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-list-ad',
  templateUrl: './list-ad.component.html',
  styleUrls: ['./list-ad.component.css']
})
export class ListAdComponent implements OnInit {
  checked: boolean = false;
  constructor(
    private auth: AuthenticationService,
    private api: ApiDataServices,
    private route: Router,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService) { auth.checkAuth(); }
  @ViewChild('deleteModalButton') deleteModalButton: ElementRef;
  @ViewChild('cancelModalButton') cancelModalButton: ElementRef;
  advertisements: Advertisement[] = [];
  loading = true;
  isLoading: boolean;
  isDeleting = false;
  isError = false;
  errorMessage = "Sorry, Something went wrong";
  selectedAdvertisment: Advertisement = new Advertisement();
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize = 20;
  activatingAdId: any;

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  ngOnInit(): void {
    this.advertisements = [];
    this.getAdvertisments();
  }
  getLoadingView() {
    this.loading = true;
  }


  pageChange(page) {
    this.getAdvertisments(page);
  }

  getAdvertisments(page = 1) {
    this.loading = true;
    let json = {
      "idAdCategory": "",
      "idLocation": "",
      "idUser": "",
      "searchKeyword": "",
      page: page,
      pageLength: this.pageLength
    }
    this.api.postApiData(apiUrls.advertisementList, json).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.advertisements = data.data;
          if (data.meta.pageLength && data.meta.page) {
            this.collectionsize = data.meta.total;
            this.maxPages = data.meta.pages;
            this.endCount = (this.page == this.maxPages) ?
              (this.collectionsize < this.pageLength) ? this.collectionsize :
                ((this.collectionsize % this.pageLength == 0)) ?
                  this.collectionsize :
                  ((this.page == this.maxPages)) ? this.collectionsize :
                    this.endCount + (this.collectionsize % this.pageLength) :
              (this.page * this.pageLength);
          } else {
            this.tableloading = true;
          }
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.tableloading = false;
        });
  }

  showDeleteModal(advertisementId) {
    if (this.advertisements.find(a => a.idAdvertisement == advertisementId)) {
      const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
        width: '400px',
        height: '170px',
        data: {
          message: 'Are you sure, you want to delete?'
        }
      });
      deleteDialog.afterClosed().subscribe(
        data => {
          if (data) this.deleteAdverisement(advertisementId);
        }
      );
    }
  }

  deleteAdverisement(advertisementId) {
    if (this.advertisements.find(a => a.idAdvertisement == advertisementId)) {
      this.api.putApiData(apiUrls.advertisementDelete(advertisementId)).pipe()
        .subscribe(
          (data: any) => {
            this.loading = false;
            this.toastr.success('Advertisement deleted successfully', 'Deleted');
            let index = this.advertisements.findIndex(a => a.idAdvertisement == advertisementId);
            if (index >= -1) {
              this.advertisements.splice(index, 1);
            }
          },
          error => {
            this.loading = false;
            this.isError = true;
          });
    }
  }

  activateAd(i, ad) {
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: (ad.isActive != 1) ? 'Are you sure, you want to deactivate the advertisement?' : 'Are you sure, you want to activate the advertisement?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.activateAdvertisement(i, ad);
        } else {
          this.advertisements[i]['isActive'] = !ad.isActive;
        }
      }
    );
  }
  activateAdvertisement(i, ad) {
    this.activatingAdId = ad.idAdvertisement;
    this.isLoading = true;
    let data = {
      "isActive": ad.isActive == 1 ? true : false
    }
    this.api.putApiData(apiUrls.advertisementActivate(ad.idAdvertisement), data).pipe()
      .subscribe(
        (data: any) => {
          this.toastr.success(data.data?.message, 'Done');
          this.isLoading = false;
          this.activatingAdId = null;
        },
        (error: any) => {
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
          this.isError = true;
          this.isLoading = false;
          this.activatingAdId = null;
        });

  }

  bumpAd(idAdvertisement) {
    this.api.putApiData(apiUrls.advertisementBump(idAdvertisement)).subscribe(
      (data: any) => {
        this.loading = false;
        this.toastr.success('Advertisement bumped successfully');
      },
      error => {
        this.loading = false;
      });
  }
  promoteAd(ad) {
    localStorage.setItem('promotedBadge', JSON.stringify(ad.promotedBadges))
    this.route.navigate(['/advertisements/add-badge', ad.idAdvertisement]);
  }
}

