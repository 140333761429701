<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Banner</h2>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/banner/list'">Banner</a></li>
                <li class="active">{{actionType}}</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>

<!-- /.container-fluid -->
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2 ml-120">
                <div class="panel w-750">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>{{title}}</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <form *ngIf="!loading" [formGroup]="bannerForm" (ngSubmit)="onSubmit()">


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.bannerName.errors }">
                                <label for="bannerName" class="control-label">Banner Name</label>
                                <div class="">
                                    <input type="text" formControlName="bannerName" class="form-control"
                                        placeholder="BannerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.bannerName.errors }" />
                                    <span *ngIf="submitted && f.bannerName.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>

                            <!-- <div class="form-group" [ngClass]="{ 'has-error': submitted && f.text.errors }">
                                <label for="text" class="control-label">Text</label>
                                <div class="">
                                    <ckeditor [editor]="Editor" formControlName="text"  ></ckeditor>
                                    <span *ngIf="submitted && f.text.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div> -->


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.texteditor.errors }">
                                <label for="texteditor" class="control-label">Text</label>
                                <angular-editor (ngModelChange)="onChange2($event)" formControlName="texteditor"
                                    [config]="editorConfig"></angular-editor>
                                <span *ngIf="submitted && f.texteditor.errors" class="help-block">This field is
                                    required.</span>
                            </div>


                            <!-- <div class="form-group" [ngClass]="{ 'has-error': submitted && f.image.errors }">
                                <label for="image" class="control-label">image</label>
                                <div class="image-upload">
                                    <img [src]="image" style="max-width:300px;max-height:300px"/>
                                    <input type="file"
                                        (change)="handleInputChange($event)"/>
                                        <input formControlName="image"  type="hidden"/>
                                </div>
                            </div> -->


                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.location.errors }">
                                <label for="location" class="control-label">Location</label>
                                <div class="">
                                    <select formControlName="location" class="form-control">
                                        <option value="" disabled selected>Select Location</option>
                                        <option>top</option>
                                        <option>header</option>
                                        <option>middle1</option>
                                        <option>middle2</option>
                                        <option>middle3</option>
                                        <option>left1</option>
                                        <option>left2</option>
                                        <option>left3</option>
                                        <option>right1</option>
                                        <option>right2</option>
                                        <option>right3</option>
                                        <option>footer</option>
                                    </select>

                                    <span *ngIf="submitted && f.location.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.pageCode.errors }">
                                <label for="pageCode" class="control-label">Page Code</label>
                                <div class="">
                                    <select formControlName="pageCode" class="form-control">
                                        <option value="" disabled selected>Select Pagecode</option>
                                        <option value="HomeP01">Home Page</option>
                                        <option value="ListP01">List</option>
                                        <option value="InfoP01">Ad Info Page</option>
                                    </select>

                                    <span *ngIf="submitted && f.pageCode.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>





                            <button type="submit"
                                class="btn btn-primary">{{actionType == 'Add'? (isSaving ? 'Creating...' : 'Create') : (isSaving ? 'Updating...' : 'Update') }}
                                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>