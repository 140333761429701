<div *ngIf="validPage" class="main-wrapper">
   <app-top-navbar *ngIf="!isLoginPage"></app-top-navbar>
   <div class="content-wrapper">
      <div class="content-container">
         <div class="sidebar-content left-sidebar box-shadow {{isLoginPage ? 'none' : ''}}"
            [class.small-nav]="smallSidebar" [class.bg-black-300]="bgBlack300" [class.bg-primary]="bgPrimary"
            [class.bg-danger]="bgDanger" [class.bg-success]="bgSuccess" [class.bg-warning]="bgWarning"
            [class.bg-gray-500]="bgLight">
            <div class="sidenav">
               <button class="dropdown-btn d_1" data-id="1"><i class="fa fa-user-secret pr-15"></i>Acl
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'user-acl/list'" href="#"
                     (click)="change('user-acl/list')"><i class="fa fa-universal-access pr-15"></i>Acl</a>
               </div>
               <button class="dropdown-btn d_2" data-id="2"><i class="fa fa-users pr-15"></i>User
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'user-types/list'" href="#"
                     (click)="change('user-types/list')"><i class="fa fa-user-secret pr-15"></i>User Type</a>
                  <a routerLinkActive="active" [routerLink]="'user/list'" href="#" (click)="change('user/list')"><i
                        class="fa fa-user pr-15"></i>User</a>
               </div>
               <button class="dropdown-btn d_3" data-id="3"><i class="fa fa-map pr-15"></i>Advertisement
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'category/list'" href="#"
                     (click)="change('category/list')"><i class="fa fa-sitemap pr-15"></i>Category</a>
                  <a routerLinkActive="active" [routerLink]="'advertisements/list'" href="#"
                     (click)="change('advertisements/list')"><i class="fa fa-industry pr-15"></i>Advertisements</a>
               </div>
               <button class="dropdown-btn d_4" data-id="4"><i class="fa fa-id-badge pr-15"></i>Badge
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'badge-config/list'" href="#"
                     (click)="change('badge-config/list')"><i class="fa fa-user-secret pr-15"></i>Badge Config</a>
               </div>
               <button class="dropdown-btn d_5" data-id="5"><i class="fa fa-map-marker pr-15"></i>Location
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'locations/list'" href="#"
                     (click)="change('locations/list')"><i class="fa fa-location-arrow pr-15"></i>Locations</a>
               </div>
               <button class="dropdown-btn d_6" data-id="6"><i class="fa fa-camera-retro pr-15"></i>Banner
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'banner/list'" href="#" (click)="change('banner/list')"><i
                        class="fa fa-pencil-square-o pr-15"></i>Banners</a>
               </div>
               <button class="dropdown-btn d_7" data-id="7"><i class="fa fa-camera-retro pr-15"></i>Settings
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'settings'" href="#" (click)="change('settings')"><i
                        class="fa fa-pencil-square-o pr-15"></i>Settings</a>
               </div>
               <button class="dropdown-btn d_8" data-id="8"><i class="fa fa-language pr-15"></i>Language
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'language/list'" href="#"
                     (click)="change('language/list')"><i class="fa fa-list pr-15"></i>List</a>
               </div>

               <button class="dropdown-btn d_9" data-id="9"><i class="fa fa-globe pr-15"></i>Translation
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'translation/list'" href="#"
                     (click)="change('translation/list')"><i class="fa fa-list pr-15"></i>List</a>
               </div>

               <button class="dropdown-btn d_8" data-id="8"><i class="fa fa-tag pr-15"></i>Tags
                  <i class="fa fa-caret-right"></i>
               </button>
               <div class="dropdown-container">
                  <a routerLinkActive="active" [routerLink]="'tag/list'" href="#"
                     (click)="change('tag/list')"><i class="fa fa-tag pr-15"></i>List</a>
               </div>
            </div>
         </div>
         <div class="main-page">
            
            <router-outlet></router-outlet>
         </div>
      </div>
   </div>
</div>
<div *ngIf="!validPage" class="main-wrapper">
   <div class="text-center">
      <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
   </div>
</div>