<div class="container-fluid">
  <div class="row page-title-div">
      <div class="col-md-6">
          <h2 class="title">User ACL</h2>
      </div>
      <!-- /.col-md-6 -->
      <div class="col-md-6 right-side">
          <a class="btn bg-black toggle-code-handle" [routerLink]="'/user-acl/add'">Add</a>
      </div>
      <!-- /.col-md-6 text-right -->
  </div>
  <!-- /.row -->
  <div class="row breadcrumb-div">
      <div class="col-md-6">
          <ul class="breadcrumb">
              <li><a [routerLink]="'dashboard'"><i class="fa fa-home"></i> Home</a></li>
              <li class="active">ACL</li>
          </ul>
      </div>
      <!-- /.col-md-6 -->
  </div>
  <!-- /.row -->
</div>


<section class="section">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12">
              <div class="panel">
                  <div class="panel-heading">
                      <div class="panel-title">
                          <h5>User ACL</h5>
                      </div>
                      <!-- <div *ngIf="!tableloading" class="text-right">
                        <pagination-controls
                            (pageChange)="pageChanged($event)"
                            (pageBoundsCorrection)="pageChanged($event)"
                            maxSize={{maxsize}}
                            previousLabel="Previous"
                            nextLabel="Next">
                        </pagination-controls>

                    </div> -->
                  </div>
                  <div class="panel-body p-20">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <div class="table-responsive">
                            <table *ngIf="!loading" class="table table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. #</th>
                                    <th>Namespace</th>
                                    <th>Route</th>
                                    <th>Path</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let userACL of userAclList let i = index">
                                    <td>{{(((page - 1 )*(pageLength))) + (i + 1)}}</td>
                                    <td scope="row">{{userACL.namespace}}</td>
                                    <td>{{userACL.route}}</td>
                                    <td>{{userACL.path}}</td>
                                    <td><mat-slide-toggle [(ngModel)]="userACL.isActive"  (change)="toggleActive(userACL, $event)"></mat-slide-toggle></td>
                                    <td>
                                        <a class="btn btn-default icon-only margin"
                                            [routerLink]="['/user-acl/update',userACL.idUserAcl]"><i
                                                class="fa fa-pencil"></i></a>
                                        <button type="button" class="btn btn-danger icon-only"
                                            (click)="confirmDelete(userACL)"><i class="fa fa-trash-o"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div *ngIf="!loading && userAclList.length == 0" class="text-center">
                            <h4>No data found!</h4>
                        </div>
                  </div>
                  <div class="col-12" *ngIf="collectionsize > pageLength && !loading">
                      <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" [maxSize]="3" [rotate]="true"
                          [pageSize]="pageLength" [boundaryLinks]="true" (pageChange)="pageChange($event)"
                          class="float-right"></ngb-pagination>
                  </div>
              </div>
              <!-- /.panel -->
          </div>
      </div>
  </div>
</section>