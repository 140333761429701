<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Add Badge</h2>
        </div>
    </div>
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/advertisements/list'">Advertisements</a></li>
                <li class="active">Add-badge</li>
            </ul>
        </div>
    </div>
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Link Badge</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <div class="row">
                            <div class="col-md-1"> </div>
                            <div class="col-md-10">
                                <table *ngIf="!loading" class="table table-hover table-bordered">
                                    <thead class="c-green">
                                        <tr>
                                            <th>Badge</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Visibility</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let badge of badges">
                                            <td><img src="{{badge.imageLink}}" width="70" height="70"></td>
                                            <td>{{badge.title}}</td>
                                            <td>{{badge.description}}</td>
                                            <td>{{badge.visibility}}</td>
                                            <td>${{badge.price}}</td>
                                            <td>
                                                <button (click)="hit(badge.idBadgeConfig)" type="button" [disabled]="badge.isPromoted"
                                                    [ngClass]="badge.isPromoted ? 'fa fa-arrow-circle-up btn promoted':'fa fa-arrow-circle-up btn c-green'"><i
                                                        aria-hidden="true"></i></button>
                                                <span *ngIf="linkingBadgeId == badge.idBadgeConfig && isLinking">
                                                    <h4><i class="fa fa-spinner fa-spin ml-20"></i></h4>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-1"> </div>
                        </div>
                        <div class="col-sm-6"> </div>
                        <div class="text-right" *ngIf="!loading">
                            <div class="text-right">
                                <a [routerLink]="'/advertisements/list'" class="btn btn-default mr-20">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>