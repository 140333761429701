<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Translation</h2>
        </div>
        <div class="col-md-6 right-side">
            <a class="btn bg-black toggle-code-handle" [routerLink]="'/translation/list'">Back</a>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/translation/list'">Translations</a></li>
                <li class=" text-capitalize active">{{title}}</li>
            </ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5 class="text-capitalize">{{title}} Translation</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="loading" class="text-center">
                            <h4>Loading...<i class="fa fa-spinner fa-spin"></i></h4>
                        </div>
                        <form [formGroup]="translationForm" (ngSubmit)="onSubmit()" *ngIf="!loading">
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.idLanguage.errors }">
                                <label for="language" class="control-label">Language</label>
                                <div class="">
                                    <mat-select formControlName="idLanguage" placeholder="Select Language*"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.idLanguage.errors }">
                                        <mat-option *ngFor="let language of languages" value="{{language.idLanguage}}">
                                            {{language.languageName}}</mat-option>
                                    </mat-select>
                                    <span *ngIf="submitted && f.idLanguage.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.slug.errors }">
                                <label for="slug" class="control-label">Slug</label>
                                <div class="">
                                    <input type="text" formControlName="slug" class="form-control" placeholder="Slug"
                                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.slug.errors }" />
                                    <span *ngIf="submitted && f.slug.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'has-error': submitted && f.text.errors }">
                                <label for="text" class="control-label">Text</label>
                                <div class="">
                                    <textarea type="text" formControlName="text" class="form-control"
                                        placeholder="Some Text..." class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.text.errors }"></textarea>
                                    <span *ngIf="submitted && f.text.errors" class="help-block">This field is
                                        required.</span>
                                </div>
                            </div>
                            <div *ngIf="title ==='add'; else updateButton">
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!translationForm.valid">{{isSaving ? 'Creating...' : 'Create' }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </div>
                            <ng-template #updateButton>
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!translationForm.valid">{{isSaving ? 'Updating...' : 'Update' }}
                                    <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                </button>
                            </ng-template>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>