import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiDataServices } from '../_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { Tag } from '../models/tag';
import { paginationConfig } from '../constants';
import { apiUrls } from '../api-urls';
import { ConfirmdialogComponent } from '../common/dialog/confirmdialog/confirmdialog.component';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css']
})
export class TagListComponent implements OnInit {

  @ViewChild('deleteModalButton') deleteModalButton: ElementRef;
  @ViewChild('cancelModalButton') cancelModalButton: ElementRef;
  tags: Tag[];
  loading = true;
  isDeleting = false;
  isError = false;
  errorMessage = 'Sorry, Something went wrong';
  selectedTag: Tag = new Tag();
  tableloading = true;
  paginationConfig: any = paginationConfig;
  maxsize = 20;
  searchTagName = '';
  searchTagCode = '';

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;


  constructor(private auth: AuthenticationService,
    private dialog: MatDialog,
    private api: ApiDataServices,
    private toastr: ToastrService) {
    auth.checkAuth();
  }


  ngOnInit(): void {
    this.tags = [];
    this.getTags();
  }

  pageChanged(event) {
    this.paginationConfig.currentPage = event;
    this.getTags(event);
  }

  pageChange(page) {
    this.getTags(page);
  }

  getTags(page = 1) {
    this.loading = true;
    const data = {
      page,
      pageLength: this.pageLength
    };
    if (this.searchTagName) data['tagName'] = this.searchTagName;
    if (this.searchTagCode) data['tagCode'] = this.searchTagCode;
    this.api.postApiData(apiUrls.tagList, data).pipe()
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.tags = data.data;
          if (data.meta.pageLength && data.meta.page) {
            this.paginationConfig.totalItems = data.meta.total;
            this.paginationConfig.currentPage = data.meta.page;
            this.paginationConfig.itemsPerPage = data.meta.pageLength;
            this.paginationConfig.maxSize = data.meta.pageLength;
            this.tableloading = false;
          } else {
            this.tableloading = true;
          }
        },
        error => {
          this.loading = false;
          this.isError = true;
          this.tableloading = false;
        });
  }

  showDeleteModal(tag) {
    this.isDeleting = false;
    // let inputElement: HTMLElement = this.deleteModalButton.nativeElement as HTMLElement;
    // inputElement.click();

    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: `Are you sure, you want to delete ${tag.tagName} tag?`
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedTag = tag;
          this.delete();
        }
      }
    );
  }

  public delete() {
    if (this.selectedTag) {
      this.isDeleting = true;
      this.api.putApiData(apiUrls.tagDelete(this.selectedTag.idTag)).pipe()
        .subscribe(
          (data: any) => {
            this.isDeleting = false;
            this.toastr.success('Tag deleted successfully', 'Deleted');
            const index = this.tags.indexOf(this.selectedTag);
            if (index >= -1) {
              this.tags.splice(index, 1);
            }
            this.selectedTag = new Tag();
          },
          error => {
            this.loading = false;
            this.isError = true;
          });
    } else {
      alert('Tag not found');
    }
  }

  searchByName(ev) {
    this.searchTagName = ev.target.value;
    this.getTags();
  }

  searchByCode(ev) {
    this.searchTagCode = ev.target.value;
    this.getTags();
  }
}
