<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Edit Advertisement</h2>
        </div>
    </div>
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
                <li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
                <li><a [routerLink]="'/advertisements/list'">Advertisements</a></li>
                <li class="active">Update</li>
            </ul>
        </div>
    </div>
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <h5>Edit Advertisement</h5>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="mainarea">
                            <div class="container">
                                <h4 *ngIf="isLoading" class="text-center">Loading...<i
                                        class="fa fa-spinner fa-spin"></i></h4>
                                <div class="Postd_dtls" *ngIf="adDetails && !isLoading">
                                    <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
                                        <h4>Post Your Ad</h4>
                                        <div class="Postd_box">
                                            <div class="form-group">
                                                <label class="text">Ad Title</label><span class="req-span"><i
                                                        class="fa fa-asterisk" aria-hidden="true"></i></span>
                                                <textarea class="form-control" name="title" id="title" minlength="8"
                                                    required formControlName="adTitle"></textarea>
                                                <div *ngIf="form.controls['adTitle'].invalid && (form.controls['adTitle'].dirty || form.controls['adTitle'].touched)"
                                                    class="alert  mt-2" role="alert">
                                                    <div *ngIf="form.controls['adTitle'].errors.required" class="error">
                                                        Ad title is required
                                                    </div>
                                                    <div *ngIf="form.controls['adTitle'].invalid" class="error">
                                                        Minimum 8 characters required
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <label class="text breadform">Selected Category</label>

                                                <ol class="catbread">
                                                    <li *ngIf="parentCategory">{{parentCategory?.categorytitle}} </li>
                                                    <li *ngIf="childCategory"> <i class="fa fa-angle-double-right angle"
                                                            aria-hidden="true"></i> {{childCategory?.categorytitle}}
                                                    </li>
                                                    <li *ngIf="grandChildCategory" class="breadcrumb-item active"
                                                        aria-current="page"> <i class="fa fa-angle-double-right angle"
                                                            aria-hidden="true"></i>
                                                        {{grandChildCategory?.categorytitle}}</li>
                                                </ol>

                                            </div>
                                            <div class="form-group mt-3" *ngIf="categoryCode==9967471">
                                                <label class="text">Job Offered By</label><span class="req-span"><i
                                                        class="fa fa-asterisk" aria-hidden="true"></i></span>
                                                <p>
                                                    <input type="radio" id="Individual" formControlName="advertiserType"
                                                        value="Individual" checked="">
                                                    <label for="Individual">Individual
                                                    </label>
                                                </p>
                                                <p>
                                                    <input type="radio" id="Business" formControlName="advertiserType"
                                                        value="Business">
                                                    <label for="Business">Professional employer
                                                    </label>
                                                </p>
                                                <p>
                                                    <input type="radio" id="External" formControlName="advertiserType"
                                                        value="External">
                                                    <label for="External">Third party employer
                                                    </label>
                                                </p>
                                                <div *ngIf="form.controls['advertiserType'].invalid && (form.controls['advertiserType'].dirty || form.controls['advertiserType'].touched)"
                                                    class="alert  mt-2" role="alert">
                                                    <div *ngIf="form.controls['advertiserType'].errors.required"
                                                        class="error">
                                                        Job Offered By Required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <label class="text">Ad Description</label> <span class="req-span"><i
                                                        class="fa fa-asterisk" aria-hidden="true"></i></span>
                                                <angular-editor formControlName="adDesc" [config]="editorConfig"
                                                    minlength="8" required></angular-editor>
                                                <div *ngIf="form.controls['adDesc'].invalid && (form.controls['adDesc'].dirty || form.controls['adDesc'].touched)"
                                                    class="alert  mt-2" role="alert">
                                                    <div *ngIf="form.controls['adDesc'].errors.required" class="error">
                                                        Ad Description is required
                                                    </div>
                                                    <div *ngIf="form.controls['adDesc'].invalid" class="error">
                                                        Minimum 8 characters required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let data of dataForm;index as i">
                                            <app-ad-builder [formData]="data" [form]="form" [ind]="i" croute="edit"
                                                (response)="getResponse($event)" (imageOrder)="getImageOrder($event)">
                                            </app-ad-builder>
                                        </ng-container>
                                        <div class="text-right mt-50 mb-20">
                                            <a [routerLink]="'/advertisements/list'"
                                                class="btn btn-default mr-20">Cancel</a>
                                            <button type="submit"
                                                class="btn btn-primary mr-20">{{isSaving ? 'Updating...' : 'Update' }}
                                                <i *ngIf="isSaving" class='fa fa-spinner fa-spin'></i>
                                            </button>
                                        </div>

                                        <!-- <div class="btn-group mt-4">
                                            <input type="submit" value="postad" class="btn btn-danger float-right">
                                        </div> -->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>