import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiDataServices } from 'src/app/_services/apiservices/api.service';
import { ToastrService } from 'ngx-toastr';
import { apiUrls } from 'src/app/api-urls';
import { paginationConfig } from 'src/app/constants';
import { Setting } from 'src/app/models/setting';
import { ConfirmdialogComponent } from 'src/app/common/dialog/confirmdialog/confirmdialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/authentication.service';
import { SettingDialogComponent } from 'src/app/common/dialog/setting-dialog/setting-dialog.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  paginationConfig: any = paginationConfig;
  isDeleting = false;
  tableloading = true;

  //Pagination
  page: number = 1;
  pageLength: number = 20;
  collectionsize: number;
  startCount: number = 1;
  endCount: number;
  maxPages: number;



  constructor(private _ac: ActivatedRoute,
    private _fb: FormBuilder,
    private _api: ApiDataServices,
    private _router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private auth: AuthenticationService,
  ) {
    auth.checkAuth();
  }

  loading = false;
  settings: any = '';
  settingsForm: FormGroup;
  errorMessage = "";
  isSaving = false;
  submitted = false;
  isError = false;
  logo: string = '';
  selectedSetting: Setting = new Setting();
  idSetting: string = '';

  ngOnInit(): void {
    this.getSettings();
    this.createSettingsFormInit();
  }

  //Initializing Userform for validation
  createSettingsFormInit() {
    this.settingsForm = this._fb.group({
      'key': ['', Validators.required],
      'value': ['', Validators.required],
    });
  }

  get f() { return this.settingsForm.controls; }

  //Getting User data using (idUser)
  getSettings(page = 1) {
    this.loading = true;
    let data = {
      page: page,
      pageLength: this.pageLength
    }
    this._api.postApiData(apiUrls.getSettings, data).subscribe(
      (data: any) => {
        this.settings = data.data;
        if (this.settings) {
          this.loading = false;
          if (data.meta.pageLength && data.meta.page) {
            this.collectionsize = data.meta.total;
            this.maxPages = data.meta.pages;
            this.endCount = (this.page == this.maxPages) ?
              (this.collectionsize < this.pageLength) ? this.collectionsize :
                ((this.collectionsize % this.pageLength == 0)) ?
                  this.collectionsize :
                  ((this.page == this.maxPages)) ? this.collectionsize :
                    this.endCount + (this.collectionsize % this.pageLength) :
              (this.page * this.pageLength);
          } else {
            this.tableloading = true;
          }
        }
      }
    )
  }

  pageChange(page) {
    this.getSettings(page);
  }

  openEdit(setting) {
    this.idSetting = setting.idSettings
    const deleteDialog = this.dialog.open(SettingDialogComponent, {
      width: '500px',
      height: '350px',
      data: {
        editSetting: setting
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.onSubmitEdit(data.data);
        }
      });
  }

  onSubmitEdit(response) {
    this._api.putApiData(apiUrls.settingUpdate(this.idSetting), response).pipe()
      .subscribe(
        (data: any) => {
          this.isSaving = false;
          this.submitted = false;
          this.toastr.success('Settings Updated successfully', "Updated");
          this.getSettings();
        },
        (error: any) => {
          this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
          this.isSaving = false;
          this.submitted = false;
          this.isError = true;
        });

  }

  onSubmit() {
    // this.f.logo.value ? (data.logo = this.f.logo.value) : '';console.log(data)
    if (this.settingsForm.invalid) {
      return;
    }
    this.isError = false;
    this.isSaving = true;
    this.submitted = true;
    let data: any = {
      key: this.f.key.value,
      value: this.f.value.value,
    }
    this._api.postApiData(apiUrls.createSetting, data).subscribe(
      (data: any) => {
        this.isSaving = false;
        this.submitted = false;
        this.toastr.success('Settings Created successfully', "Created");
        this.settingsForm.reset();
        this.getSettings();
      },
      (error: any) => {
        this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
        this.isSaving = false;
        this.submitted = false;
        this.isError = true;
      });
  }


  showDeleteModal(setting) {
    this.isDeleting = false;
    const deleteDialog = this.dialog.open(ConfirmdialogComponent, {
      width: '400px',
      height: '170px',
      data: {
        message: 'Are you sure, you want to delete?'
      }
    });
    deleteDialog.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedSetting = setting;
          this.delete();
        }
      }
    );
  }

  public delete() {
    if (this.selectedSetting) {
      this.isDeleting = true;
      this._api.putApiData(apiUrls.settingDelete(this.selectedSetting.idSettings)).pipe()
        .subscribe(
          (data: any) => {
            this.isDeleting = false;
            this.toastr.success('Translation deleted successfully', "Deleted");
            let index = this.settings.indexOf(this.selectedSetting);
            if (index >= -1) {
              this.settings.splice(index, 1);
            }
            this.selectedSetting = new Setting();
          },
          error => {
            this.loading = false;
            this.isError = true;
          });
    } else {
      alert('Language not found');
    }
  }
}

