<div class="container-fluid">
    <div class="row page-title-div">
        <div class="col-md-6">
            <h2 class="title">Chat</h2>
        </div>
    </div>
    <!-- /.row -->
    <div class="row breadcrumb-div">
        <div class="col-md-6">
            <ul class="breadcrumb">
				<li><a [routerLink]="'/dashboard'"><i class="fa fa-home"></i> Home</a></li>
				<li class="active">Messages</li>
			</ul>
        </div>
        <!-- /.col-md-6 -->
    </div>
    <!-- /.row -->
</div>
<section class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="panel">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <div class="row">
                                <div class="col-md-2">
                                    <img src="../../assets/images/user.png" alt="John Doe" class="img-circle profile-img">
                                </div>
                                <div class="col-md-4">
                                    <h4 class="mt-30">Admin's Test Chat</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-1">
                                
                            </div>
                            <div class="col-md-10 message-div">
                                    <div *ngIf="messages.length === 0">
                                        Loading Messages ...
                                    </div>
                                    <div *ngFor="let message of messages">
                                        <b>{{ message.senderName }}</b>: {{ message.message }}
                                    </div>
                                
                            </div>
                            <div class="col-md-1">
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                
                            </div>
                            <div class="col-md-9">
                                <textarea class="form-control message" id="message_val" matInput placeholder="Type your message here..."></textarea>
                            </div>
                            <div class="col-md-2 pl-0">
                                <button class="btn btn-primary message" (click)="sendMessage('hi', 'hlo')"><i class="fa fa-send"></i></button>
                            </div>
                        </div>
                          
                    </div>
                </div>
            </div>
            <!-- /.col-md-8 col-md-offset-2 -->
        </div>
    </div>
</section>