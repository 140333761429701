import {
    Component,
    OnInit,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiDataServices } from "../../_services/apiservices/api.service";
import { ToastrService } from 'ngx-toastr';
import { apiUrls } from "../../api-urls";
import { ConfigBlockComponent } from "../config-block/config-block.component";
import { DataTransferService } from "../../_services/data-transfer/data-transfer.service";

const componentMap = {
    'blockComp': ConfigBlockComponent
};

type ValueOf<T> = T[keyof T];

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
    @ViewChild('configBlock', { read: ViewContainerRef, static: true }) configBlock: ViewContainerRef;
    @Input() actionType: string = 'Add';
    @Input() title: string = 'Add New Category';

    private _crtComp: keyof typeof componentMap = null;
    private componentList: any[] = [];
    public categoryForm: FormGroup;
    public blockIndex: number = 0;
    public formIndex: number = 0;
    submitted = false;
    loading = false;
    errorMessage = "";
    isSaving = false;
    idAdCategory: any = '';
    categoryData: any = '';
    parentList: any = [];
    uploadedimage: any = '';
    blockdata: any = [];
    // new variable add
    public pageblocks: any[];
    public hasAvailableBlock: boolean = true;
    public blockName: string;
    private editData: any[];

    constructor(
        private cfr: ComponentFactoryResolver,
        private toastr: ToastrService,
        private _fb: FormBuilder,
        private _api: ApiDataServices,
        private router: Router,
        private _dt: DataTransferService,
        private _ac: ActivatedRoute) { }

    get f() { return this.categoryForm.controls; }

    ngOnInit(): void {
        this._dt.initForm();
        this.createCategoryFormInit();
        this.getCategoryList();
        this.getPageBlockNames();
        this._ac.params.subscribe(
        params => {
            this.idAdCategory = params.id;
            if (this.idAdCategory) {
            this.getCategory();
            }
        }
    )
        this.getCategoryForm();
        localStorage.removeItem('image_url');
    }

    private getCategoryForm() {
      this._dt.categoryForm.subscribe(res => {
          this.categoryForm = res;
      });
  }

  private getPageBlockNames() {
      this._dt.pageBlocks.subscribe(res => {
          this.pageblocks = res;
          if (this.pageblocks.length > 0) {
              this.hasAvailableBlock = true;
          } else {
              this.hasAvailableBlock = false;
          }
      });
  }

  private createConfigBlock (compClass) {
      const compFactory = this.cfr.resolveComponentFactory(compClass);

      return this.configBlock.createComponent(compFactory);
  }

  private loadNewComponentList (blockName) {
      const comp = this.createConfigBlock(componentMap[this._crtComp]);

      (comp.instance as any).blockName = blockName;
      (comp.instance as any).blockIdx = this.blockIndex;
      (comp.instance as any).parentCompList = this.componentList;
      (comp.instance as any).editData = this.editData;

      comp.changeDetectorRef.detectChanges();

      this.componentList[this.blockIndex] = comp;
      this.blockIndex++;

      this.removeBlockNameFromList(blockName);
  }

  private removeBlockNameFromList(blockName: string) {
      this.pageblocks = this.pageblocks.filter(function (val) {
            return val !== blockName;
      });
      if (this.pageblocks.length > 0) {
          this.categoryForm.patchValue({blockName: this.pageblocks[0]});
      } else {
          this.categoryForm.patchValue({blockName: ''});
      }

      this._dt.updatePageBlock(this.pageblocks);
  }

  public addNewBlock(isEdit = false) {
        if (!isEdit) {
            this.editData = null;
        }

      if (this.categoryForm.value.blockName === '') {
          this.toastr.warning('Please select a page block from the drop down above.', 'Warning!');
      } else {
          this._crtComp = 'blockComp';
          this.loadNewComponentList(this.categoryForm.value.blockName);
      }
  }

  //Initializing Category form for validation
  createCategoryFormInit() {
      this._dt.updateForm( this._fb.group({
          idAdCategory: [''],
          categoryTitle: ['', Validators.required],
          parent: [''],
          categoryIcon: [''],
          categoryIconMobile: [''],
          categoryImage: ['', Validators.required],
          categoryColor: [''],
          categoryHoverColor: [''],
          blockName: [''],
          params: this._fb.array([]),
      }) );
  }

  newParamsSpec(name = '') {
    return this._fb.group({
      'name': [name]
    });
  }

  createTypeList(type='', name='', description = '', isRequired = '', placeholder = '', regexValue = '',
  filterable ='', idParamSettings = '' ) {
    return this._fb.group({
      'name': [(name !=='')? name :''],
      'description': [(description !=='')? description :''],
      'isRequired': [(isRequired !=='')? isRequired :true],
      'placeholder': [(placeholder !=='')? placeholder :''],
      'regexType': [''],
      'regexValue': [(regexValue !=='')? regexValue :''],
      'filterable': [(filterable !=='')? filterable :true],
      'idParamSettings': [(idParamSettings !=='')? idParamSettings :''],
      'selected': [true],
    })
  }

  convertToSlug(Text) {
    return Text
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
      ;
  }

  //Calling Submit function
  hit() {
    this.submitted = true;

    //Checking form validaton
    this.isSaving = true;

    //Creating Category
    if (this.actionType == 'Add') {
      let data: any = {
        categoryTitle: this.f.categoryTitle.value,
        categoryIcon: this.f.categoryIcon.value,
        categoryIconMobile: this.f.categoryIconMobile.value,
        categoryColor: this.f.categoryColor.value,
        categoryHoverColor: this.f.categoryHoverColor.value,
      }

      if (!this.f.categoryTitle.value) {
        this.isSaving = false;
        this.toastr.warning('Category Title is Required', 'Please Wait');
        return;
      }

      if (this.f.parent.value) {
        data.parent = this.f.parent.value;
      }

      if (this.f.params.value) {
        data.params = [];
      }

      if (localStorage.getItem('image_url')) {
        data.categoryImage = localStorage.getItem('image_url');
      }

      let elementObject: any;
      const paramsValue: any = this.categoryForm.value.params;
      if (paramsValue.length > 0) {
          let lastOptionSlug = '';
          paramsValue.forEach(element => {
              if (
                  element.inputtype === 'radio' ||
                  element.inputtype === 'options' ||
                  element.inputtype === 'checkbox'
              ) {
                  let paramSlug = '';
                  element.options.forEach(optElem => {
                      paramSlug = (optElem.description !== null) ? this.convertToSlug(optElem.description) : lastOptionSlug;
                      elementObject = {
                          paramType: optElem.inputtype,
                          pageBlock: optElem.blockName,
                          customParam: this.convertToSlug(optElem.name),
                          defaultValue: optElem.selected.toString(),
                          isRequired: optElem.isRequired,
                          paramDesc: optElem.description,
                          paramName: optElem.name,
                          paramSlug: paramSlug,
                          regex: '',
                          filterable: optElem.filterable
                      };

                      lastOptionSlug = this.convertToSlug(optElem.name);
                      data['params'].push(elementObject);
                    });
            } else if (element.inputtype === 'image') {
                for (let i = 0; i < 10; i++) {
                    elementObject = {
                        paramType: element.inputtype,
                        pageBlock: element.blockName,
                        customParam: 'image' + (i + 1),
                        defaultValue: element.placeholder,
                        isRequired: element.isRequired,
                        paramDesc: element.description,
                        paramName: element.name,
                        paramSlug: this.convertToSlug('image ' + (i + 1)),
                        regex: element.regexValue,
                        filterable: element.filterable,
                    }

                    data['params'].push(elementObject);
                }
            } else {
                elementObject = {
                    paramType: element.inputtype,
                    pageBlock: element.blockName,
                    customParam: this.convertToSlug(element.name),
                    defaultValue: element.placeholder,
                    isRequired: element.isRequired,
                    paramDesc: element.description,
                    paramName: element.name,
                    paramSlug: this.convertToSlug(element.name),
                    regex: element.regexValue,
                    filterable: element.filterable
                }

                data['params'].push(elementObject);
            }
        });
      }
      // console.log(elementArray);
      // data['params'].push(elementArray);
      // console.log(data);

      this._api.postApiData(apiUrls.categoryCreate, data).subscribe(
        (data: any) => {
          this.isSaving = false;
          this.submitted = false;

            this.toastr.success('Category created successfully', "Created");
            this.router.navigate(['category/list']);
          },
          (error: any) => {
            this.errorMessage = error && error.message ? error.message : 'Sorry, Something went wrong';
            this.isSaving = false;
            this.submitted = false;
          });
    }

    //Editing Category details
    if (this.actionType == 'Edit') {
      let data: any = {
        categoryTitle: this.f.categoryTitle.value
      }

      if (this.f.parent.value !== "0") {
        data.parent = this.f.parent.value;
      }
      if (this.f.categoryIcon.value) {
        data.categoryIcon = this.f.categoryIcon.value;
      }

      if (this.f.categoryIconMobile.value) {
        data.categoryIconMobile = this.f.categoryIconMobile.value;
      }
      if (this.f.categoryColor.value) {
        data.categoryColor = this.f.categoryColor.value;
      }
      if (this.f.categoryHoverColor.value) {
        data.categoryHoverColor = this.f.categoryHoverColor.value;
      }

      if (this.f.params.value) {
        data.params = [];
      }
      if (localStorage.getItem('image_url')) {
        data.categoryImage = localStorage.getItem('image_url');
      }

      let elementObject: any;
      let paramsValue: any = this.categoryForm.value.params;

      if (paramsValue.length > 0) {
          let lastOptionSlug = '';
          paramsValue.forEach(element => {
              if (
                  element.inputtype === 'radio' ||
                  element.inputtype === 'options' ||
                  element.inputtype === 'checkbox'
              ) {
                  const options = element.options;

                  let paramSlug = '';
                  options.forEach(optElem => {
                      paramSlug = (optElem.description !== null) ? this.convertToSlug(optElem.description) : lastOptionSlug;
                      elementObject = {
                          paramType: optElem.inputtype,
                          pageBlock: optElem.blockName,
                          customParam: this.convertToSlug(optElem.name),
                          defaultValue: optElem.selected.toString(),
                          isRequired: optElem.isRequired,
                          paramDesc: optElem.description,
                          paramName: optElem.name,
                          paramSlug: paramSlug,
                          regex: '',
                          filterable: optElem.filterable,
                          idParamSettings: optElem.idParamSettings
                      };

                      lastOptionSlug = this.convertToSlug(optElem.name);
                      data['params'].push(elementObject);
                    });
              } else if (element.inputtype === 'image') {
                  for (let i = 0; i < 10; i++) {
                      elementObject = {
                          paramType: element.inputtype,
                          pageBlock: element.blockName,
                          customParam: 'image' + (i + 1),
                          defaultValue: element.placeholder,
                          isRequired: element.isRequired,
                          paramDesc: element.description,
                          paramName: element.name,
                          paramSlug: this.convertToSlug('image ' + (i + 1)),
                          regex: element.regexValue,
                          filterable: element.filterable,
                      };

                      data['params'].push(elementObject);
                    }
              } else {
                  elementObject = {
                      paramType: element.inputtype,
                      pageBlock: element.blockName,
                      customParam: this.convertToSlug(element.name),
                      defaultValue: element.placeholder,
                      isRequired: element.isRequired,
                      paramDesc: element.description,
                      paramName: element.name,
                      paramSlug: this.convertToSlug(element.name),
                      regex: element.regexValue,
                      filterable: element.filterable,
                      idParamSettings: element.idParamSettings
                  };

                  data['params'].push(elementObject);
              }
          });
      }


      // console.log(data);

      this._api.putApiData(apiUrls.categoryUpdate(this.idAdCategory), data).subscribe(
        (data: any) => {
          this.isSaving = false;
          this.submitted = false;
          this.toastr.success('Category updated successfully', "Updated");
          this.router.navigate(['category/list'])

          },
          (error: any) =>{
            this.errorMessage = error && error.message ? error.message : 'Sorry, something went wrong.';
            this.isSaving = false;
            this.submitted = false;

        });
    }

  }

  onSelectFile(event) { // called each time file input changes

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => { // called once readAsDataURL is completed
          this.uploadedimage = event.target.result;
          localStorage.setItem('image_url', this.uploadedimage.toString());
      }

    }
  }

  //Getting Category data using (idAdCategory)
  getCategory() {
    this.loading = true;
    this._api.postApiData(apiUrls.categoryGet(this.idAdCategory)).subscribe(
      (data: any) => {
        this.categoryData = data.data;
        if (this.categoryData) {
            this.setCategoryData(this.categoryData);
        }
      });
  }

  public setCategoryData(data) {
      if (typeof data !== 'undefined') {
          this.categoryForm.patchValue({
              categoryTitle: data['categoryTitle'],
              parent: data['parent'],
              categoryIcon: data['categoryIcon'],
              categoryIconMobile: data['categoryIconMobile'],
              categoryColor: data['categoryColor'],
              categoryHoverColor: data['categoryHoverColor'],
              idAdCategory: data['idAdCategory']
          });

          this.uploadedimage = data['categoryImage'];
      }
      this.createConfigBlocks(data);
  }

  private createConfigBlocks(data) {
      for (let idx in data['params']) {
          if (
              data['params'][idx]['block'] === 'Details' ||
              data['params'][idx]['block'] === 'Media' ||
              data['params'][idx]['block'] === 'Price'
          ) {
              this.categoryForm.patchValue({
                  blockName: data['params'][idx]['block']
              });
              this.editData = data['params'][idx]['params'];
              this.addNewBlock(true);
          }
      }
  }

  //Getting category list
  getCategoryList() {
    this.loading = true;
    this._api.postApiData(apiUrls.categoryList).subscribe(
      (data: any) => {
        this.loading = false;
        this.parentList = data.data;
      },
      error => {
        this.loading = false;
      }

    );
  }

  //Getting parameter values in FormArray
  params(): FormArray {
      return this.categoryForm.get("params") as FormArray
  }

}
